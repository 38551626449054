import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import YouTube, { Options } from 'react-youtube';
import { Button } from 'rsuite';
import styled from 'styled-components';

import { Header, SubHeader, WidthWrapper } from '../../../components/Layout/styles';
import { AppRoutes } from '../../Routing/routing.model';

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.backgroundBrokenWhite};
  padding: 80px 0;
`;

const Content = styled.article`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
    max-width: 100%;
  }
`;

const StyledYouTube = styled(YouTube)`
  margin: 0 16px;
  overflow: hidden;
  border-radius: 16px;
  float: left;
  width: 640px;
  max-width: 100%;

  @media (max-width: 900px) {
    width: 360px;
    margin: auto;
  }
`;

const BlockedVideoWrapper = styled.div`
  position: relative;
  width: 640px;
  height: 390px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  color: white;
  text-align: center;
  padding: 20px;
  font-size: 18px;
  line-height: 1.5;
  z-index: 1;

  @media (max-width: 900px) {
    width: 360px;
    height: 220px;
  }
`;

const DescriptionWrapper = styled.div`
  & > * {
    display: block;
    text-align: center;
  }
  max-width: 360px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-top: 32px;
`;

const opts: Options = {
  height: '390',
  width: '640',
  playerVars: {
    autoplay: 0,
  },
};

export const ProductVideo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <WidthWrapper>
        <Content>
          <div style={{ position: 'relative' }}>
            <BlockedVideoWrapper className="cookieconsent-optout-marketing">
              <p>Film niedostępny, ponieważ marketingowe pliki cookies zostały zablokowane przez użytkownika.</p>
            </BlockedVideoWrapper>
            <StyledYouTube videoId={'Ous8IngTFdM'} opts={opts} className="cookieconsent-optin-marketing" />
          </div>
          <DescriptionWrapper>
            <Header style={{ marginTop: 16 }}>{t('LANDING_PAGE.SECTION_THREE.HEADING')}</Header>
            <SubHeader>{t('LANDING_PAGE.SECTION_THREE.DESCRIPTION')}</SubHeader>
            <Link to={AppRoutes.OFFERS}>
              <StyledButton appearance="primary" size={'lg'}>
                {t('LANDING_PAGE.SECTION_THREE.BUTTON')}
              </StyledButton>
            </Link>
          </DescriptionWrapper>
        </Content>
      </WidthWrapper>
    </Wrapper>
  );
};
