import { Button, Form, Input, InputNumber, List, Select, Space, Typography } from 'antd';
import React, { useMemo, useState } from 'react';

import { SelectOption } from '../../../models/main.model';
import { LocalMeterReadings } from '../../../models/protocol.model';

import { mergeArrays } from './mergeArrays';

interface Props {
  onChange: (data: LocalMeterReadings[]) => void;
  initialValues: LocalMeterReadings[];
}

const initial: LocalMeterReadings[] = [
  {
    name: 'Energii elektrycznej',
    meterNumber: null,
    quantity: 0,
    unit: 'kWh',
  },
  {
    name: 'Gazu',
    meterNumber: null,
    quantity: 0,
    unit: 'm3',
  },
  {
    name: 'Wody ciepłej',
    meterNumber: null,
    quantity: 0,
    unit: 'l',
  },
  {
    name: 'Wody zimnej',
    meterNumber: null,
    quantity: 0,
    unit: 'l',
  },
  {
    name: 'Ciepła',
    meterNumber: null,
    quantity: 0,
    unit: 'm3',
  },
];

export function useUnitType(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () =>
      ['kWh', 'm³', 'l', 'GJ'].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}

const LocalMeterReadingsComponent: React.FC<Props> = ({ onChange, initialValues }) => {
  const mergedInitialData = useMemo(() => mergeArrays(initial, initialValues), [initialValues]);
  const [localMeterReadings, setLocalMeterReadings] = useState(mergedInitialData);
  const [newElement, setNewElement] = useState('');
  const [newMeterNumber, setNewMeterNumber] = useState(null);
  const [newValueOfNewElement, setNewValueOfNewElement] = useState(0);
  const [newUnit, setNewUnit] = useState(null);
  const unitType = useUnitType();

  const handleAdd = () => {
    const newItem = { name: newElement, meterNumber: newMeterNumber, quantity: newValueOfNewElement, unit: newUnit };
    setLocalMeterReadings([...localMeterReadings, newItem]);
    onChange([...localMeterReadings, newItem]);
    setNewElement('');
    setNewMeterNumber(null);
    setNewValueOfNewElement(0);
    setNewUnit(null);
  };

  const handleUnitChange = (index, newUnit) => {
    const updatedList = [...localMeterReadings];
    updatedList[index].unit = newUnit;
    setLocalMeterReadings(updatedList);
    onChange(updatedList);
  };

  const handleQuantityChange = (index, value) => {
    const updatedList = [...localMeterReadings];
    updatedList[index].quantity = value;
    setLocalMeterReadings(updatedList);
    onChange(updatedList);
  };

  const handleMeterNumberChange = (index, value) => {
    const updatedList = [...localMeterReadings];
    updatedList[index].meterNumber = value;
    setLocalMeterReadings(updatedList);
    onChange(updatedList);
  };

  return (
    <div className="mt-2">
      <List
        header={<div className="font-medium text-base">C. Stwierdzony stan liczników</div>}
        bordered
        dataSource={localMeterReadings}
        renderItem={(item, index) => (
          <>
            <List.Item className="flex items-center">
              <Space>
                <Typography.Text>{index + 1}</Typography.Text>
                <Typography.Text className="mr-4">{item.name}</Typography.Text>
              </Space>
              <Space className="mt-2 mb-2 flex ml-auto">
                <Form.Item className="-mb-1 mr-2">
                  <InputNumber
                    min={0}
                    className="w-32"
                    value={item.meterNumber}
                    onChange={(value) => handleMeterNumberChange(index, value)}
                    placeholder="Numer licznika"
                  />
                </Form.Item>
                <Form.Item className="-mb-1 mr-2">
                  <InputNumber
                    min={0}
                    className="w-14"
                    value={item.quantity}
                    onChange={(value) => handleQuantityChange(index, value)}
                  />
                </Form.Item>
                <Form.Item className="-mb-1 w-20">
                  <Select
                    options={unitType}
                    defaultValue={item.unit}
                    onChange={(value) => handleUnitChange(index, value)}
                    placeholder="Jednostka"
                  />
                </Form.Item>
              </Space>
            </List.Item>
            {index + 1 === localMeterReadings.length && (
              <List.Item>
                <Space className="table sm:flex ">
                  <Typography.Text>{index + 2}</Typography.Text>
                  <Input
                    value={newElement}
                    onChange={(e) => setNewElement(e.target.value)}
                    className="w-auto ml-4"
                    placeholder="Licznik"
                  />
                  <Input
                    value={newMeterNumber}
                    onChange={(e) => setNewMeterNumber(e.target.value)}
                    className="w-auto float-left mt-2 sm:mt-0 ml-4"
                    placeholder="Numer licznika"
                  />
                  <InputNumber
                    min={0}
                    className="w-14 mt-2 sm:mt-0 ml-4"
                    value={newValueOfNewElement}
                    onChange={(value) => setNewValueOfNewElement(value)}
                    placeholder="Stan"
                  />
                  <Select
                    options={unitType}
                    defaultValue={newUnit}
                    className="min-w-20 mt-2 sm:mt-0 ml-4"
                    onChange={setNewUnit}
                    placeholder="Jednostka"
                  />

                  {/* <Select
                  options={unitType}
                  onChange={setNewUnit}}
                  defaultValue={newUnit}
                  className="w-20 mt-2 sm:mt-0 ml-4"
                /> */}
                </Space>
                <Space className="mt-2 mb-2 flex ml-auto">
                  <Button onClick={handleAdd} type="default" className="float-right w-24">
                    Dodaj
                  </Button>
                </Space>
              </List.Item>
            )}
          </>
        )}
      />
    </div>
  );
};

export default LocalMeterReadingsComponent;
