import { MenuProps, Space, Spin } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FlexboxGrid } from 'rsuite';

import { WidthWrapper } from '../../../components/Layout/styles';
import { OfferCardHorizontal } from '../../../components/OfferCard/OfferCardHorizontal';
import { Typography } from '../../../components/Typography/Typography';
import { useGetOffersByUid } from '../../../firebase/hooks/getOffersByUid.hook';
import { OfferStatusState } from '../../../models/offers.model';
import { App } from '../../App/App';
import { useAuth } from '../../Auth/AuthContext';
import { AppRoutes } from '../../Routing/routing.model';
import { MenuWrapper } from '../components/MenuWrapper';
import { additionalNavigation } from '../Menu/AdditionalNavigation';
import { MenuItems } from '../Menu/Menu';
import { StyledArticle } from '../MyOffers/MojaTablicaOffers';

export const Favorites: React.FC = () => {
  const { push } = useHistory();
  const { currentUser } = useAuth();
  const onClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [offers, favorites, loading] = useGetOffersByUid(currentUser.uid);

  return (
    <App>
      <MenuWrapper>
        <MenuItems
          onClick={onClick}
          defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_FAVORITES]}
          defaultOpenKeys={['sub1']}
        />
      </MenuWrapper>

      {loading ? (
        <Spin style={{ margin: '50px' }} />
      ) : (
        favorites &&
        favorites.length > 0 && (
          <StyledArticle>
            <WidthWrapper>
              <Space direction="vertical" style={{ marginLeft: 20 }}>
                <Typography.H6>Polubione Oferty</Typography.H6>
              </Space>
              <FlexboxGrid>
                {favorites.map((o) => (
                  <FlexboxGrid.Item key={o.id} colspan={8}>
                    <OfferCardHorizontal
                      offer={o}
                      state={o.offerStatus.state as OfferStatusState}
                      addToFavoritesButton={false}
                    />
                  </FlexboxGrid.Item>
                ))}
              </FlexboxGrid>
            </WidthWrapper>
          </StyledArticle>
        )
      )}
    </App>
  );
};
