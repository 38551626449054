import * as React from 'react';
import styled from 'styled-components';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { Offer } from '../../../models/offers.model';
import { LeafletMap } from '../../../components/LeafletMap/LeafletMap';
import { MapMarkers } from '../../../components/LeafletMap/components/MapMarkers';

const StyledMap = styled(LeafletMap)`
  height: 30vh;
`;

interface Props {
  offer: Offer;
}
export const OfferLocation: React.FC<Props> = ({ offer }) => {
  return (
    <PanelWhite header="Lokalizacja" collapsible bodyFill defaultExpanded>
      <PanelContent>
        <StyledMap>
          <MapMarkers offerData={[offer]} selectedOfferId={offer.id} disableAnimation />
        </StyledMap>
      </PanelContent>
    </PanelWhite>
  );
};
