import * as React from 'react';
import { Message } from 'rsuite';
import styled from 'styled-components';

import { Tenant } from '../../../models/legalEntity.model';

const StyledMessage = styled(Message)`
  margin-bottom: ${({ theme }) => theme.gap(3)};
  width: 100%;
`;
const EntityWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.gap(2)};
`;

interface Props {
  tenant: Tenant;
}

export const LegalEntity: React.FC<Props> = ({ tenant }) => {
  return (
    <StyledMessage
      type="warning"
      title="Reprezentujesz podmiot:"
      description={
        <div>
          <EntityWrapper>
            <div>{tenant.name}</div>
            {tenant.lastName && <div>{tenant.lastName}</div>}
            {tenant.companyName && <div>Nazwa firmy: {tenant.companyName}</div>}
            {tenant.NIP && <div>NIP: {tenant.NIP}</div>}
          </EntityWrapper>
          <p>
            Jeżeli dane się nie zgadzają, przejdź do <a href="/moja-tablica/account/info">ustawień</a>.
          </p>
        </div>
      }
    />
  );
};
