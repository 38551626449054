import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'rsuite';

import { FormInput } from '../../../../components/FormInputs/FormInput';
import { ModalConfig, useAuth } from '../../AuthContext';
import { SignInFormValues } from '../../auth.model';
import { useOnSubmit } from '../hooks/useOnSubmit.hool';
import { useValidation } from '../hooks/useValidation.hook';

import { GoogleSignInButton } from './GoogleSignInButton';

export const SignInForm: React.FC<Pick<ModalConfig, 'onSuccess'>> = ({ onSuccess }) => {
  const validation = useValidation();
  const initialValues: SignInFormValues = { email: '', password: '' };
  const onSubmit = useOnSubmit(onSuccess);
  const { t } = useTranslation();
  const { signInWithGoogle } = useAuth();

  return (
    <Formik initialValues={initialValues} validationSchema={validation} onSubmit={onSubmit}>
      {({ isSubmitting, isValid, submitForm }) => (
        <Form fluid size={'lg'}>
          <FormInput name={'email'} label={t('FEATURES.AUTH.SIGN_IN.INPUT_EMAIL_PLACEHOLDER')} />

          <FormInput name={'password'} label={t('FEATURES.AUTH.SIGN_IN.INPUT_PASSWORD_PLACEHOLDER')} type="password" />

          <Button block color={'blue'} type="submit" disabled={isSubmitting || !isValid} onClick={submitForm}>
            {t('FEATURES.AUTH.SIGN_IN.BUTTON_SUBMIT')}
          </Button>
          <GoogleSignInButton onClick={signInWithGoogle} />
        </Form>
      )}
    </Formik>
  );
};
