import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { SignUpFormValues, UserProfileFormValues } from '../../auth.model';

export const useValidation1 = (): yup.SchemaOf<SignUpFormValues> => {
  const { t } = useTranslation();
  return yup.object().shape({
    email: yup
      .string()
      .email(t('FEATURES.AUTH.SIGN_UP.INPUT_EMAIL_VALIDATION_INVALID'))
      .required(t('FEATURES.AUTH.SIGN_UP.INPUT_EMAIL_VALIDATION_REQUIRED')),
    password: yup
      .string()
      .min(6, t('FEATURES.AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_MIN'))
      .max(50, t('FEATURES.AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_MAX'))
      .required(t('FEATURES.AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_REQUIRED')),
    passwordConfirm: yup
      .string()
      .max(50, t('FEATURES.AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_MAX'))
      .oneOf([yup.ref('password'), null], t('FEATURES.AUTH.SIGN_UP.INPUT_CONFIRM_PASSWORD_VALIDATION_NOT_MATCH'))
      .required(t('FEATURES.AUTH.SIGN_UP.INPUT_PASSWORD_VALIDATION_REQUIRED')),
    marketingConsent: yup.boolean().required(t('FEATURES.AUTH.SIGN_UP.INPUT_LASTNAME_VALIDATION_REQUIRED')),
  });
};

export const useValidation2 = (): yup.SchemaOf<UserProfileFormValues> => {
  const { t } = useTranslation();
  return yup.object().shape({
    firstName: yup.string().required(t('FEATURES.AUTH.SIGN_UP.INPUT_FIRSTNAME_VALIDATION_REQUIRED')),
    lastName: yup.string().required(t('FEATURES.AUTH.SIGN_UP.INPUT_LASTNAME_VALIDATION_REQUIRED')),
  });
};

export const useValidation3 = (): yup.SchemaOf<{ verificationCode: string }> => {
  const { t } = useTranslation();
  return yup.object().shape({
    verificationCode: yup
      .string()
      .matches(/^\d{6}$/) // Walidacja kodu 6-cyfrowego
      .required(t('FEATURES.AUTH.SIGN_UP.INPUT_VERIFICATION_CODE_REQUIRED')), // Komunikat o błędzie wymaganym
  });
};
