import firebase from 'firebase';

import { ToastVariant } from '../../../components/Toast/toast.model';
import { toastService } from '../../../components/Toast/toast.service';
import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions, FunctionAddOfferPayload } from '../../../firebase/firebase.models';
import { OfferCheck, useUpdateOfferCheck } from '../../../firebase/hooks/updateOfferCheck.hook';
import { Offer, Verification } from '../../../models/offers.model';
import { AppRoutes } from '../../Routing/routing.model';
import { createPDFs } from '../Documents/createPDFs';
import { ConfigType } from '../Documents/documents.models';
import { isCompanyConfirmed } from '../utils/isCompanyConfirmed';
import { isIdentityConfirmed } from '../utils/isIdentityConfirmed';

export const handleOnOfferAdd = async (
  currentUser: firebase.User,
  offer: Offer,
  setIsIdentityModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsCompanyModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setImagesVerificationResults: React.Dispatch<React.SetStateAction<string | null>>,
  push: (path: string) => void
): Promise<string | void> => {
  push(AppRoutes.ADD_OFFER_VERIFY.replace(':id', offer.id));

  useUpdateOfferCheck(offer.id, OfferCheck.VERIFYING_DATA);

  if ((await isIdentityConfirmed(currentUser)) !== Verification.ACCEPTED) {
    setIsIdentityModalOpen(true);
  } else if ((await isCompanyConfirmed(currentUser)) !== Verification.ACCEPTED) {
    setIsCompanyModalOpen(true);
  } else {
    await verifyOfferData();

    useUpdateOfferCheck(offer.id, OfferCheck.VERIFYING_IMAGES);

    const verificationResults = await verifyOfferImages(offer.id);
    console.log(verificationResults);
    setImagesVerificationResults(verificationResults);
    if (verificationResults) {
      push(AppRoutes.ADD_OFFER.replace(':id', offer.id));
      return;
    }

    useUpdateOfferCheck(offer.id, OfferCheck.GENERATING_PROJECT_OFFER);

    setLoading(true);

    createPDFs(offer, ConfigType.PROJECT)
      .then(() => {
        useUpdateOfferCheck(offer.id, OfferCheck.SENDING_PROJECT_OFFER);
        const offerAdd = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_OFFER_ADD);
        const functionPayload: FunctionAddOfferPayload = { offerId: offer.id };

        offerAdd(functionPayload)
          .then(() => {
            useUpdateOfferCheck(offer.id, OfferCheck.DONE);
          })
          .catch((error: any) => {
            toastService.show(error.message, 'Coś poszło nie tak.', {
              variant: ToastVariant.ERROR,
            });
          });
      })
      .catch((error: any) => {
        console.error(error);
        toastService.show('Błąd podczas tworzenia dokumentów.', 'Błąd!', {
          variant: ToastVariant.ERROR,
        });
      })
      .finally(() => setLoading(false));
  }
};

async function verifyOfferData(): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 2000);
  });
}

async function verifyOfferImages(offerId: string): Promise<string | null> {
  const verifyPhotos = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.VERIFY_PHOTOS_GEMINI);
  const functionPayload: FunctionAddOfferPayload = { offerId: offerId };

  try {
    const resp = await verifyPhotos(functionPayload);
    if (resp.data.response) {
      alert(formatImageVerificationResponse(JSON.stringify(resp.data.response)));
      return resp.data.response;
    } else {
      return null;
    }
  } catch (error: any) {
    toastService.show(error.message, 'Nie udało się zweryfikować zdjęć.', {
      variant: ToastVariant.ERROR,
    });
    return null;
  }
}

function formatImageVerificationResponse(response: string): string {
  const lines = response.split('\n');
  const formattedLines = lines.map((line, index) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, reasonLine] = line.split(' jest nieodpowiednie z powodu: Nie. ');
    return `Zdjęcie ${index + 1} zostało oznaczone jako nieodpowiednie z następującego powodu: ${reasonLine}.`;
  });

  return formattedLines.join('\n');
}
