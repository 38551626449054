import {
  AppstoreOutlined,
  AuditOutlined,
  CarryOutOutlined,
  CheckCircleOutlined,
  EditOutlined,
  HeartOutlined,
  HomeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Menu, type MenuProps } from 'antd';
import firebase from 'firebase/app'; // Upewnij się, że masz właściwą inicjalizację Firebase
import 'firebase/auth';
import React, { useState, useEffect } from 'react';

import { AppRoutes } from '../../Routing/routing.model';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const checkPasswordSignInMethod = async (email: string): Promise<boolean> => {
  try {
    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
    return signInMethods.includes(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD);
  } catch (error) {
    console.error('Error fetching sign-in methods:', error);
    return false;
  }
};

const accountSubItemsBase = [
  getItem('Informacje o koncie', AppRoutes.MOJA_TABLICA_ACCOUNT_INFO),
  getItem('Tożsamość', 'sub3', null, [
    getItem('Potwierdź tożsamość', AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY),
    getItem('Połącz z firmą', AppRoutes.MOJA_TABLICA_LINK_COMPANY),
  ]),
];

type Props = {
  onClick: MenuProps['onClick'];
  defaultSelectedKeys: string[];
  defaultOpenKeys: string[];
};

export const MenuItems: React.FC<Props> = ({ onClick, defaultSelectedKeys, defaultOpenKeys }) => {
  const [accountSubItems, setAccountSubItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    const updateMenuItems = async () => {
      const user = firebase.auth().currentUser;
      if (user) {
        const canSignInWithPassword = await checkPasswordSignInMethod(user.email);
        if (canSignInWithPassword) {
          setAccountSubItems([...accountSubItemsBase, getItem('Bezpieczeństwo', AppRoutes.MOJA_TABLICA_SECURITY)]);
        } else {
          setAccountSubItems(accountSubItemsBase);
        }
      } else {
        setAccountSubItems(accountSubItemsBase);
      }
    };

    updateMenuItems();
  }, []);

  return (
    <Menu
      mode="inline"
      style={{ width: 256 }}
      onClick={onClick}
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      items={[
        getItem('Oferty', 'sub1', <HomeOutlined rev={undefined} />, [
          getItem('Podpisane oferty', AppRoutes.MOJA_TABLICA_ACTIVE_OFFERS, <CheckCircleOutlined rev={undefined} />),
          getItem('Projekty ofert', AppRoutes.MOJA_TABLICA_UNSIGNED_OFFERS, <EditOutlined rev={undefined} />),
          getItem('Podpisane umowy', AppRoutes.MOJA_TABLICA_SIGNED_OFFERS, <AuditOutlined rev={undefined} />),
          getItem('Zarezerwowane', AppRoutes.MOJA_TABLICA_BOOKED_OFFERS, <CarryOutOutlined rev={undefined} />),
          getItem('Polubione', AppRoutes.MOJA_TABLICA_FAVORITES, <HeartOutlined rev={undefined} />),
        ]),

        getItem('Konto', 'sub2', <AppstoreOutlined rev={undefined} />, accountSubItems),

        { type: 'divider' },

        getItem('Zaawansowane', 'sub4', <SettingOutlined rev={undefined} />, [
          getItem('Pomoc', AppRoutes.MOJA_TABLICA_SUPPORT),
          getItem('Zgłoś błąd lub sugestie', AppRoutes.MOJA_TABLICA_REPORT),
        ]),

        getItem(
          'Informacje',
          'grp',
          null,
          [getItem('Aspekty Prawne', '1'), getItem('Regulamin', '2'), getItem('Cennik', '3')],
          'group'
        ),
      ]}
    />
  );
};
