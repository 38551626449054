import firebase from 'firebase';
import { useEffect, useState } from 'react';

import { Offer } from '../../models/offers.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

import { useProcessDates } from './useProcessDates.hook';

export function useGetOffersByUid(uid: string): [Offer[], Offer[], boolean, firebase.FirebaseError] {
  const [error, setError] = useState<firebase.FirebaseError>();
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [favorites, setFavorites] = useState<Offer[]>([]);

  useEffect(() => {
    setLoading(true);
    setError(null);

    const unsubscribe = firestore
      .collection(FirestoreCollection.USERS)
      .doc(uid)
      .onSnapshot(
        async (snapshot) => {
          const data = snapshot.data();
          const offersId = data?.offers || [];
          const favoritesId = data?.favorites || [];

          const fetchOffers = async (ids: string[]) => {
            const snapshots = await Promise.all(
              ids.map((id) => firestore.collection(FirestoreCollection.OFFERS).doc(id).get())
            );
            return snapshots.map(
              (doc) =>
                ({
                  id: doc.id,
                  ...doc.data(),
                  ...useProcessDates({
                    availability: data.availability,
                    availabilityTo: data.availabilityTo,
                    offerValidTo: data.offerValidTo,
                  }),
                } as Offer)
            );
          };

          try {
            const [fetchedOffers, fetchedFavorites] = await Promise.all([
              fetchOffers(offersId),
              fetchOffers(favoritesId),
            ]);
            setOffers(fetchedOffers);
            setFavorites(fetchedFavorites);
          } catch (e) {
            console.error('Error fetching offers or favorites', e);
            setError(e as firebase.FirebaseError);
          } finally {
            setLoading(false);
          }
        },
        (e) => {
          console.error('Error with snapshot', e);
          setError(e);
          setLoading(false);
        }
      );

    return () => unsubscribe();
  }, [uid]);

  return [offers, favorites, loading, error];
}
