import { Formik } from 'formik';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'rsuite';

import { ForgotPasswordFormValues } from '../auth.model';
import { useAuth } from '../AuthContext';
import { AdditionalLink } from '../styles';
import { Typography } from '../../../components/Typography/Typography';
import { FormInput } from '../../../components/FormInputs/FormInput';

import { useValidation } from './hooks/useValidation.hook';
import { useOnSubmit } from './hooks/useOnSubmit.hool';

export const ForgotPassword: React.FC = () => {
  const validation = useValidation();
  const initialValues: ForgotPasswordFormValues = { email: '' };
  const onSubmit = useOnSubmit();
  const { t } = useTranslation();
  const { showSignInModal } = useAuth();

  return (
    <>
      <Modal.Header>
        <Typography.H3>{t('FEATURES.AUTH.FORGOT_PASSWORD.HEADER')}</Typography.H3>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} validationSchema={validation} onSubmit={onSubmit}>
          {({ isSubmitting, isValid, submitForm }) => (
            <Form fluid size={'lg'}>
              <FormInput name={'email'} label={t('FEATURES.AUTH.FORGOT_PASSWORD.INPUT_EMAIL_PLACEHOLDER')} />

              <Button block color={'blue'} type="submit" disabled={isSubmitting || !isValid} onClick={submitForm}>
                {t('FEATURES.AUTH.FORGOT_PASSWORD.BUTTON_SUBMIT')}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <AdditionalLink>
          <Trans
            i18nKey="FEATURES.AUTH.FORGOT_PASSWORD.SIGN_IN"
            components={{
              link1: (
                <Typography.Anchor
                  onClick={(e) => {
                    e.preventDefault();
                    showSignInModal();
                  }}
                />
              ),
            }}
          />
        </AdditionalLink>
      </Modal.Footer>
    </>
  );
};
