import { Modal } from 'antd';
import React from 'react';

interface Props {
  handleOk: () => void;
  isModalOpen: boolean;
  toggleModal: () => void;
}

export const DeleteOfferModal: React.FC<Props> = ({ handleOk, isModalOpen, toggleModal }) => {
  return (
    <Modal
      title="Usuń Ofertę"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={toggleModal}
      okText="Potwierdź"
      cancelText="Anuluj">
      <p>Czy na pewno chcesz usunąć tę ofertę?</p>
      <p>Wszystkie informacje z tą ofertą zostaną usunięte.</p>
    </Modal>
  );
};
