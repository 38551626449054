import { useMemo } from 'react';

import { SelectOption } from '../../../../models/main.model';

export function useCommercialAndServiceRoomTypeOptions(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () =>
      ['z witryną', ' bez witryny'].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}
