import { Button, Form, Input, InputNumber, List, Space, Typography } from 'antd';
import React, { useMemo, useState } from 'react';

import { TransferredKeysAndDevices } from '../../../models/protocol.model';

import { mergeArrays } from './mergeArrays';

interface Props {
  onChange: (data: TransferredKeysAndDevices[]) => void;
  initialValues: TransferredKeysAndDevices[];
}

const initial = [
  {
    name: 'Klucze do drzwi wejściowych do lokalu',
    quantity: 0,
  },
  {
    name: 'Klucze do drzwi wejściowych do budynku',
    quantity: 0,
  },
  {
    name: 'Klucze do skrzynki pocztowej',
    quantity: 0,
  },
  {
    name: 'Pilot do bramy garażowej',
    quantity: 0,
  },
];

const TransferedKeysAndDevices: React.FC<Props> = ({ onChange, initialValues }) => {
  const mergedInitialData = useMemo(() => mergeArrays(initial, initialValues), [initialValues]);
  const [newElement, setNewElement] = useState('');
  const [newValueOfNewElement, setNewValueOfNewElement] = useState(0);
  const [transferredKeysAndDevices, setTransferredKeysAndDevices] = useState(mergedInitialData);

  const handleAdd = () => {
    const newItem = { name: newElement, quantity: newValueOfNewElement };
    setTransferredKeysAndDevices([...transferredKeysAndDevices, newItem]);
    onChange([...transferredKeysAndDevices, newItem]);
    setNewElement('');
    setNewValueOfNewElement(0);
  };

  const handleQuantityChange = (index, value) => {
    const updatedList = [...transferredKeysAndDevices];
    updatedList[index].quantity = value;
    setTransferredKeysAndDevices(updatedList);
    onChange(updatedList);
  };

  return (
    <div className="mt-2">
      <List
        header={<div className="font-medium text-base">D. Przekazane klucze i urządzenia</div>}
        bordered
        dataSource={transferredKeysAndDevices}
        renderItem={(item, index) => (
          <>
            <List.Item className="flex items-center">
              <Space>
                <Typography.Text>{index + 1}</Typography.Text>
                <Typography.Text className="mr-4">{item.name}</Typography.Text>
              </Space>
              <Form.Item className="-mb-1 sm:ml-0 md:ml-auto">
                <InputNumber
                  min={0}
                  className="w-14"
                  value={item.quantity}
                  onChange={(value) => handleQuantityChange(index, value)}
                />
              </Form.Item>
            </List.Item>
            {index + 1 === transferredKeysAndDevices.length && (
              <List.Item className="flex">
                <Space>
                  <Typography.Text>{index + 2}</Typography.Text>
                  <Input value={newElement} onChange={(e) => setNewElement(e.target.value)} />
                  <InputNumber
                    min={0}
                    className="w-14"
                    value={newValueOfNewElement}
                    onChange={(value) => setNewValueOfNewElement(value)}
                  />
                </Space>
                <Space className="mt-2 mb-2 flex ml-auto">
                  <Button onClick={handleAdd} type="default" className="float-right w-24">
                    Dodaj
                  </Button>
                </Space>
              </List.Item>
            )}
          </>
        )}
      />
    </div>
  );
};

export default TransferedKeysAndDevices;
