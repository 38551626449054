import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { SignInFormValues } from '../../auth.model';

export const useValidation = (): yup.SchemaOf<SignInFormValues> => {
  const { t } = useTranslation();
  return yup.object().shape({
    email: yup
      .string()
      .email(t('FEATURES.AUTH.SIGN_IN.INPUT_EMAIL_VALIDATION_INVALID'))
      .required(t('FEATURES.AUTH.SIGN_IN.INPUT_EMAIL_VALIDATION_REQUIRED')),
    password: yup
      .string()
      .min(6, t('FEATURES.AUTH.SIGN_IN.INPUT_PASSWORD_VALIDATION_MIN'))
      .max(255, t('FEATURES.AUTH.SIGN_IN.INPUT_PASSWORD_VALIDATION_MAX'))
      .required(t('FEATURES.AUTH.SIGN_IN.INPUT_PASSWORD_VALIDATION_REQUIRED')),
  });
};
