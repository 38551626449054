import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

export enum OfferCheck {
  VERIFYING_IMAGES = 'Weryfikacja zdjęć',
  VERIFYING_DATA = 'Weryfikacja danych oferty',
  GENERATING_PROJECT_OFFER = 'Generowanie dokumentów',
  SENDING_PROJECT_OFFER = 'Wysyłanie oferty',
  DONE = 'Gotowe!',

  CHECKING_NETWORK = 'Sprawdzanie bezpieczeństwa sieci',
  DOWNLOADING_DATA = 'Pobieranie potrzebnych danych',
  SIGNING_OFFER = 'Podpisywanie oferty',
  SAVE_REPORT = 'Zapisywanie operacji w rejestrze',
  SENDING_OFFER = 'Wysyłanie podpisanej oferty',

  RESERVATION = 'Rezerwowanie oferty',
  PREPARING_DOCUMENTS = 'Przygotowywanie dokumentów do podpisu',

  SIGNING_CONTRACT = 'Podpisywanie umowy',
  SENDING_CONTRACT = 'Wysyłanie umowy',
}

export async function useUpdateOfferCheck(offerId: string, state: OfferCheck): Promise<void> {
  try {
    const docRef = firestore.collection(FirestoreCollection.OFFERS).doc(offerId);

    docRef
      .update({ 'offerStatus.offerCheck': state })

      .catch((e) => {
        toastService.show(e, 'Błąd!', { variant: ToastVariant.ERROR });
      });
  } catch (error) {
    toastService.show('Nie udało się przejść do następnego kroku', 'Błąd!', { variant: ToastVariant.ERROR });
  }
  return Promise.resolve();
}
