import { useField } from 'formik';
import * as React from 'react';
import { Checkbox } from 'rsuite';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CustomCheckbox = (props) => {
  const [, , helpers] = useField(props);

  return (
    <>
      <Checkbox onChange={(_, checked) => helpers.setValue(checked)}>{props.label}</Checkbox>
      <br></br>
    </>
  );
};
