import { useMemo } from 'react';

import { SelectOption } from '../../../../models/main.model';

export function useSourrundingType(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () =>
      [
        'Zabudowa zwarta śródmiejska',
        'Zabudowa sąsiednia',
        'Zabudowa podmiejska',
        'Działki niezabudowane',
        'Budowa',
        'Las',
        'Jezioro, woda',
        'Morze',
        'Góry',
      ].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}
