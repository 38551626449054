import { Button, Form, Input } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { ToastVariant } from '../../../../components/Toast/toast.model';
import { toastService } from '../../../../components/Toast/toast.service';
import { firestore } from '../../../../firebase/firebase';
import { FirestoreCollection } from '../../../../firebase/firebase.models';
import { useUpdateUserInformation } from '../../../../firebase/hooks/updateUserInformation.hook';
import { UpdateInformation } from '../../../../models/MojaTablica.model';
import { useAuth } from '../../../Auth/AuthContext';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 30 },
};

export const BasicInfo: React.FC = () => {
  const [componentDisabled, setComponentDisabled] = useState<boolean>(true);
  const { currentUser, updateInformation } = useAuth();

  const [initialInformation, setInitialInformation] = useState<UpdateInformation>();
  const [updated, setUpdated] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    const userDoc = firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid);
    userDoc.get().then((snapshot) => {
      const data = snapshot.data();
      if (data) {
        setInitialInformation({
          firstName: data.firstName,
          lastName: data.lastName,
        } as UpdateInformation);
        form.setFieldsValue({
          firstName: data.firstName,
          lastName: data.lastName,
        });
        form.resetFields();
      }
    });
  }, [updated]);

  const onFinish = (values: UpdateInformation) => {
    setComponentDisabled(!componentDisabled);
    // update firestore user information
    useUpdateUserInformation(values, currentUser.uid);
    // update auth currentUser information
    updateInformation(values.firstName + ' ' + values.lastName);
  };

  const onFinishFailed = (errorInfo: any) => {
    toastService.show(errorInfo, 'Błąd!', { variant: ToastVariant.ERROR });
  };

  const onReset = () => {
    setUpdated(!updated);
  };

  return (
    <>
      <Form
        {...layout}
        style={{ maxWidth: 800, marginTop: 20, minWidth: 350 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          firstName: initialInformation ? initialInformation.firstName : '',
          lastName: initialInformation ? initialInformation.lastName : '',
        }}
        autoComplete="off"
        form={form}
        disabled={componentDisabled}>
        <Form.Item label="Imię" name="firstName" rules={[{ required: true, message: 'Pole nie może być puste' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Nazwisko" name="lastName" rules={[{ required: true, message: 'Pole nie może być puste' }]}>
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} hidden={componentDisabled}>
          <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}>
            Zapisz
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Zresetuj
          </Button>
        </Form.Item>
      </Form>
      <Button
        hidden={!componentDisabled}
        style={{ marginLeft: 120 }}
        onClick={() => setComponentDisabled(!componentDisabled)}>
        Edytuj
      </Button>
    </>
  );
};
