import { Alert, FormProps, message, Modal, Space, Spin } from 'antd';
import firebase from 'firebase';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Loader } from 'rsuite';

import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { Offer, PostSigningStatus } from '../../models/offers.model';
import { ProtocolData } from '../../models/protocol.model';
import { App } from '../App/App';
import { useAuth } from '../Auth/AuthContext';
import { AppRoutes } from '../Routing/routing.model';

import { ProtocolForm } from './ProtocolForm';
import { generateApprovalUrl } from './generateApprovalUrl';
import { handleOnProtocolSign } from './handleOnProtocolSign.hook';
import { handleOnProtocolSubmit } from './handleOnProtocolSubmit.hook';

interface Props {
  id: string;
}

interface PropsWhenAuth {
  offerId: string;
  currentUser: firebase.User;
}

export const Protocol: React.FC<Props> = ({ id }) => {
  const { isAuthenticating, isAuth, currentUser } = useAuth();

  return (
    <App>
      {!isAuthenticating && isAuth && <WhenAuth offerId={id} currentUser={currentUser} />}
      {isAuthenticating && !isAuth && <WhenNotAuth />}
    </App>
  );
};

const WhenAuth: React.FC<PropsWhenAuth> = ({ offerId, currentUser }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [offer, isLoading, error] = useGetOffer(offerId);
  const history = useHistory();

  const [validationError, setValidationError] = useState<React.ReactElement | null>(null);

  const isActive = offer?.offerStatus && !(offer.offerStatus.postSigningStatus === PostSigningStatus.DEPOSIT_PENDING);
  const isSigned =
    offer?.offerStatus &&
    (offer.offerStatus.postSigningStatus === PostSigningStatus.PROTOCOL_CONFIRMED ||
      offer.offerStatus.postSigningStatus === PostSigningStatus.PROTOCOL_ATTACHED);

  const onSubmitProtocol: FormProps<ProtocolData>['onFinish'] = async (values) => {
    setLoading(true);
    Modal.info({
      title: (
        <p>
          Twoje dane są zapisywane...
          <Spin className="ml-2" />
        </p>
      ),
      content: (
        <div>
          <p>Za chwilę {currentUser.uid === offer.uid ? 'Najemca' : 'Wynajmujący'} otrzyma protokół do podpisania.</p>
        </div>
      ),
      okButtonProps: { style: { display: 'none' } },
    });
    await handleOnProtocolSubmit({ offerId, values, currentUser });
    setLoading(false);
    history.push(AppRoutes.MOJA_TABLICA_SIGNED_OFFERS);
    setTimeout(() => {
      Modal.destroyAll();
    }, 1500); // Opóźnienie wynosi 1500 milisekund (1,5 sekundy na przeczytanie modala)
  };

  useEffect(() => {
    if (offer) {
      const error = validateOfferData(offer, currentUser);
      if (error) {
        setValidationError(error);
        console.log('error!');
      }
    }
  }, [offer, currentUser]);

  useEffect(() => {
    if (validationError) {
      Modal.error({
        title: 'Formularz nie został wypełniony',
        content: validationError,
        onOk: () => history.push(AppRoutes.MOJA_TABLICA_SIGNED_OFFERS),
      });
    }
  }, [validationError]);

  const handleApprovalClick = async (accepted: boolean) => {
    try {
      Modal.info({
        title: (
          <p>
            Twój wybór jest zapisywany...
            <Spin className="ml-2" />
          </p>
        ),
        content: <p>Za chwilę otrzymasz mail z potwierdzeniem.</p>,
        okButtonProps: { style: { display: 'none' } },
      });

      if (accepted) {
        await handleOnProtocolSign({ offerId, accepted, currentUser });
      }

      const url = await generateApprovalUrl(accepted, offerId, currentUser);
      window.location.assign(url);
    } catch (error) {
      message.error('Wystąpił problem podczas zapisywania wyboru.');
      console.error('Error during approval:', error);
    }
  };

  if (validationError) {
    return <></>;
  }
  if (error) {
    return (
      <Space direction="vertical" style={{ width: '100%', marginTop: '100px', marginBottom: '60%', padding: '50px' }}>
        <Alert showIcon message="Nie masz dostępu do tego formularza!" description={error} type="error" />
      </Space>
    );
  } else if (offer === undefined || isLoading) {
    return (
      <Loader
        style={{ position: 'absolute', top: '108%', left: '50%', transform: 'translate(-50%, -50%)' }}
        size="md"
        center
        content="loading..."
      />
    );
  } else {
    return (
      <>
        <Modal open={!isActive} closable={false} footer={null} maskClosable={false} title={'Formularz niedostępny'}>
          <div>
            <p>Zostanie odblokowany, gdy wpłynie do nas kaucja od najemcy.</p>
          </div>
        </Modal>
        {offer.leaseAgreement.fixedlong && (
          <Alert
            className="m-4"
            type="info"
            showIcon
            message={
              'Aby umowa była zawarta na czas powyżej 12 miesięcy, należy nadać umowie podpisy osobiste w formie długopisowej.'
            }
          />
        )}
        <ProtocolForm
          offerId={offerId}
          o={offer}
          onFinish={onSubmitProtocol}
          isAddingPending={loading}
          active={isActive && !isSigned}
          handleApprovalClick={handleApprovalClick}
        />
      </>
    );
  }
};

const validateOfferData = (o: Offer, currentUser: firebase.User): React.ReactElement | null => {
  const endLine = <div className="font-medium mt-2">Spróbuj później.</div>;
  if (!o.transferOfThePremise) {
    return currentUser.uid === o.uid ? (
      <>
        Formularz dot. przekazania Lokalu nie został wypełniony. Sprawdź poprzednie maile i uzupełnij formularz.
        {endLine}
      </>
    ) : (
      <>
        Formularz dot. przekazania Lokalu nie został wypełniony. Wynajmujący musi uzupełnić formularz odbioru. {endLine}
      </>
    );
  }

  if (!o.receptionTime) {
    return currentUser.uid !== o.uid ? (
      <>
        Formularz dot. czasu odbioru Lokalu nie został wypełniony. Sprawdź poprzednie maile i uzupełnij formularz.
        {endLine}
      </>
    ) : (
      <>
        Formularz dot. czasu odbioru Lokalu nie został wypełniony. Najemca musi uzupełnić formularz odbioru. {endLine}
      </>
    );
  }

  return null;
};

const WhenNotAuth: React.FC = () => {
  const { showSignInModal, closeModal } = useAuth();

  useEffect(() => {
    showSignInModal();
    return () => closeModal();
  }, []);

  return <></>;
};
