import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Animation, FlexboxGrid } from 'rsuite';

import { Article, WidthWrapper } from '../../../components/Layout/styles';
import { OfferCardHorizontal } from '../../../components/OfferCard/OfferCardHorizontal';
import { Typography } from '../../../components/Typography/Typography';
import { Offer } from '../../../models/offers.model';

const { Collapse } = Animation;

const StyledArticle = styled(Article)``;

interface Props {
  offers: Offer[];
  isSearchPending: boolean;
  onLocationClick: (id: string) => void;
}
export const SearchedOffers: React.FC<Props> = ({ offers, onLocationClick }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>();

  useEffect(() => {
    setIsCollapsed(!!offers);
  }, [offers, setIsCollapsed]);

  return (
    <Collapse in={isCollapsed}>
      {(props, ref) => (
        <div {...props} ref={ref}>
          <StyledArticle>
            <WidthWrapper>
              <Typography.H6>liczba Lokali: {offers?.length}</Typography.H6>
              <FlexboxGrid>
                {offers?.map((o) => (
                  <FlexboxGrid.Item key={o.id} colspan={8}>
                    <OfferCardHorizontal offer={o} onLocationClick={onLocationClick} />
                  </FlexboxGrid.Item>
                ))}
              </FlexboxGrid>
            </WidthWrapper>
          </StyledArticle>
        </div>
      )}
    </Collapse>
  );
};
