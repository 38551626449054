import * as React from 'react';
import { Icon, IconButton, Popover, Whisper } from 'rsuite';
import { IconNames } from 'rsuite/lib/Icon/Icon';
import styled from 'styled-components';

import { Offer } from '../../../models/offers.model';

const Additional = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin-right: 16px;
    margin-top: 16px;
  }
`;
const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
  }
`;

interface Props {
  offer: Offer;
  className?: string;
}
export const OfferBadges: React.FC<Props> = ({ className, offer }) => {
  return (
    <Additional className={className}>
      <Badge
        icon={'arrows-alt'}
        popoverText={'Pow. użytkowa do wynajęcia'}
        value={`${offer.areaDetails.surface ?? 0} m²`}
      />
      {offer.legalMainUse === 'Magazynowo-Przemysłowe' && (
        <Badge icon={'industry'} popoverText={'Głównna funkcja Lokalu: Magazynowo-Przemysłowe'} />
      )}
      {offer.areaDetails?.levels && offer.areaDetails.levels.findIndex((l) => l.accessibility) > -1 && (
        <Badge icon={'wheelchair-alt'} popoverText={'Lokal przystosowany dla osób z niepełnosprawnoscią'} />
      )}
      {offer.parkingDetails?.parkingLotsAvailable > 0 && (
        <Badge
          icon={'car'}
          popoverText={'Pojemność całego parkingu'}
          value={`${offer.parkingDetails.parkingLotsAvailable} poj.`}
        />
      )}
      {offer.availability && (
        <Badge
          icon={'calendar-check-o'}
          popoverText={'Lokal dostępny od - do'}
          value={
            offer.availability.toLocaleDateString('pl-PL', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }) +
            '-' +
            (offer.availabilityTo
              ? offer.availabilityTo.toLocaleDateString('pl-PL', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })
              : 'Bez końca')
          }
        />
      )}
      {offer.mediaDetails.fiber && <Badge icon={'flow'} popoverText={'Dostęp do światłowodu'} />}
      {offer.areaDetails.levels && (
        <Badge icon={'bars'} popoverText={'Ilość kondygnacji'} value={offer.areaDetails.levels.length.toString()} />
      )}
    </Additional>
  );
};

interface BadgeProps {
  icon: IconNames;
  popoverText?: string;
  value?: string;
}
const Badge: React.FC<BadgeProps> = ({ icon, popoverText, value }) => (
  <BadgeWrapper>
    {popoverText ? (
      <Whisper
        trigger="hover"
        placement="topStart"
        speaker={
          <Popover>
            <p>{popoverText}</p>
          </Popover>
        }>
        <IconButton icon={<Icon icon={icon} />} circle size="md" />
      </Whisper>
    ) : (
      <IconButton icon={<Icon icon={icon} />} circle size="md" />
    )}
    {value && <span>{value}</span>}
  </BadgeWrapper>
);
