import * as React from 'react';
import { Modal } from 'rsuite';
import styled from 'styled-components';

import { AuthDialog } from './auth.model';
import { ModalProps, useAuth } from './AuthContext';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { NonEmailSignUp } from './NonEmailSignUp/NonEmailSignUp';
import { SignIn } from './SignIn/SignIn';
import { Step3Form } from './SignUp/components/Step3Form';
import { SignUp } from './SignUp/SignUp';

const StyledModal = styled(Modal)`
  width: 500px;
`;

interface Props {
  dialogConfig: ModalProps;
  showDialog: boolean;
}
export const AuthModal: React.FC<Props> = ({ dialogConfig, showDialog }) => {
  const { closeModal } = useAuth();

  return (
    <StyledModal show={showDialog} onHide={closeModal}>
      {dialogConfig?.content === AuthDialog.SIGN_IN && (
        <SignIn title={dialogConfig?.title} onSuccess={dialogConfig?.onSuccess} />
      )}
      {dialogConfig?.content === AuthDialog.SIGN_UP && (
        <SignUp title={dialogConfig?.title} onSuccess={dialogConfig?.onSuccess} />
      )}
      {dialogConfig?.content === AuthDialog.NON_EMAIL_SIGN_UP && (
        <NonEmailSignUp title={dialogConfig?.title} onSuccess={dialogConfig?.onSuccess} />
      )}
      {dialogConfig?.content === AuthDialog.FORGOT_PASSWORD && <ForgotPassword />}
      {dialogConfig?.content === AuthDialog.VERIFY_EMAIL && <Step3Form title={dialogConfig?.title} />}
    </StyledModal>
  );
};
