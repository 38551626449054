import { Formik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'rsuite';

import { FormInput } from '../../../../components/FormInputs/FormInput';
import { ModalConfig, useAuth } from '../../AuthContext';
import { CustomCheckbox } from '../../MarketingConsentCheckbox';
import { NonEmailSignUpFormValues } from '../../auth.model';
import { useOnSubmit } from '../hooks/useOnSubmit.hool';
import { useValidation } from '../hooks/useValidation.hook';
import { firestore } from '../../../../firebase/firebase';
import { FirestoreCollection } from '../../../../firebase/firebase.models';

export const NonEmailSignUpForm: React.FC<Pick<ModalConfig, 'onSuccess'>> = ({ onSuccess }) => {
  const validation = useValidation();
  const { currentUser } = useAuth();

  const [initialValues, setInitialValues] = useState<NonEmailSignUpFormValues>({
    firstName: '',
    lastName: '',
    marketingConsent: false,
    email: '',
    uid: '',
  });

  // Aktualizacja initialValues gdy currentUser zostanie załadowany
  useEffect(() => {
    const saveToFirestore = async (userData) => {
      try {
        const userRef = firestore.collection(FirestoreCollection.USERS).doc(userData.uid);
        await userRef.set(userData, { merge: true });
        console.log('User data saved successfully');
      } catch (error) {
        console.error('Error saving user data:', error);
      }
    };

    if (currentUser) {
      const initialValues = {
        email: currentUser.email,
        uid: currentUser.uid,
        firstName: currentUser.displayName.split(' ')[0] || '',
        lastName: currentUser.displayName.split(' ').slice(1).join(' ') || '',
      };

      setInitialValues((prevValues) => ({
        ...prevValues,
        ...initialValues,
      }));

      saveToFirestore(initialValues);
    }
  }, [currentUser]);

  const onSubmit = useOnSubmit(onSuccess);
  const { t } = useTranslation();

  // Warunkowe renderowanie Formika
  if (!currentUser || !initialValues.uid || !initialValues.email) {
    return <div>Ładowanie danych użytkownika...</div>;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validation} onSubmit={onSubmit}>
      {({ isSubmitting, isValid, submitForm }) => (
        <Form fluid size={'lg'}>
          <FormInput name={'firstName'} label={t('FEATURES.AUTH.SIGN_UP.INPUT_FIRSTNAME_PLACEHOLDER')} />
          <FormInput name={'lastName'} label={t('FEATURES.AUTH.SIGN_UP.INPUT_LASTNAME_PLACEHOLDER')} />
          <CustomCheckbox
            name={'marketingConsent'}
            label={t('FEATURES.AUTH.SIGN_UP.INPUT_MARKETING_CONSENT_PLACEHOLDER')}
          />

          <Button block color={'blue'} type="submit" disabled={isSubmitting || !isValid} onClick={submitForm}>
            {t('FEATURES.AUTH.SIGN_UP.SAVE')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
