import { Alert, Space } from 'antd';
import * as React from 'react';
import { useEffect } from 'react';
import { Loader } from 'rsuite';

import { useGetProjectOffer } from '../../../firebase/hooks/getProjectOfferById.hook';
import { Offer } from '../../../models/offers.model';
import { App } from '../../App/App';
import { useAuth } from '../../Auth/AuthContext';

import { Details } from './Details';

interface Props {
  id: string;
}

interface PropsWhenAuth {
  id: string;
  offer: Offer;
  error: string;
  currentUser: any;
}

export const Deposit: React.FC<Props> = ({ id }) => {
  const { isAuthenticating, isAuth, currentUser } = useAuth();
  const { offer, error } = useGetProjectOffer(id);

  return (
    <App>
      {!isAuthenticating && isAuth ? (
        <WhenAuth id={id} offer={offer} error={error} currentUser={currentUser} />
      ) : (
        <WhenNotAuth />
      )}
    </App>
  );
};

const WhenAuth: React.FC<PropsWhenAuth> = ({ offer, error }) => {
  if (error) {
    return (
      <>
        <Space direction="vertical" style={{ width: '100%', marginTop: '100px', marginBottom: '60%', padding: '50px' }}>
          <Alert showIcon message="Nie masz dostępu do tego formularza!" description={error} type="error" />
        </Space>
      </>
    );
  } else if (offer === undefined) {
    return (
      <>
        <Loader
          style={{ position: 'absolute', top: '108%', left: '50%', transform: 'translate(-50%, -50%)' }}
          size="md"
          center
          content="loading..."
        />
      </>
    );
  } else {
    return <Details offer={offer} />;
  }
};

const WhenNotAuth: React.FC = () => {
  const { showSignInModal, closeModal } = useAuth();

  useEffect(() => {
    showSignInModal();
    return () => closeModal();
  }, []);

  return <></>;
};
