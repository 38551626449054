import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../models/main.model';
import { LegalWarehouseUseAccepted } from '../../../../models/offers.model';

export function usePremisesPurposeWarehouseSelectOptions(): SelectOption[] {
  const { t } = useTranslation();
  return useMemo(
    () =>
      Object.entries(LegalWarehouseUseAccepted).map(([key, value]) => ({
        label: t(`COMMON.FORMS.LEGAL_WAREHOUSE_USE_ACCEPTED.OPTIONS.${key}`),
        value,
      })),
    []
  );
}
