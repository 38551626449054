import { Button, Form, Input, Radio } from 'antd';
import React, { useRef, useState } from 'react';

import { ToastVariant } from '../../../../components/Toast/toast.model';
import { toastService } from '../../../../components/Toast/toast.service';
import { firebaseFunctions } from '../../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../../firebase/firebase.models';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: '${label} jest wymagane',
};

interface FormProps {
  userUid: string;
  title: string;
  type: string;
  description: string;
}
interface Props {
  userUid: string;
}

export const FromReport: React.FC<Props> = (userUid) => {
  const [isloading, setIsloading] = useState(false);
  const formRef = useRef(null);

  const onFinish = (values: FormProps) => {
    setIsloading(true);
    const report = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_REPORT);
    report({ userUid: values.userUid, title: values.title, type: values.type, description: values.description })
      .then(() => {
        setIsloading(false);
        toastService.show('Zgłoszenie zostało przyjęte', 'Sukces');
        formRef.current.resetFields();
      })
      .catch(() => {
        setIsloading(false);
        toastService.show('Cos poszło nie tak. Spróbuj później', 'Błąd', { variant: ToastVariant.ERROR });
      });
  };

  return (
    <>
      <Form
        {...layout}
        ref={formRef}
        name="nest-messages"
        onFinish={onFinish}
        style={{ width: 700, marginTop: '20px', marginLeft: '50px' }}
        validateMessages={validateMessages}
        initialValues={{ userUid: userUid.userUid }}>
        <Form.Item name={'userUid'} hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name={'title'} label="Tytuł" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Rodzaj zgłoszenia" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value="Error">Błąd</Radio>
            <Radio value="Sugestion">Sugestia</Radio>
            <Radio value="Other">Inne</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name={'description'} label="Opis">
          <Input.TextArea rows={9} />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit" loading={isloading}>
            Wyślij
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
