import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { toastService } from '../../../../components/Toast/toast.service';
import { firebaseFunctions } from '../../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../../firebase/firebase.models';
import { useAuth } from '../../AuthContext';
import { CompleteSignUpFormValues } from '../../auth.model';

export function useOnSubmit(onSuccess: () => void): (values: CompleteSignUpFormValues) => Promise<any> {
  const { signUp, updateInformation } = useAuth();
  const { t } = useTranslation();
  const onNewUser = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_NEW_USER);

  return useCallback(
    (values) => {
      return signUp(values).then((v) => {
        return onNewUser({
          firstName: values.firstName,
          lastName: values.lastName,
          email: v.user.email,
          uid: v.user.uid,
          marketingConsent: values.marketingConsent,
        })
          .then(() => updateInformation(values.firstName + ' ' + values.lastName))
          .then(() => toastService.show(t('FEATURES.AUTH.SIGN_UP.API_RESPONSE_SUCCESS'), 'Sukces'))
          .then(() => new Promise((resolve) => setTimeout(resolve, 1000))) // Czekanie 1 sekundy
          .then(() => onSuccess?.());
      });
    },
    [signUp, updateInformation, onSuccess, onNewUser, t]
  );
}
