import { Alert, Space } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Loader } from 'rsuite';

import { CompanyModal } from '../../components/Alerts/CompanyModal';
import { IdentityModal } from '../../components/Alerts/IdentityModal';
import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { useGetProjectOffer } from '../../firebase/hooks/getProjectOfferById.hook';
import { Offer, OfferStatusState } from '../../models/offers.model';
import { App } from '../App/App';
import { useAuth } from '../Auth/AuthContext';
import { AppRoutes } from '../Routing/routing.model';

import { HowItWorks } from './components/HowItWorks';
import { OfferForm } from './components/OfferForm';
import { handleOnOfferAdd } from './hooks/handleOnOfferAdd.hook';
import { ImageVerificationModal } from './ImageVerificationModal';

interface Props {
  offerId: string;
}

interface PropsWhenAuth {
  offerId: string;
  initial: Offer;
  error: string;
  currentUser: any;
}

export const OfferAdd: React.FC<Props> = ({ offerId }) => {
  const { isAuthenticating, isAuth, currentUser } = useAuth();
  const { offer, error } = useGetProjectOffer(offerId);

  return (
    <App>
      {!isAuthenticating && isAuth && (
        <WhenAuth offerId={offerId} initial={offer} error={error} currentUser={currentUser} />
      )}
      {isAuthenticating && !isAuth && <WhenNotAuth />}
    </App>
  );
};

const WhenAuth: React.FC<PropsWhenAuth> = ({ offerId, initial, error, currentUser }) => {
  const { push } = useHistory();
  const [loading, setLoading] = useState<boolean>();
  const [isIdentityModalOpen, setIsIdentityModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [offer] = useGetOffer(offerId);
  const [imagesVerificationResults, setImagesVerificationResults] = useState<string | null>(null);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const onOfferAdd = () => {
    handleOnOfferAdd(
      currentUser,
      offer,
      setIsIdentityModalOpen,
      setIsCompanyModalOpen,
      setLoading,
      setImagesVerificationResults,
      push
    );

    if (imagesVerificationResults) {
      setShowVerificationModal(true);
    }
  };

  const handleCloseModal = () => setShowVerificationModal(false);

  useEffect(() => {
    console.log('imagesVerificationResults', imagesVerificationResults);
  }, [imagesVerificationResults]);

  if (error) {
    return (
      <>
        <Space direction="vertical" style={{ width: '100%', marginTop: '100px', marginBottom: '60%', padding: '50px' }}>
          <Alert showIcon message="Nie masz dostępu do tego formularza!" description={error} type="error" />
        </Space>
      </>
    );
  } else if (initial === undefined) {
    return (
      <>
        <HowItWorks />
        <Loader
          style={{ position: 'absolute', top: '108%', left: '50%', transform: 'translate(-50%, -50%)' }}
          size="md"
          center
          content="loading..."
        />
      </>
    );
  } else if (offer.offerStatus.state !== OfferStatusState.UNSIGNED) {
    return <WhenOfferSigned />;
  } else {
    return (
      <>
        <OfferForm offerId={offerId} initial={initial} onAddOffers={onOfferAdd} isAddingPending={loading} />
        <IdentityModal isIdentityModalOpen={isIdentityModalOpen} />
        <CompanyModal isCompanyModalOpen={isCompanyModalOpen} />
        <ImageVerificationModal
          show={showVerificationModal}
          onClose={handleCloseModal}
          verificationResults={imagesVerificationResults}
          onChangeImage={() => push(AppRoutes.ADD_OFFER.replace(':id', offer.id))}
        />
        <HowItWorks />
      </>
    );
  }
};

const WhenNotAuth: React.FC = () => {
  const { showSignInModal, closeModal } = useAuth();

  useEffect(() => {
    showSignInModal();
    return () => closeModal();
  }, []);

  return <></>;
};

const WhenOfferSigned: React.FC = () => {
  return (
    <>
      <Space direction="vertical" style={{ width: '100%', marginTop: '100px', marginBottom: '60%', padding: '50px' }}>
        <Alert showIcon message="Błąd" description="Oferta nie jest dostępna do edycji." type="error" />
      </Space>
    </>
  );
};
