import { useField } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckPicker, ControlLabel, FormControl, FormControlProps, FormGroup, SelectPicker } from 'rsuite';

interface Props extends FormControlProps {
  name: string;
  label: string;
  width?: number;
  highlighted?: boolean;
}
export const FormInput: React.FC<Props> = ({ name, label, inputAccepter, ...props }) => {
  const [field, meta, form] = useField(name);
  const fieldError: string = meta.touched && meta.error ? meta.error : null;
  const { t } = useTranslation();

  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
      <FormControl
        name={name}
        accepter={inputAccepter}
        {...field}
        errorMessage={fieldError}
        value={field.value}
        onChange={(e) => form.setValue(e)}
        onBlur={field.onBlur}
        placeholder={
          inputAccepter == CheckPicker || inputAccepter == SelectPicker ? t('COMMON.FORMS.PLACEHOLDERS.SELECT') : null
        }
        style={{
          width: props.width ? props.width : 280,
          border: props.highlighted ? '1px solid rgba(0, 0, 255, 0.5)' : undefined,
          borderRadius: props.highlighted ? 6 : undefined,
          outline: props.highlighted ? 'none !important' : undefined,
          boxShadow: props.highlighted ? '0 0 5px rgba(0, 0, 255, 0.3)' : undefined,
        }}
        {...props}
      />
    </FormGroup>
  );
};
