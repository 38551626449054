import * as React from 'react';

import { Typography } from '../Typography/Typography';

interface Props {
  onClick?: () => void;
  href?: string;
  className?: string;
}
export const Anchor: React.FC<Props> = ({ onClick, children, ...props }) => {
  return (
    <Typography.Anchor
      {...props}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}>
      {children}
    </Typography.Anchor>
  );
};
