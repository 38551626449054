import { useMemo } from 'react';

import { SelectOption } from '../../../../models/main.model';

export function useOfficeTypeOptions(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () =>
      ['pom. biurowe', 'pom. biurowe z witryną', 'open space', 'sala konferencyjna', 'recepcja'].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}
