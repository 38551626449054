import { Offer } from '../models/offers.model';

export enum FirestoreCollection {
  OFFERS = 'offers',
  CONTRACTS = 'contracts',
  USERS = 'users',
  ADMIN = 'admin',
}

export enum CollectionLegalEntity {
  TENANT = 'tenant',
  LANDLORD = 'landlord',
}

export interface OfferOption {
  id: number;
  title: string;
  qualifiedSignature: boolean;
}

export interface FunctionSignOfferPayload {
  offer: Offer;
  selectedOfferOption: OfferOption;
}
export interface onBookOfferPayload {
  offerId: string;
  selectedOfferOption: OfferOption;
}
export interface FunctionAddOfferPayload {
  offerId: string;
}
export interface FunctionSignOfferConfirmPayload {
  offerId: string;
  userUid: string;
}
export interface FunctionAskQuestion {
  offerName: string;
  title: string;
  description: string;
  tenant: string;
  landlord: string;
}

export interface User {
  uid: string;
  firstName: string;
  lastName: string;
}

export enum FirebaseCallableFunctions {
  ON_OFFER_SIGN = 'onOfferSign',
  ON_OFFER_ADD = 'onOfferAdd',
  ON_OFFER_ADD_CONFIRM = 'onOfferAddConfirm',
  ON_OFFER_SIGN_CONFIRM = 'onOfferSignConfirm',
  ON_NEW_USER = 'onNewUser',
  ADD_TO_FAVORITES = 'addToFavorites',
  ON_CREATE_OFFER = 'onCreateOffer',
  ON_DELETE_ACCOUNT_CONFIRM = 'onDeleteAccountConfirm',
  ON_REPORT = 'onReport',
  ON_UPLOAD_SIGNATURE = 'onUploadSignature',
  ON_ADD_COMPANY = 'onAddCompany',
  ON_RECEPTION_TO_LANDLORD = 'onReceptionToLandlord',
  ON_RECEPTION_TO_TENANT = 'onReceptionToTenant',
  ON_ASK_QUESTION = 'onAskQuestion',
  ON_PROTOCOL_ATTACHED = 'onProtocolAttached',
  NOTIFY_ADMIN_ON_CONTRACT_SIGNED = 'notifyAdminOnContractSigned',
  ON_COUNTER_OFFER = 'onCounterOffer',
  ON_COUNTER_OFFER_DECLINED = 'onCounterOfferDeclined',
  ON_COUNTER_OFFER_ACCEPTED = 'onCounterOfferAccepted',
  REMOVE_FROM_FAVORITES = 'removeFromFavorites',
  GENERATE_OFFER_PDF_AND_UPLOAD = 'generateOfferPdfAndUpload',
  ON_BOOK_OFFER = 'onBookOffer',
  ON_REJECT_PROTOCOL = 'onRejectProtocol',
  CONFIRM_OR_DECLINE_PROTOCOL = 'confirmOrDecline',
  MAIL_NEW_OFFER = 'mailNewOffer',
  VERIFY_PHOTOS = 'verifyPhotos',
  VERIFY_PHOTOS_VISION = 'verifyPhotosVision',
  VERIFY_PHOTOS_GEMINI = 'verifyPhotosGemini',
  SEND_EMAIL_VERIFICATION_CODE = 'sendEmailVerificationCode',
  VERIFY_EMAIL_VERIFICATION_CODE = 'verifyEmailVerificationCode',
}
