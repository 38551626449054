import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'rsuite';

import { ModalConfig, useAuth } from '../AuthContext';
import { AdditionalLink } from '../styles';
import { Typography } from '../../../components/Typography/Typography';

import { SignInForm } from './component/SignInForm';

export const SignIn: React.FC<ModalConfig> = ({ title, onSuccess }) => {
  const { showForgotPasswordModal, showSignUpModal } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header>
        <Typography.H3>{title || t('FEATURES.AUTH.SIGN_IN.HEADER')}</Typography.H3>
      </Modal.Header>
      <Modal.Body>
        <SignInForm onSuccess={onSuccess} />
      </Modal.Body>
      <Modal.Footer>
        <AdditionalLink>
          <Trans
            i18nKey="FEATURES.AUTH.SIGN_IN.FORGOT_PASSWORD"
            components={{
              link1: (
                <Typography.Anchor
                  onClick={(e) => {
                    e.preventDefault();
                    showForgotPasswordModal();
                  }}
                />
              ),
            }}
          />
        </AdditionalLink>
        <AdditionalLink>
          <Trans
            i18nKey="FEATURES.AUTH.SIGN_IN.DONT_HAVE_ACCOUNT"
            components={{
              link1: (
                <Typography.Anchor
                  onClick={(e) => {
                    e.preventDefault();
                    showSignUpModal({ title, onSuccess });
                  }}
                />
              ),
            }}
          />
        </AdditionalLink>
      </Modal.Footer>
    </>
  );
};
