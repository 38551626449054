import type { MenuProps } from 'antd';
import { Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import { App } from '../../../App/App';
import { AppRoutes } from '../../../Routing/routing.model';
import { MenuWrapper } from '../../components/MenuWrapper';
import { additionalNavigation } from '../../Menu/AdditionalNavigation';
import { MenuItems } from '../../Menu/Menu';

export const Support: React.FC = () => {
  const { push } = useHistory();
  const onClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  return (
    <App>
      <MenuWrapper>
        <MenuItems
          onClick={onClick}
          defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_SUPPORT]}
          defaultOpenKeys={['sub4']}
        />
      </MenuWrapper>
      <Space direction="vertical" style={{ margin: 20, width: '80%' }}>
        <p>Oto niektóre z rzeczy, które możesz rozważyć umieszczenie na podstronie Pomoc:</p>
        <p>
          1. <strong>Instrukcje korzystania z platformy:</strong> Przewodniki, które pokazują, jak korzystać z
          podstawowych funkcji platformy, takich jak tworzenie konta, wyszukiwanie nieruchomości, składanie wniosków o
          najem, płatności etc. Mogą to być instrukcje krok po kroku, najlepiej zrzuty ekranu lub filmiki instruktażowe.
        </p>
        <p>
          2. <strong>Często zadawane pytania (FAQ): </strong>Sekcja FAQ powinna zawierać odpowiedzi na najczęściej
          zadawane pytania dotyczące korzystania z platformy, procesu wynajmu, płatności i innych aspektów.
        </p>
        <p>
          3. <strong>Informacje o zasadach i regulaminie platformy: </strong>To powinny być linki do pełnego tekstu
          zasad platformy, polityki prywatności oraz regulaminu.
        </p>
        <p>
          4. <strong>Kontakt do wsparcia klienta: </strong>Powinno być jasne, jak skontaktować się z zespołem wsparcia,
          jeśli użytkownik ma problemy, których nie może sam rozwiązać. Może to obejmować adres e-mail, numer telefonu,
          formularz kontaktowy, czy informacje o dostępności czatu na żywo.
        </p>
        <p>
          5. <strong>Porady i wskazówki:</strong> Dla wynajmujących, to mogą być porady dotyczące tego, jak stworzyć
          atrakcyjne ogłoszenie, jak bezpiecznie przeprowadzić transakcję, jakie są prawa i obowiązki wynajmującego. Dla
          najemców, to mogą być informacje o tym, na co zwracać uwagę przy wyborze nieruchomości, jak bezpiecznie
          płacić, jakie są prawa i obowiązki najemcy.
        </p>
        <p>
          6. <strong>Informacje o procesie rozwiązywania sporów:</strong> Jeśli na platformie dochodzi do konfliktów
          między wynajmującymi a najemcami, użytkownicy powinni wiedzieć, jakie są procedury rozwiązywania tych
          konfliktów i jak mogą zgłosić problem.
        </p>
        <p>
          7. <strong>Zasady bezpieczeństwa:</strong> Wyjaśnienia dotyczące zasad bezpieczeństwa platformy, takie jak
          zasady dotyczące przetwarzania danych osobowych, płatności, jak unikać oszustw etc.
        </p>

        <p>
          8. <strong>Glosariusz: </strong>Terminy i definicje związane z wynajmem nieruchomości, które mogą nie być
          jasne dla wszystkich użytkowników.
        </p>
      </Space>
    </App>
  );
};
