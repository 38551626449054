import { Badge, Popover, Skeleton } from 'antd';
import * as React from 'react';

import { useAuth } from '../../Auth/AuthContext';
export const SignInToShow: React.FC<{ text?: string; rows?: number }> = ({ text, rows }) => {
  const { showSignInModal } = useAuth();
  const content = (
    <div className="w-96">
      <div>
        Niniejsza oferta jest rozumiana zgodnie z art. 66 Kodeksu cywilnego i jest skierowana wyłącznie do określonych
        adresatów. W związku z tym nie jesteśmy uprawnieni do jej udostępniania osobom, które nie są jej adresatami.
        Uprzejmie prosimy o{' '}
        <a className="underline text-blue-500" onClick={() => showSignInModal()}>
          zalogowanie się
        </a>
        , aby uzyskać dostęp do szczegółów oferty.
      </div>
    </div>
  );
  return (
    <Popover content={content} title="Dlaczego nie mogę zobaczyć szczegółów?" trigger="hover" placement="bottomLeft">
      <div className="text-white -my-4">{text ? text : 'Zaloguj się, aby zobaczyć szczegóły oferty'} </div>
      <Badge.Ribbon
        text={
          <a className="hover:text-gray-50" onClick={() => showSignInModal()}>
            {text ? text : 'Zaloguj się, aby zobaczyć szczegóły oferty'}{' '}
          </a>
        }>
        <div className="h-10"></div>
        <Skeleton active paragraph={{ rows: rows ? rows : 2 }} title={false} />
      </Badge.Ribbon>
    </Popover>
  );
};
