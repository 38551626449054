import { useMemo } from 'react';

import { SelectOption } from '../../../models/main.model';

export function usePremiseConditionOptions(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () =>
      ['Nowe', 'Używany - Dobry stan', 'Używany - Umiarkowany stan', 'Odnowiony', 'Uszkodzony', 'Nie dotyczy'].map(
        (item) => ({
          label: item,
          value: item,
        })
      ),
    []
  );
}
