import { Button, Card, Collapse, CollapseProps, Space } from 'antd';
import Typography from 'antd/es/typography';
import * as React from 'react';
import { Divider } from 'rsuite';

import { App } from '../App/App';

import RecruitmentHeader from './RecruitmentHeader';

const { Title, Paragraph } = Typography;

const whyWithUs: CollapseProps['items'] = [
  {
    key: '2',
    label: 'Rozwój kariery',
    children: (
      <Card>
        Stawiamy na Twój rozwój! Po 6 miesiącach masz szansę stać się &ldquo;Twarzą Projektu&ldquo; i zyskać kluczową
        rolę w naszej firmie.
      </Card>
    ),
  },
  {
    key: '3',
    label: 'Atrakcyjne wynagrodzenie',
    children: (
      <Card>
        Oferujemy konkurencyjne wynagrodzenie: podstawa 5000 zł oraz premia za wyniki w wysokości 5 000 - 10 000 zł/m-c.
      </Card>
    ),
  },
  {
    key: '4',
    label: 'Elastyczne warunki pracy',
    children: (
      <Card>
        Pracuj zdalnie, hybrydowo lub stacjonarnie w Warszawie (Atrium Reduta lub Centrum Praskie Koneser). Możemy
        również zapewnić wynajęcie biura, jeśli preferujesz pracę zdalną. Okres wdrożenia, praca stacjonarna.
      </Card>
    ),
  },
  {
    key: '5',
    label: 'Szkolenie i wsparcie',
    children: (
      <Card>
        Zadbamy o Twój rozwój poprzez 4-tygodniowe szkolenie wdrażające, które przygotuje Cię do nowych wyzwań i
        zapewni, że pracujemy według wspólnych standardów.
      </Card>
    ),
  },
  {
    key: '6',
    label: 'Możliwość przyszłości w zarządzie',
    children: <Card>Najlepsi mają szansę na dołączenie do zarządu firmy.</Card>,
  },
];

const whoWeAre: CollapseProps['items'] = [
  {
    key: '2',
    label: 'Aktualność ofert',
    children: <Card>Każda oferta widoczna na stronie jest aktualna i gotowa do wynajęcia.</Card>,
  },
  {
    key: '3',
    label: 'Weryfikacja',
    children: <Card>Wszystkie oferty są przez nas zweryfikowane i prawdziwe.</Card>,
  },
  {
    key: '4',
    label: 'Kompleksowość',
    children: <Card>Wszystkie dokumenty oraz możliwość zawarcia transakcji dostępne są w jednym miejscu.</Card>,
  },
  {
    key: '5',
    label: 'Szybkość i sprawność',
    children: (
      <Card>
        Operacja najmu odbywa się szybko i sprawnie, bez oczekiwania na długie procesy i wielokrotne spotkania.
      </Card>
    ),
  },
  {
    key: '6',
    label: 'Bezpieczeństwo',
    children: <Card>Gwarantujemy bezpieczeństwo i przejrzystość transakcji.</Card>,
  },
  {
    key: '7',
    label: 'Innowacyjność',
    children: (
      <Card>
        Pierwsze na rynku rozwiązanie, które umożliwia wynajem lokalu w 100% online. Nasza platforma wyróżnia się
        błyskawiczną decyzją i prostotą procesu.
      </Card>
    ),
  },
];

export const Recruitment: React.FC = () => {
  const handleClick = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSc9lRv1hyl6Qtnw-7iB2WYvmnUpmnxBMggQyLtclZ5iRsEAmQ/viewform',
      '_blank'
    );
  };
  return (
    <App>
      <RecruitmentHeader />
      <Space className="block p-2 lg:p-0 lg:m-16 w-full lg:w-1/2 lg:mx-auto">
        <Title>
          Marzysz o dynamicznej karierze w branży nieruchomości? A może czujesz wypalenie i potrzebujesz “nowego
          otwarcia”?
        </Title>
        <Paragraph>
          Chcesz stać się kluczowym graczem na rynku i reprezentować innowacyjny startup? najmuje.com szuka Ciebie!
          Dołącz do nas jako Specjalista ds. Sprzedaży i Marketingu i bądź częścią nadchodzącej rewolucji na rynku,
          rozwijaj swoją karierę w nowoczesnym środowisku pełnym wyzwań i możliwości.
        </Paragraph>
        <Title level={2} className="mt-12">
          Dlaczego warto z nami pracować?
        </Title>
        <Collapse accordion items={whyWithUs} className="my-8" />
        <Title level={2}>Kim jesteśmy?</Title>
        <Paragraph>
          Najmuje.com to innowacyjny startup, który zrewolucjonizuje rynek nieruchomości, łącząc właścicieli z najemcami
          za pomocą efektywnego i przyjaznego procesu online. Nasz portal internetowy oferuje szybkie, bezproblemowe i
          bezpieczne rozwiązania najmu nieruchomości. Wyróżnia nas:
        </Paragraph>
        <Collapse accordion items={whoWeAre} className="my-8" />
        <Title level={2}>Kogo szukamy?</Title>
        <Paragraph>
          Osoby z pierwszym doświadczeniem w sprzedaży B2B, najlepiej w sektorze nieruchomości. Osoby z żyłką
          przedsiębiorczości, ambitnej, pracowitej i żądnej sukcesu. Osoby pozytywnie nastawionej i silnie zmotywowanej
          do pracy...
        </Paragraph>
        <Title level={2}>Twoje zadania:</Title>
        <Paragraph>
          Przez pierwsze 6 miesięcy: tzw. &ldquo;warm calling&ldquo; do naszej bazy w celu zdobycia potencjalnych
          klientów dla najmuje.com. Po 6 miesiącach: przejęcie roli &ldquo;twarzy projektu&ldquo;, aktywny udział w
          wydarzeniach branżowych, prowadzenie webinarów, reprezentowanie firmy w mediach społecznościowych. Docelowo:
          współtworzenie strategii marketingowej i sprzedażowej, potencjalne członkostwo w zarządzie/ udziałach spółki.
        </Paragraph>
        <Title level={2}>Co oferujemy?</Title>
        <Paragraph>
          Stabilne podstawowe wynagrodzenie z możliwością jego wypłaty z góry. Atrakcyjne wynagrodzenie za osiągane
          wyniki. Możliwość szybkiego rozwoju zawodowego i osobistego. Pracę w dynamicznym, innowacyjnym zespole.
        </Paragraph>
        <Title level={2}>Jak aplikować?</Title>
        <Paragraph>
          Wypełnij <a onClick={handleClick}>ANKIETĘ REKRUTACYJNĄ</a>. Nie przesyłaj nam swojego CV. Chcemy poznać Ciebie
          i Twoje doświadczenie, dlatego znajdź 5-10 minut i wypełnij nasz kwestionariusz, który pozwoli nam sprawdzić,
          czy możemy razem pracować.
        </Paragraph>
        <Button type="primary" size="large" className="mt-4" onClick={handleClick}>
          Dołącz do nas
        </Button>
        <Divider className="mb-48 mt-8"></Divider>
      </Space>
    </App>
  );
};
