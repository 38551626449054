import { Button, Dropdown, MenuProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Offer, OfferStatusState, PostSigningStatus } from '../../../models/offers.model';

const StyledDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 30px;
`;

const baseHostname = window.location.hostname;
const port = baseHostname === 'localhost' ? ':3000' : '';
const httpOrHttps = port ? 'http' : 'https';

interface Props {
  showModal: () => void;
  offer: Offer;
  state: OfferStatusState;
  duplicate: () => void;
  showDocuments: () => void;
  isLandlord: boolean;
  onRejectProtocol: () => void;
}

export const DropdownButton: React.FC<Props> = ({
  showModal,
  offer,
  state,
  duplicate,
  showDocuments,
  isLandlord,
  onRejectProtocol,
}) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <a onClick={duplicate}>Duplikuj jako projekt</a>,
    },
    {
      key: '3',
      label: <a onClick={showDocuments}>Dokumenty</a>,
    },
  ];

  if (state === OfferStatusState.UNSIGNED) {
    items.push({
      key: '2',
      label: <a onClick={showModal}>Usuń</a>,
    });
  }
  if (
    !isLandlord &&
    state === OfferStatusState.SIGNED &&
    offer.offerStatus.postSigningStatus !== PostSigningStatus.PROTOCOL_CONFIRMED
  ) {
    items.push({
      key: '4',
      label: <a onClick={onRejectProtocol}>Odmów przyjęcia Lokalu</a>,
    });
  }
  if (
    offer.offerStatus.postSigningStatus === PostSigningStatus.DEPOSIT_CONFIRMED ||
    offer.offerStatus.postSigningStatus === PostSigningStatus.NONE ||
    offer.offerStatus.postSigningStatus === PostSigningStatus.PROTOCOL_ATTACHED
  ) {
    items.push({
      key: '5',
      label: <a href={`${httpOrHttps}://${baseHostname}${port}/protocol/${offer.id}/`}>Protokół zdawczo-odbiorczy</a>,
    });
  }
  return (
    <StyledDiv>
      <Dropdown menu={{ items }} placement="bottomRight" arrow>
        <Button>Więcej</Button>
      </Dropdown>
    </StyledDiv>
  );
};
