import firebase from 'firebase';
import { useCallback, useState } from 'react';

import { Offer, OfferStatusState, SearchFormFilters } from '../../models/offers.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

import { useProcessDates } from './useProcessDates.hook';

export function useGetOffers(): [Offer[], (formFilters?: SearchFormFilters) => void, boolean, firebase.FirebaseError] {
  const [error, setError] = useState<firebase.FirebaseError>();
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState<Offer[]>([]);

  const getOffers = useCallback(async (formFilters) => {
    let isMounted = true;
    setLoading(true);
    setError(null);

    const baseQuery = firestore
      .collection(FirestoreCollection.OFFERS)
      .where('offerStatus.state', '==', OfferStatusState.ACTIVE);

    try {
      const snapshot = await baseQuery.get();
      let offers = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          ...useProcessDates({
            availability: data.availability,
            availabilityTo: data.availabilityTo,
            offerValidTo: data.offerValidTo,
          }),
        } as Offer;
      });

      console.log('Offers before filtering:', offers);

      if (formFilters) {
        // Zapisanie filtrów w cookies

        console.log(formFilters);

        offers = offers.filter((offer) => {
          if (formFilters.minArea && offer.areaDetails.surface < parseFloat(formFilters.minArea)) return false;
          if (formFilters.maxPrice && offer.price > formFilters.maxPrice) return false;
          if (formFilters.premisesPurpose && offer.legalMainUse !== formFilters.premisesPurpose) return false;
          if (formFilters.freeFrom) {
            const freeFromTimestamp = firebase.firestore.Timestamp.fromDate(formFilters.freeFrom);
            console.log('freeFromTimestamp:', freeFromTimestamp.toMillis());
            console.log('offer.availability:', offer.availability);
            console.log('offer.availabilityTo:', offer.availabilityTo);
            if (
              offer.availability > new Date(freeFromTimestamp.toMillis()) ||
              (offer.availabilityTo && offer.availabilityTo < new Date(freeFromTimestamp.toMillis()))
            )
              return false;
          }
          if (formFilters.province && offer.address.officialAddress.province !== formFilters.province) return false;
          if (formFilters.county && offer.address.officialAddress.county !== formFilters.county) return false;
          if (formFilters.district && offer.address.officialAddress.district !== formFilters.district) return false;
          if (formFilters.estate && offer.address.officialAddress.estate !== formFilters.estate) return false;
          if (formFilters.street && offer.address.officialAddress.street !== formFilters.street) return false;
          return true;
        });
      }

      if (isMounted) {
        setOffers(offers);
      }
    } catch (error: any) {
      if (isMounted) {
        console.error(error);
        setError(error);
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return [offers, getOffers, loading, error];
}
