import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Popover, Switch } from 'antd';
import axios from 'axios';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { Company } from './company.models';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface Props {
  initial: Company;
  onFinish: (values: Company) => void;
  disabled?: boolean;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormToLinkCompany: React.FC<Props> = ({ initial, onFinish, disabled, setDisabled }) => {
  const [numerKRS, setNumerKRS] = useState('');
  const [companyData, setCompanyData] = useState<Company>(initial);
  const [form] = Form.useForm<Company>();
  const [validateKRS, setValidateKRS] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState(false);
  const [iDontHaveKRS, setiDontHaveKRS] = useState(initial.numerKRS?.length === 0);

  useEffect(() => {
    // jeśli brak danych to domyślnie formularz jest aktywny
    if (Object.keys(companyData).length === 0) {
      setDisabled(false);
    }
  }, []);

  const onIDontHaveKRS = (checked: boolean) => {
    setiDontHaveKRS(checked);
    form.validateFields();
    if (checked) {
      setValidateKRS(false);
      form.setFieldValue('numerKRS', '');
    }
  };

  const fetchData = useCallback(
    debounce((numerKRS: string) => {
      axios
        .get(`https://api-krs.ms.gov.pl/api/krs/OdpisAktualny/${numerKRS}`)
        .then((res) => {
          setValidateKRS(false);
          setCompanyData(res.data);
          form.setFieldsValue({
            nazwa: res.data.odpis.dane.dzial1.danePodmiotu.nazwa,
            nip: res.data.odpis.dane.dzial1.danePodmiotu.identyfikatory.nip,
            regon: res.data.odpis.dane.dzial1.danePodmiotu.identyfikatory.regon,
            kraj: res.data.odpis.dane.dzial1.siedzibaIAdres.adres.kraj,
            kodPocztowy: res.data.odpis.dane.dzial1.siedzibaIAdres.adres.kodPocztowy,
            miejscowosc: res.data.odpis.dane.dzial1.siedzibaIAdres.adres.miejscowosc,
            nrDomu: res.data.odpis.dane.dzial1.siedzibaIAdres.adres.nrDomu,
            ulica: res.data.odpis.dane.dzial1.siedzibaIAdres.adres.ulica,
          });
        })
        .catch(() => {
          setValidateKRS(true);
        })
        .finally(() => {
          setIsFetching(false);
          form.validateFields(['numerKRS']);
        });
    }, 0),
    // Dodaj opóźnienie jeśli będzie problem z blokowaniem żądań
    []
  );

  useEffect(() => {
    if (numerKRS) {
      setIsFetching(true);
      fetchData(numerKRS);
    }
  }, [numerKRS]);

  return (
    <>
      <Form
        {...layout}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 24 }}
        style={{ width: 600 }}
        onFinish={onFinish}
        initialValues={initial}
        autoComplete="off"
        form={form}
        disabled={disabled}>
        <Divider>Identyfikatory</Divider>
        <Form.Item
          label="Numer KRS"
          name="numerKRS"
          rules={[
            { required: !iDontHaveKRS, message: 'Numer KRS jest wymagany' },
            {
              validator: (_, value) =>
                !isFetching && (!validateKRS || value === '')
                  ? Promise.resolve()
                  : Promise.reject(new Error('Nieprawidłowy numer KRS')),
            },
          ]}>
          <Input onChange={(e) => setNumerKRS(e.target.value)} disabled={disabled || iDontHaveKRS} />
        </Form.Item>
        <Form.Item>
          <div className="justify-center items-center text-right -my-10">
            <Switch onChange={onIDontHaveKRS} className="m-2" />
            Nie mam KRS
            <Popover
              trigger="hover"
              overlayStyle={{
                width: '30vw',
              }}
              content={
                <div className="font-semibold">
                  W Polsce istnieją działalności gospodarcze i firmy, które nie posiadają numeru KRS, czyli wpisu do
                  Krajowego Rejestru Sądowego. Numer KRS jest wymagany głównie dla spółek prawa handlowego (jak spółki z
                  o.o., spółki akcyjne) oraz innych podmiotów określonych w ustawie o KRS, na przykład fundacji czy
                  stowarzyszeń. <br></br>
                  <br></br> Natomiast jednoosobowe działalności gospodarcze oraz niektóre inne formy działalności, takie
                  jak spółki cywilne, nie są zobowiązane do rejestracji w KRS. Te mniejsze formy przedsiębiorczości
                  zazwyczaj rejestrują się w Centralnej Ewidencji i Informacji o Działalności Gospodarczej (CEIDG),
                  która jest innym rejestrem niż KRS.
                </div>
              }>
              <InfoCircleOutlined rev={undefined} className="m-2" />
            </Popover>
          </div>
        </Form.Item>
        <Form.Item label="NIP" name="nip" rules={[{ required: true, message: 'Pole nie może być puste' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="REGON" name="regon" rules={[{ required: true, message: 'Pole nie może być puste' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Nazwa firmy" name="nazwa" rules={[{ required: true, message: 'Pole nie może być puste' }]}>
          <Input />
        </Form.Item>
        <Divider>Adres</Divider>
        <Form.Item label="Kraj" name="kraj" rules={[{ required: true, message: 'Pole nie może być puste' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Kod pocztowy"
          name="kodPocztowy"
          rules={[{ required: true, message: 'Pole nie może być puste' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Miejscowosc"
          name="miejscowosc"
          rules={[{ required: true, message: 'Pole nie może być puste' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Numer domu" name="nrDomu" rules={[{ required: true, message: 'Pole nie może być puste' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Ulica" name="ulica" rules={[{ required: true, message: 'Pole nie może być puste' }]}>
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 5, span: 16 }} hidden={disabled}>
          <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}>
            Prześlij
          </Button>
        </Form.Item>
      </Form>
      <Form.Item wrapperCol={{ offset: 5, span: 16 }} hidden={!disabled}>
        <Button hidden={!disabled} onClick={() => setDisabled(!disabled)}>
          Edytuj
        </Button>
      </Form.Item>
    </>
  );
};
