import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  i18nKey: string;
  linkPaths: { [key: string]: string };
}

export const LocalizedLabelWithMultipleLinks: React.FC<Props> = ({ i18nKey, linkPaths }) => {
  const { t } = useTranslation();
  const rawText = t(i18nKey);
  const components = [];

  let cursor = 0;
  rawText.replace(/<(\d+)>/g, (match, key, offset) => {
    // Tekst przed znacznikiem
    components.push(rawText.slice(cursor, offset));

    // Etykieta linku
    const linkLabel = rawText.slice(offset + match.length, rawText.indexOf(`</${key}>`, offset));
    components.push(
      <a key={key} href={linkPaths[key]} className="text-blue-600" target="_blank" rel="noreferrer">
        {linkLabel}
      </a>
    );

    // Aktualizacja kursora
    cursor = rawText.indexOf(`</${key}>`, offset) + `</${key}>`.length;

    return match; // ten zwrot jest tu tylko dla formalności i nie jest używany
  });

  // Dodanie pozostałego tekstu
  components.push(rawText.slice(cursor));

  return <>{components}</>;
};
