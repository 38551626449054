import { useEffect, useState } from 'react';
import firebase from 'firebase';

import { Company } from '../../features/MojaTablica/Account/Identity/LinkCompany/company.models';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

export function useGetCompany(uid: string): [Company, boolean, firebase.FirebaseError] {
  const [error, setError] = useState<firebase.FirebaseError>();
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<Company>();

  useEffect(() => {
    setLoading(true);
    setError(null);
    const unsubscribe = firestore
      .collection(FirestoreCollection.USERS)
      .doc(uid)
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.data();
          setCompany(data ? ({ ...snapshot.data().company } as Company) : null);
          setLoading(false);
        },
        (e) => {
          console.log('Error', e);
          setError(e);
          setLoading(false);
        }
      );
    return () => unsubscribe();
  }, [uid]);

  return [company, loading, error];
}
