import * as React from 'react';
import styled from 'styled-components';
import { Icon, IconButton } from 'rsuite';

import { Offer } from '../../../models/offers.model';

const StyledIconButton = styled(IconButton)`
  padding-left: 24px !important;

  ${({ clickable }) =>
    !clickable &&
    `cursor: auto ;
     text-decoration: none !important;
     &:hover {
         text-decoration: none !important;
     }
  `}
`;
const StyledIcon = styled(Icon)<{ clickable: boolean }>`
  line-height: 0.8 !important;
  width: 20px !important;
  transform: translateY(-50%) !important;
  font-size: 24px !important;
  top: 50% !important;
`;

interface Props {
  offer: Offer;
  onLocationClick: ((id: string) => void) | null;
}
export const OfferLocationButton: React.FC<Props> = ({ offer, onLocationClick }) => {
  return (
    <StyledIconButton
      clickable={onLocationClick}
      appearance="link"
      icon={<StyledIcon icon="map-marker" size={'lg'} />}
      onClick={() => onLocationClick?.(offer.id)}>
      <div>
        {offer.address.street
          ? `${offer.address.street}, ${offer.address.postCode} ${offer.address.city}`
          : 'brak informacji'}
      </div>
    </StyledIconButton>
  );
};
