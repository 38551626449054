import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'rsuite';

import { Typography } from '../../../components/Typography/Typography';
import { ModalConfig } from '../AuthContext';

import { NonEmailSignUpForm } from './components/NonEmailSignUpForm';

export const NonEmailSignUp: React.FC<ModalConfig> = ({ onSuccess }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header>
        <Typography.H3>{t('FEATURES.AUTH.SIGN_UP.COMPLETE_FORM')}</Typography.H3>
      </Modal.Header>
      <Modal.Body>
        <NonEmailSignUpForm onSuccess={onSuccess} />
      </Modal.Body>
    </>
  );
};
