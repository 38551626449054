import { Button, message } from 'antd';
import React from 'react';

import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';
import { ToastVariant } from '../../Toast/toast.model';
import { toastService } from '../../Toast/toast.service';

interface Props {
  offerId: string;
  uid: string;
}

export const DeleteFavorite: React.FC<Props> = ({ offerId, uid }) => {
  const handleDelete = () => {
    message.loading('Usuwanie...', 0);
    const removeFromFavorites = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.REMOVE_FROM_FAVORITES);
    removeFromFavorites({ offerId: offerId, uid: uid })
      .then(() => {
        toastService.show('Usunięto z ulubionych', 'Sukces');
      })
      .catch(() => {
        toastService.show('Coś poszło nie tak. Spróbuj później', 'Błąd', { variant: ToastVariant.ERROR });
      })
      .finally(() => {
        message.destroy();
      });
  };

  return (
    <Button className="absolute top-0 right-0 m-6" onClick={handleDelete}>
      Usuń
    </Button>
  );
};
