import { Card, Col, Divider, Form, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';

import { Offer } from '../../../models/offers.model';
import { Level, Room } from '../../../models/protocol.model';

import { usePremiseConditionOptions } from './usePremiseConditionOptions.hook';

interface Props {
  onChange: (data: Level[]) => void;
  offer: Offer;
  initialValues: Level[]; // Dodanie prop initialValues
}

const EstablishedPremiseCondition: React.FC<Props> = ({ onChange, offer, initialValues }) => {
  const [levels, setLevels] = useState<Level[]>(() =>
    offer.areaDetails.levels.map((level, index) => ({
      levelNumber: level.levelNumber,
      levelName: level.levelName,
      offices: level.offices?.map((office, i) => ({
        name: office.type,
        condition: initialValues?.[index]?.offices?.[i]?.condition || '',
        comments: initialValues?.[index]?.offices?.[i]?.comments || '',
      })),
      warehouses: level.warehouses?.map((room, i) => ({
        name: room.type,
        condition: initialValues?.[index]?.warehouses?.[i]?.condition || '',
        comments: initialValues?.[index]?.warehouses?.[i]?.comments || '',
      })),
      commercialAndServiceRooms: level.commercialAndServiceRooms?.map((room, i) => ({
        name: room.type,
        condition: initialValues?.[index]?.commercialAndServiceRooms?.[i]?.condition || '',
        comments: initialValues?.[index]?.commercialAndServiceRooms?.[i]?.comments || '',
      })),
      technicalRooms: level.technicalRooms?.map((room, i) => ({
        name: room.type,
        condition: initialValues?.[index]?.technicalRooms?.[i]?.condition || '',
        comments: initialValues?.[index]?.technicalRooms?.[i]?.comments || '',
      })),
      otherRoomsDetails: level.otherRoomsDetails?.map((detail, i) => ({
        name: detail,
        condition: initialValues?.[index]?.otherRoomsDetails?.[i]?.condition || '',
        comments: initialValues?.[index]?.otherRoomsDetails?.[i]?.comments || '',
      })),
    }))
  );

  useEffect(() => {
    setLevels(() =>
      offer.areaDetails.levels.map((level, index) => ({
        levelNumber: level.levelNumber,
        levelName: level.levelName,
        offices: level.offices?.map((office, i) => ({
          name: office.type,
          condition: initialValues?.[index]?.offices?.[i]?.condition || '',
          comments: initialValues?.[index]?.offices?.[i]?.comments || '',
        })),
        warehouses: level.warehouses?.map((room, i) => ({
          name: room.type,
          condition: initialValues?.[index]?.warehouses?.[i]?.condition || '',
          comments: initialValues?.[index]?.warehouses?.[i]?.comments || '',
        })),
        commercialAndServiceRooms: level.commercialAndServiceRooms?.map((room, i) => ({
          name: room.type,
          condition: initialValues?.[index]?.commercialAndServiceRooms?.[i]?.condition || '',
          comments: initialValues?.[index]?.commercialAndServiceRooms?.[i]?.comments || '',
        })),
        technicalRooms: level.technicalRooms?.map((room, i) => ({
          name: room.type,
          condition: initialValues?.[index]?.technicalRooms?.[i]?.condition || '',
          comments: initialValues?.[index]?.technicalRooms?.[i]?.comments || '',
        })),
        otherRoomsDetails: level.otherRoomsDetails?.map((detail, i) => ({
          name: detail,
          condition: initialValues?.[index]?.otherRoomsDetails?.[i]?.condition || '',
          comments: initialValues?.[index]?.otherRoomsDetails?.[i]?.comments || '',
        })),
      }))
    );
  }, [initialValues, offer.areaDetails.levels]);

  const allowedRoomTypes = [
    'offices',
    'warehouses',
    'commercialAndServiceRooms',
    'technicalRooms',
    'otherRoomsDetails',
  ];

  const handleRoomUpdate = (levelIndex: number, roomType: keyof Level, roomIndex: number, updatedRoom: Room) => {
    const newLevels = [...levels];
    const level = newLevels[levelIndex];

    const property = level[roomType];
    if (Array.isArray(property)) {
      const rooms = property as Room[];
      rooms[roomIndex] = updatedRoom;
      level[roomType] = rooms;
    }

    setLevels(newLevels);
    onChange(newLevels);
  };

  return (
    <Card title={<div className="text-base font-medium">A. Stwierdzony stan Lokalu</div>} className="mb-2">
      {levels.map((level, levelIndex) => (
        <Form layout="vertical" key={levelIndex}>
          <Row className="font-bold text-base pb-4">
            Kondygnacja: {level.levelNumber} ({level.levelName}):
          </Row>
          {Object.entries(level)
            .filter(([key]) => allowedRoomTypes.includes(key))
            .map(([type, rooms]) =>
              addRoomsToTable(type as keyof Level, rooms as Room[], levelIndex, handleRoomUpdate)
            )}
        </Form>
      ))}
    </Card>
  );
};

function addRoomsToTable(
  type: keyof Level,
  rooms: Room[],
  levelIndex: number,
  handleRoomUpdate: (levelIndex: number, roomType: keyof Level, roomIndex: number, updatedRoom: Room) => void
) {
  const conditionOptions = usePremiseConditionOptions();
  const translatedType = getTranslatedType(type);

  if (!rooms || rooms.length === 0) return null;

  return (
    <div className="my-4">
      <Divider plain orientation="center">
        {translatedType}
      </Divider>
      {rooms.map((room, roomIndex) => (
        <Row key={roomIndex}>
          <Col span={8}>{room.name}</Col>
          <Col span={8}>
            <Form.Item className="mr-4">
              <Select
                options={conditionOptions}
                onChange={(value) => handleRoomUpdate(levelIndex, type, roomIndex, { ...room, condition: value })}
                value={room.condition}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <TextArea
                rows={1}
                placeholder="Brak"
                maxLength={1000}
                value={room.comments}
                onChange={(e) => handleRoomUpdate(levelIndex, type, roomIndex, { ...room, comments: e.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      ))}
    </div>
  );
}

function getTranslatedType(type: keyof Level): string {
  const types = {
    offices: 'Pomieszczenia biurowe',
    warehouses: 'Magazyny',
    commercialAndServiceRooms: 'Pomieszczenia komercyjne i usługowe',
    technicalRooms: 'Pomieszczenia techniczne',
    otherRoomsDetails: 'Inne pomieszczenia',
  };
  return types[type] || `Pomieszczenia typu ${type.toString()}`;
}

export default EstablishedPremiseCondition;
