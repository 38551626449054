import { Button, Card, Form, Space, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';

import { Offer, PostSigningStatus } from '../../models/offers.model';
import {
  Equipment,
  Level,
  LocalMeterReadings,
  ProtocolData,
  TransferredKeysAndDevices,
} from '../../models/protocol.model';
import { useAuth } from '../Auth/AuthContext';

import EstablishedEquipmentState from './Components/EstablishedEquipmentState';
import EstablishedPremiseCondition from './Components/EstablishedPremiseCondition';
import LocalMeterReadingsComponent from './Components/LocalMeterReadings';
import TransferedKeysAndDevices from './Components/TransferedKeysAndDevices';

interface Props {
  offerId: string;
  o: Offer;
  onFinish: (values: ProtocolData) => void;
  isAddingPending: boolean;
  active: boolean;
  handleApprovalClick: (accepted: boolean) => void;
}

export const ProtocolForm: React.FC<Props> = ({
  offerId,
  o,
  onFinish,
  isAddingPending,
  active,
  handleApprovalClick,
}) => {
  const [form] = Form.useForm<ProtocolData>();

  const { currentUser } = useAuth();

  const handleTransferedKeysChange = (data: TransferredKeysAndDevices[]) => {
    form.setFieldsValue({
      transferredKeysAndDevices: data,
    });
  };

  const handleLocalMeterReadings = (data: LocalMeterReadings[]) => {
    form.setFieldsValue({
      localMeterReadings: data,
    });
  };

  const handleEstablishedEquipmentStateChange = (data: Equipment[]) => {
    form.setFieldsValue({
      establishedEquipmentState: data,
    });
  };

  const handleEstablishedPremiseConditionChange = (data: Level[]) => {
    console.log(data);
    form.setFieldsValue({
      establishedPremiseCondition: data,
    });
  };

  const validateOfferData = (): boolean => {
    if (!o.transferOfThePremise && currentUser.uid == o.uid) {
      message.error(
        'Formularz dot. przekazania Lokalu nie został wypełniony. Sprawdź poprzednie maile i uzupełnij formularz',
        0
      );
      return false;
    } else if (!o.transferOfThePremise && currentUser.uid != o.uid) {
      message.error(
        'Formularz dot. przekazania Lokalu nie został wypełniony. Wynajmujący musi uzupełnić formularz odbioru.',
        0
      );
      return false;
    }
    if (!o.receptionTime && currentUser.uid != o.uid) {
      message.error(
        'Formularz dot. czasu odbioru Lokalu nie został wypełniony. Sprawdź poprzednie maile i uzupełnij',
        0
      );
      return false;
    } else if (!o.receptionTime && currentUser.uid == o.uid) {
      message.error(
        'Formularz dot. czasu odbioru Lokalu nie został wypełniony. Najemca musi uzupełnić formularz odbioru.',
        0
      );
      return false;
    }
    return true;
  };

  const handleFinish = (values: ProtocolData) => {
    values.transferredKeysAndDevices = form.getFieldValue('transferredKeysAndDevices');
    values.localMeterReadings = form.getFieldValue('localMeterReadings');
    values.establishedEquipmentState = form.getFieldValue('establishedEquipmentState');
    values.establishedPremiseCondition = form.getFieldValue('establishedPremiseCondition');
    values.additionalStatements = form.getFieldValue('additionalStatements') ?? 'Brak';
    if (!validateOfferData()) {
      return;
    }
    form.resetFields();
    onFinish(values);
  };

  return (
    <Space direction="vertical" className="sm:w-max md:w-5/6 lg:w-2/3 m-8">
      <div className="text-lg font-semibold">Formularz Protokołu dla Oferty: {o.name}</div>
      <div className="opacity-50">Nr Oferty: {offerId}</div>
      <Form form={form} onFinish={handleFinish} layout="vertical" disabled={!active} initialValues={o.protocolData}>
        <EstablishedPremiseCondition
          onChange={handleEstablishedPremiseConditionChange}
          offer={o}
          initialValues={o.protocolData?.establishedPremiseCondition}
        />
        <EstablishedEquipmentState
          onChange={handleEstablishedEquipmentStateChange}
          offer={o}
          initialValues={o.protocolData?.establishedEquipmentState}
        />
        <LocalMeterReadingsComponent
          onChange={handleLocalMeterReadings}
          initialValues={o.protocolData?.localMeterReadings}
        />

        <TransferedKeysAndDevices
          onChange={handleTransferedKeysChange}
          initialValues={o.protocolData?.transferredKeysAndDevices}
        />

        <Card title="E. Dodatkowe stwierdzenia / ustalenia" className="mt-4">
          <Form.Item name="additionalStatements">
            <TextArea rows={4} placeholder="Jeśli brak, zostawić puste" maxLength={1000} />
          </Form.Item>
        </Card>

        {o.offerStatus.postSigningStatus === PostSigningStatus.PROTOCOL_ATTACHED &&
        currentUser.uid != o.protocolData?.protocolSubmitterUid ? (
          <Form.Item shouldUpdate>
            {() => (
              <>
                <Button
                  // Tu wywoła funkcje cloudową taka sama jak w mailu
                  type="primary"
                  onClick={() => handleApprovalClick(false)}
                  disabled={form.getFieldsError().some((field) => field.errors.length > 0)}
                  className="float-right lg:float-left my-4 mx-2 bg-red-700">
                  Odrzuć
                </Button>
                <Button
                  // Tu wywoła funkcje cloudową taka sama jak w mailu
                  type="primary"
                  onClick={() => handleApprovalClick(true)}
                  disabled={form.getFieldsError().some((field) => field.errors.length > 0)}
                  className="float-right lg:float-left my-4">
                  Podpisz
                </Button>
              </>
            )}
          </Form.Item>
        ) : (
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                loading={isAddingPending}
                disabled={form.getFieldsError().some((field) => field.errors.length > 0) || !active}
                className="float-right lg:float-left my-4">
                Zatwierdź
              </Button>
            )}
          </Form.Item>
        )}
      </Form>
    </Space>
  );
};
