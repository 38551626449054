import { Button, List, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { FilePaths } from '../../../features/OfferAdd/Documents/documents.models';
import { firebaseStorage } from '../../../firebase/firebase';
import { Offer } from '../../../models/offers.model';

interface Props {
  isModalOpen: boolean;
  toggleModal: () => void;
  offer: Offer;
  isLandlord: boolean;
}

export const DocumentsOfferModal: React.FC<Props> = ({ isModalOpen, toggleModal, offer, isLandlord }) => {
  const [pdfFiles, setPdfFiles] = useState<string[]>([]);

  const fetchPdfFiles = async () => {
    const folderRef = firebaseStorage.ref(`offers/${offer.id}`);
    const result = await folderRef.listAll();
    const pdfNames = result.items.filter((item) => item.name.endsWith('.pdf')).map((item) => item.name);

    // Ukrywanie faktury
    const filteredItems = pdfNames.filter(
      (item) => !((item === FilePaths.INVOICE_L && !isLandlord) || (item === FilePaths.INVOICE_T && isLandlord))
    );

    setPdfFiles(filteredItems);
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchPdfFiles();
    }
  }, [isModalOpen, offer.id]);

  const handleFileDownload = async (fileName: string) => {
    const fileRef = firebaseStorage.ref(`offers/${offer.id}/${fileName}`);
    const downloadUrl = await fileRef.getDownloadURL();
    window.open(downloadUrl, '_blank'); // Otwiera plik w nowej karcie
  };

  return (
    <Modal
      title="Dokumenty oferty"
      open={isModalOpen}
      onOk={toggleModal}
      onCancel={toggleModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}>
      <List
        bordered
        dataSource={pdfFiles}
        renderItem={(item) => (
          <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
            <>
              <span>{item}</span>
              <div>
                <Button onClick={() => handleFileDownload(item)}>Pokaż</Button>
              </div>
            </>
          </List.Item>
        )}
      />
    </Modal>
  );
};
