import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import 'rsuite/dist/styles/rsuite-default.css';
import './index.css';

import { AuthProvider } from './features/Auth/AuthContext';
import { Routing } from './features/Routing/Routing';
import i18n from './i18n/i18n';
import reportWebVitals from './reportWebVitals';
import { GlobalStyle, theme } from './utils/theme.utils';

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM });
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const AppRoot: React.FC = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <AuthProvider>
          <Routing />
        </AuthProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AppRoot />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
