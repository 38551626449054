import { Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'rsuite';

import { ToastVariant } from '../../../../components/Toast/toast.model';
import { toastService } from '../../../../components/Toast/toast.service';
import { Typography } from '../../../../components/Typography/Typography';
import { firebaseFunctions } from '../../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../../firebase/firebase.models';
import { gtm } from '../../../../reportGTM';
import { useAuth } from '../../AuthContext';

const inputStyle: React.CSSProperties = {
  textAlign: 'center', // Ustawienie kursora po lewej
  fontSize: '1.5rem', // Większa czcionka
  padding: '20px',
  border: '1px solid #ccc', // Obramowanie każdego znaku
  borderRadius: '4px',
  width: 'fit-content',
  blockSize: 'fit-content',
  boxSizing: 'border-box',
  letterSpacing: '0.5rem', // Odpowiednie odstępy między cyframi
  caretColor: 'transparent',
};

export const Step3Form: React.FC<{ title: string }> = ({ title }) => {
  const [status, setStatus] = React.useState(2);
  const [resending, setResending] = React.useState(false); // Stan wysyłania kodu ponownie
  const { t } = useTranslation();
  const { sendVerificationEmail, closeModal } = useAuth();
  const { currentUser } = useAuth();

  // Wysłanie kodu weryfikacyjnego przy załadowaniu komponentu
  // React.useEffect(() => {
  //   sendEmail();
  // }, [currentUser.email]);

  // const sendEmail = async () => {
  //   setStatus(1);
  //   const response = await sendVerificationEmail(currentUser.email);
  //   if (response.status === 'SUCCESS') {
  //     setStatus(2);
  //   } else {
  //     setStatus(3);
  //     toastService.show(response.message, 'Błąd!', { variant: ToastVariant.ERROR });
  //   }
  // };

  // Obsługa ponownego wysłania kodu weryfikacyjnego
  const handleResend = async () => {
    setStatus(1);
    setResending(true);
    const response = await sendVerificationEmail(currentUser.email);
    if (response.status === 'SUCCESS') {
      console.log('wysłano kod weryfikacyjny');
    } else {
      toastService.show(response.message, 'Błąd!', { variant: ToastVariant.ERROR });
    }
    setResending(false);
    setStatus(2);
  };

  const onComplete = async (verificationCode: string) => {
    setStatus(1);
    const verifyEmailVerificationCode = firebaseFunctions.httpsCallable(
      FirebaseCallableFunctions.VERIFY_EMAIL_VERIFICATION_CODE
    );
    try {
      const response = await verifyEmailVerificationCode({
        email: currentUser.email,
        verificationCode: verificationCode,
      });
      const { status, message } = response.data;

      if (status === 'SUCCESS') {
        toastService.show(message, 'Sukces!', { variant: ToastVariant.SUCCESS });
        gtm('form_submit_reg_step_3', { state: 'emailVerified' });
        closeModal();
        window.location.reload();
      } else {
        toastService.show(message, 'Błąd!', { variant: ToastVariant.ERROR });
      }
    } catch (error: any) {
      toastService.show('Wystąpił błąd podczas weryfikacji kodu.', 'Błąd!', { variant: ToastVariant.ERROR });
    } finally {
      setStatus(2);
    }
  };

  return (
    <>
      <Typography.H6 className="pb-8">{title}</Typography.H6>
      <Formik
        initialValues={{ verificationCode: '' }}
        validate={(values) => {
          const errors: { verificationCode?: string } = {};
          if (!/^\d{6}$/.test(values.verificationCode)) {
            errors.verificationCode = 'Kod weryfikacyjny musi składać się z 6 cyfr.';
          }
          return errors;
        }}
        onSubmit={(
          values: { verificationCode: string },
          formikHelpers: FormikHelpers<{ verificationCode: string }>
        ) => {
          onComplete(values.verificationCode); // Przejdź do zakończenia procesu rejestracji
          formikHelpers.setSubmitting(false); // Ustawienie isSubmitting na false po zakończeniu
        }}>
        {({ isSubmitting, isValid, submitForm, handleChange, handleBlur, values }) => (
          <Form fluid size={'lg'} key="step3">
            <div className="flex items-center justify-center">
              <input
                type="text"
                name="verificationCode"
                placeholder=" ___  ___" // Placeholder dla 6-cyfrowego kodu
                style={inputStyle}
                autoComplete="off"
                maxLength={6} // Maksymalna długość
                onChange={handleChange} // Obsługa zmian
                onBlur={handleBlur} // Obsługa opuszczenia pola
                value={values.verificationCode}
              />
            </div>

            <Button
              className="mt-4"
              block
              color={'blue'}
              type="submit"
              loading={isSubmitting || status === 1}
              disabled={!isValid}
              onClick={submitForm}>
              {t('FEATURES.AUTH.SIGN_UP.BUTTON_VERIFY')}
            </Button>

            <Button
              block
              appearance="link"
              onClick={handleResend}
              disabled={resending || status === 1}
              style={{ marginTop: '10px' }}>
              {resending ? t('FEATURES.AUTH.SIGN_UP.BUTTON_RESENDING') : t('FEATURES.AUTH.SIGN_UP.BUTTON_RESEND')}
            </Button>

            {status === 3 && (
              <div
                style={{
                  color: 'red',
                  marginTop: '10px',
                  width: '100%',
                  display: 'flex', // Dodane: Ustawienie Flexbox
                  alignItems: 'center', // Dodane: Wyśrodkowanie w pionie
                  justifyContent: 'center', // Dodane: Wyśrodkowanie w poziomie
                }}>
                {t('FEATURES.AUTH.SIGN_UP.ERROR_SENDING_EMAIL')}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
