import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'rsuite';

import { Typography } from '../../../components/Typography/Typography';
import { ModalConfig } from '../AuthContext';

import { SignUpForm } from './components/SignUpForm';

export const SignUp: React.FC<ModalConfig> = ({ onSuccess }) => {
  const { t } = useTranslation();

  return (
    <div id="form_reg">
      <Modal.Header>
        <Typography.H3>{t('FEATURES.AUTH.SIGN_UP.HEADER')}</Typography.H3>
      </Modal.Header>
      <Modal.Body>
        <SignUpForm onSuccess={onSuccess} />
      </Modal.Body>
    </div>
  );
};
