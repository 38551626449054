import { Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Form } from 'rsuite';

import { FormInput } from '../../../../components/FormInputs/FormInput';
import { Typography } from '../../../../components/Typography/Typography';
import { gtm } from '../../../../reportGTM';
import { SignUpFormValues } from '../../auth.model';
import { useAuth } from '../../AuthContext';
import { CustomCheckbox } from '../../MarketingConsentCheckbox';
import { GoogleSignInButton } from '../../SignIn/component/GoogleSignInButton';
import { AdditionalLink } from '../../styles';
import { useValidation1 } from '../hooks/useValidation.hook';

export const Step1Form: React.FC<{ onNext: (values: SignUpFormValues) => void }> = ({ onNext }) => {
  const validation1 = useValidation1();
  const { signInWithGoogle, showSignInModal } = useAuth();
  const { t } = useTranslation();

  // Initial values for Step 1
  const initialStep1Values: SignUpFormValues = {
    email: '',
    password: '',
    passwordConfirm: '',
    marketingConsent: false,
  };

  return (
    <>
      <Formik
        initialValues={initialStep1Values}
        validationSchema={validation1}
        onSubmit={(values: SignUpFormValues, formikHelpers: FormikHelpers<SignUpFormValues>) => {
          onNext(values); // Przejdź do kroku 2
          formikHelpers.setSubmitting(false); // Ustawienie isSubmitting na false po zakończeniu
          gtm('form_submit_reg_step_1', { state: 'emailAndPasswordSubmitted' });
        }}>
        {({ isSubmitting, isValid, submitForm }) => (
          <Form fluid size={'lg'} key="step1">
            <FormInput name={'email'} label={t('FEATURES.AUTH.SIGN_UP.INPUT_EMAIL_PLACEHOLDER')} />
            <FormInput
              name={'password'}
              label={t('FEATURES.AUTH.SIGN_UP.INPUT_PASSWORD_PLACEHOLDER')}
              type="password"
              autoComplete="new-password"
            />
            <FormInput
              name={'passwordConfirm'}
              label={t('FEATURES.AUTH.SIGN_UP.INPUT_CONFIRM_PASSWORD_PLACEHOLDER')}
              type="password"
              autoComplete="new-password"
            />
            <CustomCheckbox
              name={'marketingConsent'}
              label={t('FEATURES.AUTH.SIGN_UP.INPUT_MARKETING_CONSENT_PLACEHOLDER')}
            />

            <Button block color={'blue'} type="submit" disabled={isSubmitting || !isValid} onClick={submitForm}>
              {t('FEATURES.AUTH.SIGN_UP.BUTTON_SUBMIT')}
            </Button>
            <GoogleSignInButton onClick={signInWithGoogle} signUp={true} />
          </Form>
        )}
      </Formik>
      <AdditionalLink style={{ marginTop: '1.5rem' }}>
        <Trans
          i18nKey="FEATURES.AUTH.SIGN_UP.ALREADY_HAVE_AN_ACCOUNT"
          components={{
            link1: (
              <Typography.Anchor
                onClick={(e) => {
                  e.preventDefault();
                  showSignInModal();
                }}
              />
            ),
          }}
        />
      </AdditionalLink>
    </>
  );
};
