import React, { ReactNode } from 'react';
import { Container, Content, Footer } from 'rsuite';

import { FixedMenu } from './components/FixedMenu';
import { FooterContent } from './components/Footer';
import { InfoBeta } from './components/InfoBeta';
import { SpecialOffer100 } from './components/SpecialOffer100';

interface Props {
  pageHeading?: ReactNode;
}
export const App: React.FC<Props> = ({ children, pageHeading }) => {
  return (
    <Container>
      <InfoBeta />
      <div>
        <FixedMenu />
        {pageHeading}
      </div>
      <SpecialOffer100 />
      <Content>{children}</Content>
      <Footer>
        <FooterContent />
      </Footer>
    </Container>
  );
};
