import { useMemo } from 'react';

import { SelectOption } from '../../../../models/main.model';

export function useBuildingType(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () => [
      {
        label: 'Obiekt biurowy',
        value: 'Obiekt biurowy',
        children: [
          'Biurowiec',
          'Budynek biurowy',
          'Obiekt biurowo-handlowy',
          'Obiekt biurowo-magazynowy',
          'Kamienica biurowa',
        ].map((item) => ({
          label: item,
          value: item,
        })),
      },
      {
        label: 'Obiekt przemysłowo-magazynowy',
        value: 'Obiekt przemysłowo-magazynowy',
        children: ['Zakład produkcyjny', 'Hala magazynowa', 'Garaż/warsztat', 'Warsztat', 'Piwnica', 'Inny'].map(
          (item) => ({
            label: item,
            value: item,
          })
        ),
      },
      {
        label: 'Obiekt handlowy',
        value: 'Obiekt handlowy',
        children: ['Pawilon wolnostojący', 'Centrum handlowe', 'Galeria handlowa', 'Kamienica handlowa'].map(
          (item) => ({
            label: item,
            value: item,
          })
        ),
      },
      {
        label: 'Kamienica',
        value: 'Kamienica',
        children: ['Kamienica biurowa', 'Kamienica handlowa', 'Kamienica mieszkalna'].map((item) => ({
          label: item,
          value: item,
        })),
      },
      {
        label: 'Budynek mieszkalny',
        value: 'Budynek mieszkalny',
        children: ['Blok mieszkalny', 'Apartamentowiec'].map((item) => ({
          label: item,
          value: item,
        })),
      },
      {
        label: 'Dom',
        value: 'Dom',
        children: ['Wolnostojący', 'Bliźniak', 'Segment'].map((item) => ({
          label: item,
          value: item,
        })),
      },
    ],
    []
  );
}
