import { Card, Divider, Space, Typography } from 'antd';
import React from 'react';

import { Offer } from '../../../models/offers.model';

const { Title, Text } = Typography;

interface Props {
  offer: Offer;
}

export const Details: React.FC<Props> = ({ offer }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Space direction="vertical" style={{ width: '70%', padding: '16px' }}>
        <Card bordered={false} style={{ boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
          <Title level={3}>Opłacenie Kaucji</Title>
          <Divider />
          <Text>Właśnie podpisałeś umowę najmu dotyczącą lokalu:</Text>
          <Title level={4} style={{ margin: '16px 0' }}>
            {offer.name}
          </Title>
          <Text>Aby sfinalizować proces wynajmu, prosimy o wpłacenie kaucji w wysokości:</Text>
          <Title level={2} style={{ margin: '16px 0', color: '#52c41a' }}>
            {offer.leaseAgreement.deposit} PLN
          </Title>
          <Text>Środki prosimy przesłać na poniższe konto bankowe:</Text>
          <Title level={5} style={{ margin: '16px 0', color: '#1890ff' }}>
            {process.env.REACT_APP_BANK_ACCOUNT ? process.env.REACT_APP_BANK_ACCOUNT : 'zgłoś błąd'}
          </Title>
          <Text>
            Kaucja zostanie przelana na konto depozytowe operatora najmuje.com i zwolnione Wynajmującemu, po Twoim
            odbiorze lokalu. W przypadku, gdy z przyczyn od Ciebie niezależnych, nie dojdzie do odbioru Lokalu lub Lokal
            nie będzie spełniał warunków opisanych w ofercie, masz prawo nie przejąć Lokalu i odmówić podpisania
            Protokołu Zadawczo-Odbiorczego - w takim przypadku, Kaucja w nominalnej wysokości zostanie zwrócona Tobie a
            umowa rozwiązana za porozumienie Stron
          </Text>
          <Divider></Divider>
          <Text type="secondary">
            Upewnij się, że dokonując przelewu, jako tytuł przelewu wpiszesz: Kaucja za lokal: {offer.name}.
          </Text>
        </Card>
      </Space>
    </div>
  );
};
