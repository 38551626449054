import { Alert } from 'antd';
import * as React from 'react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Animation, Button, Checkbox, CheckboxGroup, Divider, Form, FormControl, FormGroup } from 'rsuite';
import styled from 'styled-components';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { ToastVariant } from '../../../components/Toast/toast.model';
import { toastService } from '../../../components/Toast/toast.service';
import { Typography } from '../../../components/Typography/Typography';
import { firebaseFunctions, firebaseStorage } from '../../../firebase/firebase';
import {
  FirebaseCallableFunctions,
  FunctionSignOfferPayload,
  OfferOption,
  onBookOfferPayload,
} from '../../../firebase/firebase.models';
import { OfferCheck, useUpdateOfferCheck } from '../../../firebase/hooks/updateOfferCheck.hook';
import { Tenant } from '../../../models/legalEntity.model';
import { UserVerified } from '../../../models/main.model';
import { LeaseAgreement, Offer } from '../../../models/offers.model';
import { createPDFs } from '../../OfferAdd/Documents/createPDFs';
import { ConfigType, FilePaths } from '../../OfferAdd/Documents/documents.models';
import { simulateStep } from '../../OfferAdd/OfferAddConfirm';
import { AppRoutes } from '../../Routing/routing.model';

import { InfoAboutDeposit } from './InfoAboutDeposit';
import { InfoAboutNonRentFees } from './InfoAboutNonRentFees';
import { InfoAboutSignature } from './InfoAboutSignature';
import { LegalEntity } from './LegalEntity';
import { ReceiptDeadline } from './ReceiptDeadline';

const StyledPanelWhite = styled(PanelWhite)<{ selected: boolean }>`
  position: relative;
  transition: all 0.3s ease;

  ${({ selected }) =>
    selected &&
    `
      z-index: 10;
      transform: scale(1.05);
      box-shadow: 0 4px 4px rgb(0 22 84 / 15%);
  `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 10px 0;
`;
const SubTitle = styled(Typography.H6)`
  text-align: center;
`;
const Content = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 16px;
`;
const StyledPanelContent = styled(PanelContent)`
  padding-top: 0;
`;

const SignWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 8px;
`;

const SignContent = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 100%;
`;

const SignButton = styled(Button)`
  transition: all 0.3s ease;
  white-space: pre-wrap;
  width: 400px;
  height: 64px;
  margin: 0 auto;

  &:hover {
    transform: scale(1.04);
    box-shadow: 0 6px 6px rgb(0 22 84 / 15%);
    &:active {
      transform: scale(1.03);
    }
  }
`;
const CheckboxWrapper = styled.div`
  margin-bottom: 16px;
`;

interface Props {
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  offerOptionsRef: React.MutableRefObject<HTMLDivElement>;
  tenant: Tenant;
  offerId: string;
  userVerified: UserVerified;
  leaseAgreements: OfferOption[];
  unitReceiptDeadline: number;
  leaseAgreement: LeaseAgreement;
  offer: Offer;
  disabledSigning: boolean;
}
export const OfferOptions: React.FC<Props> = ({
  offerId,
  expanded,
  setExpanded,
  offerOptionsRef,
  tenant,
  userVerified,
  leaseAgreements,
  unitReceiptDeadline,
  leaseAgreement,
  offer,
  disabledSigning,
}) => {
  const { push } = useHistory();
  const [selectedOffer, setSelectedOffer] = useState<OfferOption>();
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [offerUrl, setOfferUrl] = useState('');

  useEffect(() => {
    if (selectedOffer) {
      getFileUrl(offerId, selectedOffer.id)
        .then((url) => {
          setOfferUrl(url);
        })
        .catch((error) => {
          console.error('Nie udało się pobrać URL-a pliku: ', error);
          setOfferUrl('');
        });
    }
  }, [selectedOffer, offerId]);

  const onSign = async () => {
    try {
      useUpdateOfferCheck(offer.id, OfferCheck.CHECKING_NETWORK);
      setLoading(true);
      push(AppRoutes.OFFER_SUBMIT_VERIFY.replace(':id', offerId));

      // Fake steps for network checking and data downloading
      await simulateStep(offerId, OfferCheck.CHECKING_NETWORK, 2000);
      await simulateStep(offerId, OfferCheck.DOWNLOADING_DATA, 2000);

      // Prepare functions
      const signOffer = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_OFFER_SIGN);
      const bookOffer = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_BOOK_OFFER);
      // Prepare payloads
      const signOfferPayload: FunctionSignOfferPayload = { offer, selectedOfferOption: selectedOffer };
      const bookOfferPayload: onBookOfferPayload = { offerId, selectedOfferOption: selectedOffer };

      // Reservation step
      useUpdateOfferCheck(offer.id, OfferCheck.RESERVATION);
      const res = await bookOffer(bookOfferPayload);

      // Preparing documents after reservation
      useUpdateOfferCheck(offer.id, OfferCheck.PREPARING_DOCUMENTS);
      await createPDFs(res.data.offer as Offer, ConfigType.OFFER);

      // Sending offer
      useUpdateOfferCheck(offer.id, OfferCheck.SENDING_OFFER);
      await signOffer(signOfferPayload);

      // Final step
      useUpdateOfferCheck(offer.id, OfferCheck.DONE);
    } catch (error) {
      console.error('Error during signing process:', error);
      toastService.show('Coś poszło nie tak. Spróbuj później.', 'Błąd', { variant: ToastVariant.ERROR });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (values: string[]) => {
    setCheckedValues(values);
  };

  return (
    <div ref={offerOptionsRef}>
      <PanelWhite
        header="Wybór umowy najmu"
        collapsible
        bodyFill
        expanded={expanded}
        onSelect={() => setExpanded((is) => !is)}>
        <PanelContent>
          <Content>
            {leaseAgreements.map((o) => (
              <StyledPanelWhite key={o.id} bodyFill bordered selected={selectedOffer?.id === o.id}>
                <Header>
                  <SubTitle>{o.title}</SubTitle>
                </Header>
                <Divider />
                <StyledPanelContent>
                  <Button
                    appearance={selectedOffer?.id === o.id ? 'primary' : 'ghost'}
                    block
                    disabled={!userVerified.verified}
                    onClick={() => setSelectedOffer(o)}>
                    {selectedOffer?.id === o.id ? 'Wybrane' : 'Wybierz'}
                  </Button>
                </StyledPanelContent>
              </StyledPanelWhite>
            ))}
          </Content>
          {userVerified.verified ? (
            <SignWrapper>
              <Animation.Collapse in={!!selectedOffer}>
                {(props, ref) => (
                  <div {...props} ref={ref} style={{ width: '100%' }}>
                    <SignContent>
                      <ReceiptDeadline
                        days={unitReceiptDeadline}
                        firstPaymentDay={offer.leaseAgreement.firstPaymentDay}
                      />
                      <InfoAboutDeposit deposit={leaseAgreement.deposit} />
                      <InfoAboutNonRentFees leaseAgreement={leaseAgreement} />
                      {selectedOffer?.qualifiedSignature && offer.leaseAgreement.fixedlongMonths > 12 && (
                        <InfoAboutSignature />
                      )}

                      <LegalEntity tenant={tenant} />

                      <CheckboxWrapper>
                        <Form>
                          <FormGroup>
                            <FormControl accepter={CheckboxGroup} onChange={handleCheckboxChange} value={checkedValues}>
                              <Checkbox value="check1">
                                Zapoznałem się z{' '}
                                <a href={offerUrl} target="_blank" rel="noreferrer">
                                  Ofertą
                                </a>{' '}
                                oraz istotnymi zapisami umowy najmu w{' '}
                                <a
                                  href={`https://firebasestorage.googleapis.com/v0/b/fast-letting-local-2021.appspot.com/o/documents%2FFL_Regulamin.pdf?alt=media`}
                                  target="_blank"
                                  rel="noreferrer">
                                  regulaminie
                                </a>
                                , rozumiem i akceptuję wszystkie ich postanowienia.
                              </Checkbox>
                            </FormControl>
                            <FormControl accepter={CheckboxGroup} onChange={handleCheckboxChange} value={checkedValues}>
                              <Checkbox value="check2">
                                Sprawdziłem, że sposób reprezentacji wynajmującego w ofercie jest zgodny z zapisami
                                działu 2 odpowiedniego rejestru KRS.
                              </Checkbox>
                            </FormControl>
                            <FormControl accepter={CheckboxGroup} onChange={handleCheckboxChange} value={checkedValues}>
                              <Checkbox value="check3">
                                Oświadczam, iż akceptuję elektroniczną formę i podpis wynajmującego oraz, że chcę
                                przyjąć Ofertę i w ten sposób zawrzeć umowę najmu.
                              </Checkbox>
                            </FormControl>
                          </FormGroup>
                        </Form>
                      </CheckboxWrapper>

                      <SignButton
                        color="violet"
                        appearance="primary"
                        size={'lg'}
                        loading={loading}
                        disabled={checkedValues.length !== 3 || !disabledSigning}
                        onClick={onSign}>
                        {disabledSigning ? 'Przejdź dalej' : 'Nie możesz podpisać własnej oferty'}
                      </SignButton>
                    </SignContent>
                  </div>
                )}
              </Animation.Collapse>
            </SignWrapper>
          ) : (
            <Alert
              message={userVerified.message}
              showIcon
              description={userVerified.description}
              type="error"
              action={
                <Button
                  type="primary"
                  danger
                  style={{ backgroundColor: 'red', color: 'white' }}
                  onClick={() => userVerified.action()}>
                  {userVerified.routeName}
                </Button>
              }
            />
          )}
        </PanelContent>
      </PanelWhite>
    </div>
  );
};

function getFileUrl(offerId, selectedOfferId) {
  let filePath = `offers/${offerId}/`;
  filePath +=
    selectedOfferId === 1
      ? FilePaths.CERTYFIKAT_NIEOKRESLONY
      : selectedOfferId === 2
      ? FilePaths.CERTYFIKAT_DO_12
      : FilePaths.CERTYFIKAT_POWYZEJ_12;

  return firebaseStorage.ref(filePath).getDownloadURL();
}
