import { useEffect, useState } from 'react';

import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

import { OfferCheck } from './updateOfferCheck.hook';

export function useGetOfferCheck(offerId: string): OfferCheck | null {
  const [offerCheckState, setOfferCheckState] = useState<OfferCheck | null>(null);

  useEffect(() => {
    if (!offerId) return;

    const docRef = firestore.collection(FirestoreCollection.OFFERS).doc(offerId);

    const unsubscribe = docRef.onSnapshot(
      (doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data && data.offerStatus && data.offerStatus.offerCheck) {
            setOfferCheckState(data.offerStatus.offerCheck as OfferCheck);
          }
        }
      },
      (error) => {
        toastService.show(error.message, 'Błąd!', { variant: ToastVariant.ERROR });
      }
    );

    // Cleanup on unmount
    return () => unsubscribe();
  }, [offerId]);

  return offerCheckState;
}
