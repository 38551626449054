import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { UpdateInformation } from '../../models/MojaTablica.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

export async function useUpdateUserInformation(data: UpdateInformation, uid: string): Promise<void> {
  try {
    const docRef = firestore.collection(FirestoreCollection.USERS).doc(uid);
    await docRef.update(data);
    toastService.show('Udało się zapisać dane', 'success');
  } catch (error) {
    toastService.show('Nie udało się zapisać danych', 'Błąd!', { variant: ToastVariant.ERROR });
  }
}
