import { useMemo } from 'react';

import { SelectOption } from '../../../../models/main.model';

export function useOwnershipTypeOptions(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () =>
      [
        'Własność',
        'Współwłasność',
        'Spółdzielcze prawo do lokalu',
        'Umowa najmu',
        'Umowa dzierżawy',
        'Umowa użytkowania',
        'Umowa użyczenia',
        'Umowa leasingu',
      ].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}
