export function additionalNavigation(key: string): void {
  if (key == '1') {
    window.open(
      `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_STORAGE_BUCKET}/o/documents%2FFL_AspektyPrawne.pdf?alt=media`,
      '_blank'
    );
  }
  if (key == '2') {
    window.open(
      `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_STORAGE_BUCKET}/o/documents%2FFL_Regulamin.pdf?alt=media`,
      '_blank'
    );
  }
  if (key == '3') {
    window.open(
      `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_STORAGE_BUCKET}/o/documents%2FFL_Cennik.pdf?alt=media`,
      '_blank'
    );
  } else {
    return;
  }
}
