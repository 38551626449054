import { useMemo } from 'react';

import { SelectOption } from '../../../../models/main.model';

export function useWarehouseRoomTypeOptions(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () =>
      ['warsztat', 'open space', 'hala magazynowa', 'hala produkcyjna', 'chłodnia'].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}
