import { message } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Grid, Icon, Row } from 'rsuite';
import styled from 'styled-components';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { ToastVariant } from '../../../components/Toast/toast.model';
import { toastService } from '../../../components/Toast/toast.service';
import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';
import { copyTextToClipboard } from '../../../utils/navigator.utils';
import { useAuth } from '../../Auth/AuthContext';

const StyledButton = styled(Button)`
  & > * {
    margin-right: ${({ theme }) => theme.gap(2)};
  }
`;

interface Props {
  id: string;
}
export const OfferMainButtons: React.FC<Props> = ({ id }) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [color, setColor] = useState('orange');
  const [isLoading, setIsLoading] = useState(false);

  const onShare = () => {
    copyTextToClipboard(
      window.location.href,
      () => {
        message.success('Link został skopiowany.');
      },
      () => {
        message.error('Nie udało się skopiować linku.');
      }
    );
  };
  const onAddToFavorites = useCallback(() => {
    if (isLoading) return;
    setIsLoading(true);

    const addToFavorites = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ADD_TO_FAVORITES);
    addToFavorites({ offerId: id, uid: currentUser.uid })
      .then(() => {
        toastService.show(t('COMMON.OFFER.ACTIONS.ADD_TO_FAVORITES'), 'Dodano do ulubionych');
        setIsLoading(false);
        setColor('grey');
      })
      .catch(() => {
        toastService.show('Cos poszło nie tak. Spróbuj później', 'Błąd', { variant: ToastVariant.ERROR });
        setIsLoading(false);
      });
  }, [isLoading, currentUser, id]);

  return (
    <PanelWhite bodyFill>
      <PanelContent>
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={12}>
              <StyledButton color="yellow" size={'lg'} block onClick={onShare}>
                <Icon icon={'share-square-o'} size={'lg'} />
                Udostępnij
              </StyledButton>
            </Col>
            <Col xs={12}>
              <StyledButton onClick={onAddToFavorites} loading={isLoading} color={color} size={'lg'} block>
                <Icon icon={'heart-o'} size={'lg'} />
                Do ulubionych
              </StyledButton>
            </Col>
          </Row>
        </Grid>
      </PanelContent>
    </PanelWhite>
  );
};
