import { ToastVariant } from '../../components/Toast/toast.model';
import { toastService } from '../../components/Toast/toast.service';
import { firebaseFunctions, firestore } from '../firebase';
import { FirebaseCallableFunctions, FirestoreCollection, User } from '../firebase.models';
import { Company } from '../../features/MojaTablica/Account/Identity/LinkCompany/company.models';

export async function useUpdateCompany(data: Company, uid: string): Promise<void> {
  try {
    const docRef = firestore.collection(FirestoreCollection.USERS).doc(uid);
    const docData = await docRef.get().then((doc) => doc.data());
    const { uid: userId, firstName, lastName } = docData;

    const userData: User = {
      uid: userId,
      firstName,
      lastName,
    };

    docRef
      .update({ company: data })
      .then(() => {
        // wyślij maila do admina, żeby potwierdził firmę
        const onAddCompany = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_ADD_COMPANY);
        onAddCompany({
          user: userData,
          company: data,
        })
          .then(() => {
            toastService.show('Poinformujemy Cię, gdy zakończy się weryfikacja', 'Udało się');
          })
          .catch(() => {
            toastService.show('Cos poszło nie tak. Spróbuj jeszcze raz', 'Błąd', { variant: ToastVariant.ERROR });
          });
      })
      .catch((e) => {
        toastService.show(e, 'Błąd!', { variant: ToastVariant.ERROR });
      });
  } catch (error) {
    toastService.show('Nie udało się zapisać danych', 'Błąd!', { variant: ToastVariant.ERROR });
  }
}
