import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { ForgotPasswordFormValues } from '../../auth.model';

export const useValidation = (): yup.SchemaOf<ForgotPasswordFormValues> => {
  const { t } = useTranslation();
  return yup.object().shape({
    email: yup
      .string()
      .email(t('FEATURES.AUTH.FORGOT_PASSWORD.INPUT_EMAIL_VALIDATION_INVALID'))
      .required(t('FEATURES.AUTH.FORGOT_PASSWORD.INPUT_EMAIL_VALIDATION_REQUIRED')),
  });
};
