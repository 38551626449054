import { useMemo } from 'react';

import { SelectOption } from '../../../../models/main.model';

export function useOfficeEquipment(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () =>
      [
        'Biurka',
        'Krzesła',
        'Rolety/żaluzje',
        'Wykładzina',
        'Sprzęt multimedialny',
        'Szafy',
        'Kopiarka',
        'Niszczarka',
      ].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}
