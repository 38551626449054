import { InboxOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, MenuProps, Popover, Space, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router';

import { ToastVariant } from '../../../../../components/Toast/toast.model';
import { toastService } from '../../../../../components/Toast/toast.service';
import { firebaseFunctions, firebaseStorage, firestore } from '../../../../../firebase/firebase';
import { FirebaseCallableFunctions, FirestoreCollection } from '../../../../../firebase/firebase.models';
import { Verification } from '../../../../../models/offers.model';
import { gtm } from '../../../../../reportGTM';
import { App } from '../../../../App/App';
import { useOnAddOfferPush } from '../../../../App/hooks/onAddOfferPush.hook';
import { useAuth } from '../../../../Auth/AuthContext';
import { AppRoutes } from '../../../../Routing/routing.model';
import { MenuWrapper } from '../../../components/MenuWrapper';
import { additionalNavigation } from '../../../Menu/AdditionalNavigation';
import { MenuItems } from '../../../Menu/Menu';

const USER_PATH = `/users/`;
const FILE_NAME = 'Regulamin';
const { Dragger } = Upload;

interface UploadRequestOption {
  onError?: any;
  onSuccess?: any;
  file: any;
}

const handleDownloadRegulamin = async () => {
  const fileRef = firebaseStorage.ref('documents/FL_Regulamin.pdf');

  try {
    const url = await fileRef.getDownloadURL();
    const response = await fetch(url);
    const blob = await response.blob();

    // Tworzenie linku do pobrania pliku
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'Regulamin najmuje.com.pdf'; // Nazwa pliku po pobraniu
    link.click();
  } catch (error) {
    console.error('Error downloading file: ', error);
  }
};
export const ConfirmIdentity: React.FC = () => {
  const { push } = useHistory();
  const { currentUser, sendVerificationEmail } = useAuth();
  const [form] = Form.useForm();
  const [identity, setIdentity] = useState<Verification>();
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [bankAccount, setBankAccount] = useState<string>('');
  const [showEmailAlert, setShowEmailAlert] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState(false);
  const [signedTerms, setSignedTerms] = useState('');
  const onAddOfferPush = useOnAddOfferPush();

  const { t } = useTranslation();

  const onClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  useEffect(() => {
    fetchData();
    if (!currentUser.emailVerified) {
      setShowEmailAlert(true);
    }
  }, [currentUser]);

  const fetchData = async () => {
    try {
      await Promise.all([fetchFile(), fetchUserData()]);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchFile = async () => {
    try {
      const downloadUrl = await firebaseStorage.ref(`${USER_PATH}${currentUser.uid}/${FILE_NAME}`).getDownloadURL();
      setFileList([{ uid: '0', name: FILE_NAME, status: 'done', url: downloadUrl }]);
      setSignedTerms(downloadUrl);
    } catch (error) {
      console.error(error);
      setFileList([]);
    }
  };

  const fetchUserData = async () => {
    const userDoc = firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid);
    const snapshot = await userDoc.get();
    const data = snapshot.data();

    if (data) {
      setIdentity(data.verification?.identity || Verification.INITIAL);
      const bankAccountFromDb = data.bankAccount || '';
      setBankAccount(bankAccountFromDb);
      form.setFieldsValue({ bankAccount: bankAccountFromDb });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const unsubscribe = firestore
      .collection(FirestoreCollection.USERS)
      .doc(currentUser.uid)
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.data();
          if (data && data.verification) {
            setIdentity(data.verification.identity || Verification.INITIAL);
          }
        },
        (e) => {
          console.log('Error', e);
        }
      );

    return () => unsubscribe();
  }, [currentUser.uid]);

  const uploadFile = async (file: File, path: string): Promise<string> => {
    try {
      const imageRef = firebaseStorage.ref(path);
      const snapshot = await imageRef.put(file);
      return await snapshot.ref.getDownloadURL();
    } catch (error) {
      throw new Error('Nie udało się przesłać pliku');
    }
  };

  const handleInvalidBankAccount = (options: UploadRequestOption) => {
    const { onError } = options;
    const errorMessage = 'Proszę wpisać numer konta bankowego przed wysłaniem pliku.';

    onError(new Error(errorMessage));
    setIdentity(Verification.INITIAL);

    toastService.show(errorMessage, 'Błąd', {
      variant: ToastVariant.ERROR,
    });
  };

  const notifyAdmin = async (data: any) => {
    try {
      const onUploadSignature = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_UPLOAD_SIGNATURE);
      await onUploadSignature(data);
    } catch (error) {
      throw new Error('Nie udało się powiadomić admina');
    }
  };

  const customUpload = async (options: UploadRequestOption) => {
    if (!bankAccount) {
      handleInvalidBankAccount(options);
      return;
    }

    const { file, onError, onSuccess } = options;
    setIsUploading(true);

    try {
      const downloadUrl = await uploadFile(file, `/users/${currentUser.uid}/Regulamin`);
      setSignedTerms(downloadUrl);
      onSuccess({ url: downloadUrl }, file);

      const snapshot = await firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid).get();
      const userData = snapshot.data();

      if (!userData) {
        throw new Error('Brak danych użytkownika');
      }

      await notifyAdmin({
        userUid: currentUser.uid,
        fileUrl: downloadUrl,
        firstName: userData.firstName,
        lastName: userData.lastName,
      });

      toastService.show('Poinformujemy Cię, gdy zakończy się weryfikacja', 'Udało się');
      setUploaded(true);
      gtm('form_submit_reg_step_5', { state: 'RegulationsSignedSubmitted' });
    } catch (error) {
      onError(error);
      toastService.show('Cos poszło nie tak. Spróbuj jeszcze raz', 'Błąd', { variant: ToastVariant.ERROR });
    } finally {
      setIsUploading(false);
    }
    try {
      await firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid).update({
        'verification.identity': Verification.CHECKING,
      });
      setIdentity(Verification.CHECKING);
    } catch (error) {
      console.error('Error updating user verification status: ', error);
    }
  };

  const handleSendClick = async () => {
    try {
      const cleanBankAccount = form.getFieldValue('bankAccount').replace(/\s/g, '').replace(/[\s_]/g, '');

      setBankAccount(cleanBankAccount);
      await firestore
        .collection(FirestoreCollection.USERS)
        .doc(currentUser.uid)
        .update({ bankAccount: cleanBankAccount })
        .then(() => {
          gtm('form_submit_reg_step_4', { state: 'bankAccountSubmitted' });
          toastService.show('Udało się zapisać numer konta bankowego', 'Sukces');
        })
        .catch(() => {
          toastService.show('Błąd zapisu danych', 'Błąd!', {
            variant: ToastVariant.ERROR,
            duration: 0,
          });
        });
    } catch (error) {
      toastService.show('Nie udało się zapisać danych', 'Błąd!', { variant: ToastVariant.ERROR, duration: 0 });
    }
  };

  return (
    <App>
      <div className="flex">
        <MenuWrapper>
          <MenuItems
            onClick={onClick}
            defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY]}
            defaultOpenKeys={['sub2', 'sub3']}
          />
        </MenuWrapper>
        <Space direction="vertical" style={{ margin: 50 }}>
          {isLoading ? (
            <Spin style={{ margin: '50px' }} />
          ) : showEmailAlert ? (
            <Alert
              style={{ width: '400px' }}
              message="Potwierdź adres email"
              description="Obecnie nie masz dostępu do tej funkcjonalności."
              type="error"
              showIcon
              action={
                <Button
                  type="primary"
                  danger
                  style={{ backgroundColor: 'red', color: 'white' }}
                  onClick={() => {
                    sendVerificationEmail()
                      .then((response) => {
                        if (response.status === 'SUCCESS') {
                          toastService.show(t('FEATURES.AUTH.SIGN_UP.VERIFICATION_EMAIL_SENT'), 'Potwierdź email', {
                            variant: ToastVariant.SUCCESS,
                          });
                        } else {
                          toastService.show(t(response.message), 'Błąd!', { variant: ToastVariant.ERROR });
                        }
                      })
                      .catch((e) => {
                        toastService.show(t(e.message || 'Wystąpił nieoczekiwany błąd.'), 'Błąd!', {
                          variant: ToastVariant.ERROR,
                        });
                      });
                  }}>
                  Potwierdź
                </Button>
              }
            />
          ) : (
            <>
              {identity === Verification.INITIAL ? (
                <>
                  <Alert
                    message="Potwierdź tożsamość"
                    description="Potwierdź tożsamość przez wysłanie podpisanego regulaminu przez Podpis Zaufany"
                    type="info"
                    showIcon
                  />
                  <Alert
                    message="Dlaczego potrzebujemy Twojego numeru konta?"
                    description="Twój numer konta bankowego jest dla nas istotny, abyśmy mogli w przyszłości wypełnić wszelkie umowy i dokumenty związane z naszymi usługami. Twoje dane są przechowywane w bezpieczny sposób i są wykorzystywane jedynie w celach związanych z zawarciem i realizacją umów. Dzięki temu proces zawierania transakcji będzie dla Ciebie znacznie szybszy i wygodniejszy."
                    type="info"
                    showIcon
                    className="mb-4"
                  />
                </>
              ) : identity === Verification.ACCEPTED ? (
                <Alert
                  message="Twoja tożsamość jest potwierdzone"
                  description="Cieszymy się, że udało Ci się przejść przez proces potwierdzania tożsamości."
                  type="success"
                  showIcon
                />
              ) : isUploading ? (
                <Alert
                  message="Trwa wysyłanie"
                  description="Plik z regulaminem i twoim Podpisem Zaufanym jest w trakcie wysyłania do nas."
                  type="warning"
                  showIcon
                />
              ) : identity === Verification.CHECKING ? (
                <Alert
                  message="Trwa weryfikacja podpisu"
                  description={
                    <>
                      <p>Poinformujemy Cię gdy weryfikacja podpisu się zakończy. Szacowany czas: 24h</p>
                      <p>
                        Oczekując na sprawdzenie podanych przez Ciebie danych, zachęcamy do{' '}
                        <a
                          className="underline"
                          onClick={(e) => {
                            e.preventDefault();
                            push(AppRoutes.OFFERS);
                          }}>
                          sprawdzenia ofert
                        </a>{' '}
                        oraz do{' '}
                        <a
                          className="underline"
                          onClick={(e) => {
                            e.preventDefault();
                            onAddOfferPush();
                          }}>
                          dodania własnej
                        </a>
                        .
                      </p>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              ) : identity === Verification.REJECTED ? (
                <Alert
                  message="Nie udało się zweryfikować podpisu. Spróbuj jeszcze raz"
                  description="Podpis Profilem Zaufanym był nieprawidłowy lub dane na nim nie zgadzały się z danymi konta."
                  type="error"
                  showIcon
                />
              ) : null}

              <Form form={form} onFinish={handleSendClick}>
                <Popover
                  trigger="focus"
                  placement="top"
                  content={
                    <div className="font-semibold w-96">
                      Pamietaj, że zmieniając już wcześniej wpisany numer konta, dotychczsasowy numer konta nie zmieni
                      sie w Twoich aktywnych ofertach.
                    </div>
                  }>
                  <Form.Item
                    label="Numer konta bankowego"
                    name="bankAccount"
                    rules={[
                      {
                        validator: (_, value) =>
                          (value && value.length === 27) || value.length === 26
                            ? Promise.resolve()
                            : Promise.reject(new Error('Pole musi mieć dokładnie 26 znaków')),
                      },
                    ]}
                    normalize={(value) => value.replace(/\s/g, '')}>
                    <InputMask mask="99 9999 9999 9999 9999 9999 9999" alwaysShowMask>
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Popover>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Zapisz
                  </Button>
                </Form.Item>
                {!uploaded && identity !== Verification.ACCEPTED && identity !== Verification.CHECKING && (
                  <Dragger
                    name="file"
                    multiple={false}
                    fileList={fileList}
                    customRequest={customUpload}
                    disabled={!bankAccount}
                    onDrop={(e) => {
                      console.log('Dropped files', e.dataTransfer.files);
                    }}
                    showUploadList={false}
                    style={{ marginTop: '10px', marginBottom: '16px' }}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined rev={undefined} />
                    </p>
                    <p className="ant-upload-text">Kliknij lub przeciągnij, żeby załączyć podpisany regulamin</p>
                    <p className="ant-upload-hint">
                      <a
                        onClick={(e) => {
                          handleDownloadRegulamin();
                          e.stopPropagation();
                        }}
                        className="underline"
                        rel="noreferrer">
                        Pobierz regulamin,
                      </a>{' '}
                      który należy podpisać{' '}
                      <a
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="ant-upload-hint underline"
                        href="https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER"
                        target="_blank"
                        rel="noreferrer">
                        Podpisem Zaufanym
                      </a>
                    </p>
                  </Dragger>
                )}
                {isUploading ? <Spin style={{ marginTop: '20px' }} /> : null}
              </Form>
            </>
          )}
          {signedTerms ? (
            <a href={signedTerms} target="_blank" rel="noreferrer" className="text-blue-500">
              Zobacz podpisany regulamin
            </a>
          ) : null}
        </Space>
      </div>
    </App>
  );
};
