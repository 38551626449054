import type { MenuProps } from 'antd';
import { Alert, Button, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { firestore } from '../../../../../firebase/firebase';
import { FirestoreCollection } from '../../../../../firebase/firebase.models';
import { useGetCompany } from '../../../../../firebase/hooks/getCompanyByUid.hook';
import { useUpdateCompany } from '../../../../../firebase/hooks/updateCompany.hook';
import { Verification } from '../../../../../models/offers.model';
import { gtm } from '../../../../../reportGTM';
import { App } from '../../../../App/App';
import { useOnAddOfferPush } from '../../../../App/hooks/onAddOfferPush.hook';
import { useAuth } from '../../../../Auth/AuthContext';
import { AppRoutes } from '../../../../Routing/routing.model';
import { MenuWrapper } from '../../../components/MenuWrapper';
import { additionalNavigation } from '../../../Menu/AdditionalNavigation';
import { MenuItems } from '../../../Menu/Menu';

import { Company } from './company.models';
import { FormToLinkCompany } from './FormToLinkCompany';

export const LinkCompany: React.FC = () => {
  const { push } = useHistory();
  const { currentUser } = useAuth();

  const [company, loading] = useGetCompany(currentUser.uid);
  const [disabled, setDisabled] = useState(true);
  const [showLinkCompany, setShowLinkCompany] = useState(false);
  const [isCompanyConfirmed, setIsCompanyConfirmed] = useState<Verification>();
  const onAddOfferPush = useOnAddOfferPush();

  const onClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  const onFinish = (values: Company) => {
    useUpdateCompany(values, currentUser.uid);
    firestore
      .collection(FirestoreCollection.USERS)
      .doc(currentUser.uid)
      .update({ 'verification.company': Verification.CHECKING });
    setIsCompanyConfirmed(Verification.CHECKING);
    setDisabled(true);
    gtm('form_submit_reg_step_6', { state: 'companyInfoSubmitted' });
  };

  // sprawdź czy użytkownik ma potwierdzoną tożsamość i wtedy i tylko wtedy pozwól mu podłączyć firmę
  useEffect(() => {
    firestore
      .collection(FirestoreCollection.USERS)
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        if (doc.data()?.verification?.identity === Verification.ACCEPTED) {
          setShowLinkCompany(true);
        }
      });

    const userDoc = firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid).get();
    userDoc.then((doc) => {
      setIsCompanyConfirmed((doc.data()?.verification?.company as Verification) ?? null);
    });
  }, [company, loading]);

  return (
    <App>
      <MenuWrapper>
        <MenuItems
          onClick={onClick}
          defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_LINK_COMPANY]}
          defaultOpenKeys={['sub2', 'sub3']}
        />
      </MenuWrapper>
      <Space direction="vertical" style={{ margin: 50 }}>
        {isCompanyConfirmed === Verification.ACCEPTED ? (
          <Alert
            message="Twoje konto jest potwierdzone"
            description="Cieszymy się, że udało Ci się przejść przez proces potwierdzania firmy."
            type="success"
            showIcon
            style={{ marginBottom: '24px' }}
          />
        ) : isCompanyConfirmed === Verification.REJECTED ? (
          <Alert
            message="Nie udało się potwierdzić firmy. Wypełnij formularz jeszcze raz."
            description="Twoje dane użytkownika nie zgadzały się z danymi firmy dostępnymi w systemie KRS."
            type="error"
            showIcon
            style={{ marginBottom: '24px' }}
          />
        ) : isCompanyConfirmed === Verification.CHECKING ? (
          <Alert
            message="Trwa weryfikacja firmy"
            description={
              <>
                <p>Poinformujemy Cię gdy weryfikacja firmy się zakończy. Szacowany czas: 24h</p>
                <p>
                  Oczekując na sprawdzenie podanych przez Ciebie danych, zachęcamy do{' '}
                  <a
                    className="underline"
                    onClick={(e) => {
                      e.preventDefault();
                      push(AppRoutes.OFFERS);
                    }}>
                    sprawdzenia ofert
                  </a>{' '}
                  oraz do{' '}
                  <a
                    className="underline"
                    onClick={(e) => {
                      e.preventDefault();
                      onAddOfferPush();
                    }}>
                    dodania własnej
                  </a>
                  .
                </p>
              </>
            }
            type="warning"
            showIcon
            style={{ marginBottom: '24px' }}
          />
        ) : null}
        {loading ? (
          <Spin style={{ margin: '50px' }} />
        ) : showLinkCompany ? (
          <FormToLinkCompany initial={company} onFinish={onFinish} disabled={disabled} setDisabled={setDisabled} />
        ) : (
          <Alert
            message="Aby podłączyć firmę, należy potwierdzić swoją tożsamość"
            showIcon
            description="Najpierw musisz potwierdzić swoją tożsamość, żeby móc połączyć konto z firmą"
            type="error"
            style={{ width: 600 }}
            action={
              <Button
                type="primary"
                danger
                style={{ backgroundColor: 'red', color: 'white' }}
                onClick={() => push(AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY)}>
                Potwierdź
              </Button>
            }
          />
        )}
      </Space>
    </App>
  );
};
