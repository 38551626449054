import firebase from 'firebase';
import { useCallback, useState } from 'react';

import { Offer, OfferStatusState } from '../../models/offers.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

import { useProcessDates } from './useProcessDates.hook';

export function useGetBookedOffersByUid(): [Offer[], (uid: string) => void, boolean, firebase.FirebaseError] {
  const [error, setError] = useState<firebase.FirebaseError>();
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState<Offer[]>([]);

  const getBookedOffersByUid = useCallback(async (uid: string) => {
    setLoading(true);
    setError(null);

    try {
      // Pierwsze zapytanie: where('bookedBy.tenant.uid', '==', uid)
      const bookedByQuery = firestore
        .collection(FirestoreCollection.OFFERS)
        .where('offerStatus.state', '==', OfferStatusState.BOOKED)
        .where('offerStatus.tenant.uid', '==', uid);

      // Drugie zapytanie: where('uid', '==', uid)
      const ownOffersQuery = firestore
        .collection(FirestoreCollection.OFFERS)
        .where('offerStatus.state', '==', OfferStatusState.BOOKED)
        .where('uid', '==', uid);

      // Wykonaj oba zapytania równolegle
      const [bookedBySnapshot, ownOffersSnapshot] = await Promise.all([bookedByQuery.get(), ownOffersQuery.get()]);

      // Przetwórz wyniki obu zapytań
      const bookedByOffers = bookedBySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          ...useProcessDates({
            availability: data.availability,
            availabilityTo: data.availabilityTo,
            offerValidTo: data.offerValidTo,
          }),
        } as Offer;
      });

      const ownOffers = ownOffersSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          ...useProcessDates({
            availability: data.availability,
            availabilityTo: data.availabilityTo,
            offerValidTo: data.offerValidTo,
          }),
        } as Offer;
      });

      // Połącz wyniki, unikając duplikatów
      const combinedOffers = [...bookedByOffers];
      ownOffers.forEach((offer) => {
        if (!combinedOffers.some((o) => o.id === offer.id)) {
          combinedOffers.push(offer);
        }
      });

      console.log('Combined Booked Offers:', combinedOffers);
      setOffers(combinedOffers);
    } catch (error: any | firebase.FirebaseError) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return [offers, getBookedOffersByUid, loading, error];
}
