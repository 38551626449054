import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { PanelContent, PanelWhite, WidthWrapper } from '../../components/Layout/styles';
import { SlickCarousel } from '../../components/SlickCarousel/SlickCarousel';
import { SlideImg, SlideWrapper } from '../../components/SlickCarousel/styles';
import { useGetTenant } from '../../firebase/hooks/getTenant.hook';
import { UserVerified } from '../../models/main.model';
import { Offer, OfferStatusState } from '../../models/offers.model';
import { App } from '../App/App';
import { useAuth } from '../Auth/AuthContext';
import { FormFooterWrapper } from '../OfferAdd/components/OfferForm';

import { ContinueEditing } from './components/ContinueEditing';
import { CounterOffer } from './components/CounterOffer';
import { Description } from './components/Description';
import { DetailsAndFunctions } from './components/DetailsAndFunctions';
import { GoFurther } from './components/GoFurther';
import { Heading } from './components/Heading';
import { Message } from './components/Message';
import { OfferLocation } from './components/OfferLocation';
import { OfferMainButtons } from './components/OfferMainButtons';
import { OfferOptions } from './components/OfferOptions';
import { OfferValidCountingDown } from './components/OfferValidCountingDown';
import { ProgessSteps } from './components/ProgressSteps';
import { SignButton } from './components/SignButton';
import { SignInToShow } from './components/SignInToShow';
import { isUserVerified } from './hooks/isUserVerified.hook';
import { useGetLeasements } from './hooks/useGetLeasements.hook';

const Wrapper = styled.div`
  background-color: #f3f7fd;
`;

const MainGrid = styled.section<{ preview: boolean }>`
  display: grid;
  grid-template-columns: ${({ preview }) => (preview ? `1fr` : `1fr 416px`)};
  grid-gap: ${({ theme }) => theme.gap(4)};
  padding-bottom: ${({ theme }) => theme.gap(10)};
  align-items: self-start;
`;

const GridColumn = styled.section`
  display: flex;
  flex-flow: column;
  & > * {
    margin-bottom: 32px;
  }
`;

interface Props {
  offer: Offer;
  images: string[];

  preview?: boolean;
}

export const OfferView: React.FC<Props> = ({ offer, images, preview }) => {
  const [offerExpanded, setOfferExpanded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const offerOptionsRef = useRef<HTMLDivElement>();
  const { currentUser, isAuth } = useAuth();
  const [tenant] = useGetTenant(currentUser?.uid);
  const userVerified: UserVerified = isUserVerified(currentUser);
  const leaseAgreements = useGetLeasements(offer.id, offer.leaseAgreement);

  const onGoToOfferOptionsClick = useCallback(() => {
    setOfferExpanded(true);
    setTimeout(() => offerOptionsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 500);
  }, []);

  const _images = useMemo(() => {
    if (!images?.length) return null;
    if (images.length > 1) return images;
    if (images.length === 1) return [images[0], images[0], images[0]];
  }, [images]);

  // Sprawdzenie czy użytkownik jest zalogowany

  console.log(currentUser);

  return (
    <App>
      <Wrapper>
        <SlickCarousel>
          {_images?.map((i) => (
            <SlideWrapper key={i}>
              <SlideImg src={i} />
            </SlideWrapper>
          ))}
        </SlickCarousel>

        <WidthWrapper>
          <Heading offer={offer} offerImage={images?.[0]} />

          <MainGrid preview={preview}>
            <GridColumn>
              <DetailsAndFunctions offer={offer} defaultExpanded={preview} isUserLoggedIn={isAuth} />
              {!preview &&
                offer.offerStatus.state === OfferStatusState.ACTIVE &&
                (isAuth ? (
                  <OfferOptions
                    expanded={offerExpanded}
                    setExpanded={setOfferExpanded}
                    offerOptionsRef={offerOptionsRef}
                    tenant={tenant}
                    offerId={offer.id}
                    userVerified={userVerified}
                    leaseAgreements={leaseAgreements}
                    unitReceiptDeadline={offer.unitReceiptDeadline}
                    leaseAgreement={offer.leaseAgreement}
                    offer={offer}
                    disabledSigning={currentUser.uid !== offer.uid}
                  />
                ) : (
                  <PanelWhite bodyFill>
                    <PanelContent>
                      <SignInToShow text="Zaloguj się, aby zobaczyć dostępne umowy najmu" rows={5} />
                    </PanelContent>
                  </PanelWhite>
                ))}
            </GridColumn>
            {!preview && (
              <GridColumn>
                {isAuth ? (
                  <OfferValidCountingDown offer={offer}></OfferValidCountingDown>
                ) : (
                  <PanelWhite bodyFill>
                    <PanelContent>
                      <SignInToShow text="Zaloguj się, aby zobaczyć kiedy oferta wygaśnie" />
                    </PanelContent>
                  </PanelWhite>
                )}
                {offer.description && <Description description={offer.description} />}
                {offer.geoMarker && <OfferLocation offer={offer} />}
                {offer.offerStatus.state === OfferStatusState.ACTIVE && (
                  <GoFurther onGoToOfferSection={onGoToOfferOptionsClick} />
                )}
                {!isAuth && offer.counteroffer.selected && currentUser?.uid !== offer.uid && (
                  <PanelWhite bodyFill>
                    <PanelContent>
                      <SignInToShow text="Zaloguj się, aby zobaczyć szczegóły kontroferty" />
                    </PanelContent>
                  </PanelWhite>
                )}
                {isAuth && offer.counteroffer.selected && currentUser.uid !== offer.uid && (
                  <CounterOffer
                    tenant={currentUser.email}
                    offerId={offer.id}
                    price={offer.price}
                    landlord={offer.landlord}
                  />
                )}
                {isAuth ? (
                  <>
                    <OfferMainButtons id={offer.id} />
                    <Message currentUser={currentUser} offer={offer} disabled={currentUser.uid === offer.uid} />
                  </>
                ) : (
                  <PanelWhite bodyFill>
                    <PanelContent>
                      <SignInToShow text="Zaloguj się, aby móc zapytać o ofertę" rows={4} />
                    </PanelContent>
                  </PanelWhite>
                )}
              </GridColumn>
            )}
            {offer.offerStatus.state === OfferStatusState.UNSIGNED && isAuth && (
              <>
                <ProgessSteps offer={offer} currentUser={currentUser} loading={loading} setLoading={setLoading} />
                <FormFooterWrapper>
                  <ContinueEditing offerId={offer.id} />
                  <SignButton offer={offer} currentUser={currentUser} loading={loading} setLoading={setLoading} />
                </FormFooterWrapper>
              </>
            )}
          </MainGrid>
        </WidthWrapper>
      </Wrapper>
    </App>
  );
};
