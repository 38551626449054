import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { Typography } from '../../../components/Typography/Typography';
import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions, FunctionAskQuestion } from '../../../firebase/firebase.models';
import { Offer } from '../../../models/offers.model';

const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: #4caf50;
  padding: 20px;
`;

const Title = styled(Typography.H4)`
  color: ${({ theme }) => theme.textDarkPrimary};
`;

const SubTitle = styled(Typography.H6)`
  color: ${({ theme }) => theme.textDarkPrimary};
`;

const StyledIcon = styled.div`
  color: ${({ theme }) => theme.textDarkPrimary};
  margin-right: 16px;
  font-size: 32px;
`;

interface Props {
  currentUser: any;
  offer: Offer;
  disabled: boolean;
}

interface FormValue {
  title?: string;
  description?: string;
}

export const Message: React.FC<Props> = ({ currentUser, offer, disabled }) => {
  const [formValue, setFormValue] = useState<FormValue>({});
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (!formValue.title || !formValue.description) {
      message.error('Wypełnij wszystkie pola formularza.');
      return;
    }
    setLoading(true);

    const onAskQuestion = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_ASK_QUESTION);
    const functionOnAskQuestionPayload: FunctionAskQuestion = {
      offerName: offer.name,
      title: formValue.title,
      description: formValue.description,
      tenant: currentUser.email,
      landlord: offer.landlord,
    };
    onAskQuestion(functionOnAskQuestionPayload)
      .then(() => {
        console.log('Message sent');
        message.success('Wiadomość została wysłana');
        setFormValue({});
        form.resetFields();
      })
      .catch((e) => {
        console.log('Message sent error', e);
        message.error('Błąd: Wiadomość nie została wysłana');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PanelWhite bodyFill>
      <Header>
        <StyledIcon>
          <i className="fa fa-comments-o" />
        </StyledIcon>
        <div>
          <Title>Masz pytanie?</Title>
          <SubTitle>Zadaj je opiekunom Oferty</SubTitle>
        </div>
      </Header>
      <PanelContent>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={formValue}
          onValuesChange={(_changedValues, allValues) => setFormValue(allValues)}>
          <Form.Item name="title" label="Tytuł pytania" rules={[{ required: true, message: 'Tytuł jest wymagany.' }]}>
            <Input placeholder="Tytuł pytania" />
          </Form.Item>
          <Form.Item name="description" label="Opis" rules={[{ required: true, message: 'Opis jest wymagany.' }]}>
            <Input.TextArea rows={5} placeholder="Opis" style={{ minHeight: 400 }} />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={disabled}
              loading={loading}
              icon={<SendOutlined />}
              className="w-full mt-4">
              {disabled ? 'Nie możesz wysłać' : 'Wyślij'}
            </Button>
          </Form.Item>
        </Form>
      </PanelContent>
    </PanelWhite>
  );
};
