import { Alert, Space } from 'antd';
import * as React from 'react';

import { LeaseAgreement } from '../../../models/offers.model';

interface Props {
  leaseAgreement: LeaseAgreement;
}

export const InfoAboutNonRentFees: React.FC<Props> = ({ leaseAgreement }) => {
  return (
    <Space direction="vertical" size={12} style={{ marginBottom: 16 }}>
      <Alert
        type="info"
        message={`Opłaty poza czynszem: ${leaseAgreement.nonRentFees}${
          leaseAgreement.nonRentFees === 'Ryczałtowe' ? ` ${leaseAgreement.lumpSum} za m²` : ''
        }${
          leaseAgreement.nonRentFees === 'Licznikowe'
            ? leaseAgreement.averageMonthlyCostsAnnualizedKnown
              ? ` średniomiesięczne koszty w ujęciu rocznym: ${leaseAgreement.averageMonthlyCostsAnnualized} PLN (Brutto)`
              : ' średniomiesięczne koszty w ujęciu rocznym nie znane'
            : ''
        }`}
      />
    </Space>
  );
};
