import { useField } from 'formik';
import * as React from 'react';
import { ReactNode } from 'react';
import { ControlLabel, FormControl, FormControlProps, FormGroup, Toggle } from 'rsuite';

interface Props extends FormControlProps {
  name: string;
  label: ReactNode;
}
export const FormToggle: React.FC<Props> = ({ name, label, ...props }) => {
  const [field, meta, form] = useField(name);
  const fieldError: string = meta.touched && meta.error ? meta.error : null;

  const renderLabel = () => {
    if (typeof label === 'string') {
      return label;
    } else {
      return label;
    }
  };

  return (
    <FormGroup style={{ display: 'flex' }}>
      <FormControl
        name={name}
        accepter={Toggle}
        {...field}
        errorMessage={fieldError}
        value={field.value}
        onChange={(e) => form.setValue(e)}
        onBlur={field.onBlur}
        {...props}
        style={{ marginRight: '10px' }}
      />
      <ControlLabel>{renderLabel()}</ControlLabel>
    </FormGroup>
  );
};
