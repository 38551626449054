import { useMemo } from 'react';

import { SelectOption } from '../../../../models/main.model';

export function useMaterialType(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () =>
      ['Murowany', 'Stalowy', 'Drewniany'].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}
