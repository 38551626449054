import { useMemo } from 'react';

import { SelectOption } from '../../../../models/main.model';

export function useWarehouseEquipment(): SelectOption[] {
  //   const { t } = useTranslation();
  return useMemo(
    () =>
      [
        'Brak',
        'Separator tluszczu',
        'Woda na hali',
        'Gniazdo silowe 380V',
        'Gaz',
        'Kanalizacja',
        'Zraszacze PPOZ',
        'Okna dachowe',
        'Klapy oddymowe',
        'Kanał',
        'Tor instalacyjny',
      ].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}
