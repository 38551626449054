import { Alert } from 'antd';
import * as React from 'react';
import { ReactNode, useMemo } from 'react';
import { Loader } from 'rsuite';
import styled from 'styled-components';

import { Heading } from '../../features/Offer/components/Heading';
import { OfferLocation } from '../../features/Offer/components/OfferLocation';
import { useGetOfferCheck } from '../../firebase/hooks/getOfferCheck.hook';
import { OfferCheck } from '../../firebase/hooks/updateOfferCheck.hook';
import { Offer } from '../../models/offers.model';
import { PanelContent, PanelWhite, WidthWrapper } from '../Layout/styles';
import { SlickCarousel } from '../SlickCarousel/SlickCarousel';
import { SlideImg, SlideWrapper } from '../SlickCarousel/styles';
import { Typography } from '../Typography/Typography';

import OfferProgress, { ProgressConfigType } from './OfferProgress';

const Wrapper = styled.div`
  background-color: #f3f7fd;
`;
const GridColumn = styled.section`
  display: flex;
  flex-flow: column;
  & > * {
    margin-bottom: 32px;
  }
`;

const MainGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 416px;
  grid-gap: ${({ theme }) => theme.gap(4)};
  padding-bottom: ${({ theme }) => theme.gap(10)};
  align-items: self-start;
`;
const StyledPanelContent = styled(PanelContent)`
  height: 20vh;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledLoader = styled(Loader)`
  height: 64px;
`;
const TextWrapper = styled.div`
  padding: 80px;
  text-align: center;
  & > * {
    margin-bottom: ${({ theme }) => theme.gap(4)};
  }
`;

interface Props {
  offer: Offer;
  images: string[];
  loading?: boolean;
  loadingText?: string;
  title?: string;
  description: ReactNode | string;
  progressConfigType?: ProgressConfigType;
  loadingGif?: string;
  error?: string;
}
export const OfferRelatedWrapper: React.FC<Props> = ({
  images,
  offer,
  description,
  progressConfigType,
  loadingText,
  loading,
  children,
  loadingGif,
  error,
}) => {
  const offerCheck = useGetOfferCheck(offer.id);

  const _images = useMemo(() => {
    if (!images?.length) return null;
    if (images.length > 1) return images;
    if (images.length === 1) return [images[0], images[0], images[0]];
  }, [images]);

  return (
    <Wrapper>
      <SlickCarousel>
        {_images?.map((i) => (
          <SlideWrapper key={i}>
            <SlideImg src={i} />
          </SlideWrapper>
        ))}
      </SlickCarousel>

      <WidthWrapper>
        <Heading offer={offer} offerImage={_images?.find((x) => x !== undefined)} />

        <MainGrid>
          <GridColumn>
            {error && <Alert message="Error" description={error} type="error" showIcon />}
            {!loading && !error && (
              <>
                <PanelWhite bodyFill>
                  <PanelContent>
                    <TextWrapper>
                      <OfferProgress offerCheck={offerCheck} progressConfigType={progressConfigType} />
                      {offerCheck && <Typography.H2>{offerCheck}</Typography.H2>}
                      {offerCheck === OfferCheck.DONE &&
                        (description && typeof description === 'string' ? (
                          <Typography.H5>{description}</Typography.H5>
                        ) : (
                          description
                        ))}
                    </TextWrapper>
                  </PanelContent>
                </PanelWhite>
                {children}
              </>
            )}
            {loading && !error && (
              <PanelWhite bodyFill>
                <StyledPanelContent>
                  {loadingGif ? (
                    <div className="flex items-center p-10">
                      <img src={loadingGif} alt="Loading..." className="w-28 h-28" />
                      <div className="p-4 text-2xl ml-4">{loadingText}</div>
                    </div>
                  ) : (
                    <StyledLoader size="lg" content={loadingText} />
                  )}
                </StyledPanelContent>
              </PanelWhite>
            )}
          </GridColumn>
          <GridColumn>{offer.geoMarker && <OfferLocation offer={offer} />}</GridColumn>
        </MainGrid>
      </WidthWrapper>
    </Wrapper>
  );
};
