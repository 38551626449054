import * as React from 'react';
import styled from 'styled-components';

import { HowItWorksTile } from '../../../components/HowItWorksTail/HowItWorksTile';
import { Header, SubHeader, WidthWrapper } from '../../../components/Layout/styles';

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.backgroundOrange};
  padding: 80px 0;
`;
const DescriptionWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
`;
const Content = styled.article`
  display: flex;
  justify-content: space-evenly;

  > *:not(:last-child):after {
    content: url('/images/assets/arrow.png');
    position: absolute;
    width: auto;
    height: auto;
    background: 0 0;
    left: 100%;
    top: 40px;
  }
`;
const StyledSubHeader = styled(SubHeader)`
  text-align: left;
`;

export const HowItWorks: React.FC = () => {
  return (
    <Wrapper>
      <WidthWrapper>
        <DescriptionWrapper>
          <Header>Znajdziesz Lokal i podpiszesz Umowę Najmu w kilku prostych krokach</Header>
          <StyledSubHeader>
            <ol>
              <li>Wyszukaj Ofertę Lokalu jakiego potrzebujesz dla swojej działalności.</li>
              <li>Utwórz konto - jeśli jeszcze go nie masz.</li>
              <li>Sprawdź wszystkie atrybuty wyszukanego Lokalu i zapoznaj się z warunkami umowy najmu.</li>
              <li>
                Podpisz umowę najmu Lokalu poprzez przyjęcie Oferty Wynajmującego wystawionej na naszej platformie.
              </li>
              <li>Odbierz klucze do Lokalu.</li>
            </ol>
          </StyledSubHeader>
        </DescriptionWrapper>
        <Content>
          <HowItWorksTile
            title={'Wyszukaj Ofertę'}
            description={'Dzięki wyszukiwarce wbudowanej w najmuje.com znajdziesz Lokal dopasowany do Twoich potrzeb.'}
            color={'green'}
            icon={'building-o'}
          />
          <HowItWorksTile
            title={'Sprawdź czy Oferta spełnia Twoje wymagania'}
            description={
              'W przypadku, gdy Lokal spełnia Twoje kryteria, pobierz jego Ofertę wraz z treścią umowy najmu.'
            }
            color={'yellow'}
            icon={'check2'}
          />
          <HowItWorksTile
            title={'Podpisz umowę najmu!'}
            description={
              'Po zapoznaniu się z Ofertą, możesz ją przyjąć jednym kliknięciem - spowoduje to podpisanie przez Ciebie umowy najmu.'
            }
            color={'blue'}
            icon={'handshake-o'}
          />
        </Content>
      </WidthWrapper>
    </Wrapper>
  );
};
