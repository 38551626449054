import firebase from 'firebase';
import React, { useContext, useEffect, useState } from 'react';

import { firebaseAuth, firebaseFunctions, googleProvider } from '../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../firebase/firebase.models';

import { AuthModal } from './AuthModal';
import { AuthDialog, SignInFormValues, SignUpFormValues } from './auth.model';

export interface ModalConfig {
  title?: string;
  onSuccess?: () => any;
}
export interface ModalProps extends ModalConfig {
  content?: AuthDialog;
}

interface AuthContextValues {
  isAuth: boolean;
  isAuthenticating: boolean;
  currentUser: firebase.User;
  signUp: (values: SignUpFormValues) => Promise<firebase.auth.UserCredential>;
  signIn: (values: SignInFormValues) => Promise<firebase.auth.UserCredential>;
  signInWithGoogle: () => void;
  signOut: () => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  sendVerificationEmail: (email?: string) => Promise<{ status: string; message: string }>;
  showSignInModal: (config?: ModalConfig) => void;
  showSignUpModal: (config?: ModalConfig) => void;
  showForgotPasswordModal: () => void;
  closeModal: () => void;
  updateInformation: (name: string) => void;
  updateEmail: (email: string) => Promise<void>;
}

const AuthContext = React.createContext<AuthContextValues>(null);

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<firebase.User>();
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const [dialogContent, setDialogContent] = useState<ModalProps>();
  const [showDialog, setShowDialog] = useState(false);

  function signUp({ email, password }: SignUpFormValues): Promise<firebase.auth.UserCredential> {
    return firebaseAuth.createUserWithEmailAndPassword(email, password).then((v) => {
      return v;
    });
  }
  function signIn({ email, password }: SignInFormValues): Promise<firebase.auth.UserCredential> {
    return firebaseAuth.signInWithEmailAndPassword(email, password).then((v) => {
      setShowDialog(false);
      return v;
    });
  }

  function signInWithGoogle() {
    return firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((v) => {
        if (v.additionalUserInfo.isNewUser) {
          showNonEmailSignUpModal();
        } else setShowDialog(false);
        return v;
      });
  }

  function signOut(): Promise<void> {
    return firebaseAuth.signOut();
  }
  function sendPasswordResetEmail(email: string): Promise<void> {
    return firebaseAuth.sendPasswordResetEmail(email);
  }

  async function sendVerificationEmail(email?: string): Promise<{ status: string; message: string }> {
    showVerifyEmailModal();
    const sendEmailVerificationCode = firebaseFunctions.httpsCallable(
      FirebaseCallableFunctions.SEND_EMAIL_VERIFICATION_CODE
    );
    try {
      const response = await sendEmailVerificationCode({ email: email ? email : currentUser.email });
      return response.data;
    } catch (error: any) {
      console.error('Error sending verification email:', error);
      return { status: 'ERROR', message: error.message || 'Wystąpił błąd podczas wysyłania e-maila.' };
    }
  }

  function showSignInModal(config: ModalConfig): void {
    setDialogContent({ ...config, content: AuthDialog.SIGN_IN });
    setShowDialog(true);
  }
  function showSignUpModal(config: ModalConfig): void {
    setDialogContent({ ...config, content: AuthDialog.SIGN_UP });
    setShowDialog(true);
  }
  function showNonEmailSignUpModal(): void {
    setDialogContent({ content: AuthDialog.NON_EMAIL_SIGN_UP });
    setShowDialog(true);
  }
  function showForgotPasswordModal(): void {
    setDialogContent({ content: AuthDialog.FORGOT_PASSWORD });
    setShowDialog(true);
  }
  function showVerifyEmailModal(): void {
    setDialogContent({ content: AuthDialog.VERIFY_EMAIL, title: 'Potwierdź adres email' });
    setShowDialog(true);
  }
  function closeModal(): void {
    setShowDialog(false);
  }
  function updateInformation(name: string) {
    firebase.auth().currentUser.updateProfile({ displayName: name });
  }
  function updateEmail(email: string): Promise<void> {
    return firebaseAuth.currentUser.updateEmail(email);
  }

  useEffect(() => {
    setIsAuthenticating(true);
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setIsAuthenticating(false);
    });
    return () => unsubscribe();
  }, []);

  const values: AuthContextValues = {
    isAuth: !!currentUser?.email,
    isAuthenticating,
    currentUser,
    signUp,
    signIn,
    signInWithGoogle,
    signOut,
    sendPasswordResetEmail,
    sendVerificationEmail,
    showSignInModal,
    showSignUpModal,
    showForgotPasswordModal,
    closeModal,
    updateInformation,
    updateEmail,
  };

  return (
    <AuthContext.Provider value={values}>
      {children}
      <AuthModal dialogConfig={dialogContent} showDialog={showDialog} />
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValues => {
  return useContext(AuthContext);
};
