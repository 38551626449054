import { useEffect, useState } from 'react';

import { firestore } from '../../../firebase/firebase';
import { FirestoreCollection, OfferOption } from '../../../firebase/firebase.models';
import { LeaseAgreement } from '../../../models/offers.model';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGetLeasements(offerId: string, leaseAgreement?: LeaseAgreement) {
  const [leaseAgreements, setLeaseAgreements] = useState<OfferOption[]>([]);

  useEffect(() => {
    firestore
      .collection(FirestoreCollection.OFFERS)
      .doc(offerId)
      .get()
      .then((doc) => {
        const leaseData = doc.data().leaseAgreement;
        const offers: OfferOption[] = [];

        if (leaseData.infinite) {
          offers.push({ id: 1, title: 'Umowa najmu zawarta na czas nieokreślony', qualifiedSignature: false });
        }
        if (leaseData.fixedshort) {
          offers.push({
            id: 2,
            title: `Umowa najmu na czas ${leaseAgreement.fixedshortMonths} miesięcy`,
            qualifiedSignature: false,
          });
        }
        if (leaseData.fixedlong) {
          offers.push({
            id: 3,
            title: `Umowa najmu na czas ${leaseAgreement.fixedlongMonths} miesięcy`,
            qualifiedSignature: true,
          });
        }

        setLeaseAgreements(offers);
      })
      .catch((error) => {
        console.error('Error getting document:', error);
      });
  }, [offerId]);

  return leaseAgreements;
}
