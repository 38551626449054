import { Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'rsuite';

import { FormInput } from '../../../../components/FormInputs/FormInput';
import { gtm } from '../../../../reportGTM';
import { UserProfileFormValues } from '../../auth.model';
import { useValidation2 } from '../hooks/useValidation.hook';

export const Step2Form: React.FC<{ onNext: (values: UserProfileFormValues) => void }> = ({ onNext }) => {
  const validation2 = useValidation2();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  // Initial values for Step 2
  const initialStep2Values: UserProfileFormValues = {
    firstName: '',
    lastName: '',
  };

  return (
    <Formik
      initialValues={initialStep2Values}
      validationSchema={validation2}
      onSubmit={(values: UserProfileFormValues, formikHelpers: FormikHelpers<UserProfileFormValues>) => {
        gtm('form_submit_reg_step_2', { state: 'FirstNameAndLastNameSubmitted' });
        onNext(values); // Przejdź do kroku 3
        formikHelpers.setSubmitting(false); // Ustawienie isSubmitting na false po zakończeniu
      }}>
      {({ isSubmitting, isValid, submitForm }) => (
        <Form fluid size={'lg'} key="step2">
          <FormInput name={'firstName'} label={t('FEATURES.AUTH.SIGN_UP.INPUT_FIRSTNAME_PLACEHOLDER')} />
          <FormInput name={'lastName'} label={t('FEATURES.AUTH.SIGN_UP.INPUT_LASTNAME_PLACEHOLDER')} />
          <Button
            id="form_reg_submit"
            loading={loading}
            block
            color={'blue'}
            type="submit"
            disabled={isSubmitting || !isValid}
            onClick={() => {
              submitForm();
              setLoading(true);
            }}>
            {t('FEATURES.AUTH.SIGN_UP.BUTTON_SUBMIT')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
