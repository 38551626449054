import { Alert, Space } from 'antd';
import * as React from 'react';

interface Props {
  days: number;
  firstPaymentDay: number;
}

export const ReceiptDeadline: React.FC<Props> = ({ days, firstPaymentDay }) => {
  if (days)
    return (
      <Space direction="vertical" size={12} style={{ marginBottom: 16 }}>
        <Alert
          type="info"
          message={`Czas na odbiór Lokalu to ${days} dni po podpisaniu umowy. Pierwszy czynsz (NETTO): ${firstPaymentDay} dni po odebraniu lokalu.`}
        />
      </Space>
    );
  else return <></>;
};
