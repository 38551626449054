import * as React from 'react';
import { useEffect, useState } from 'react';
import { Alert, Space, Spin } from 'antd';

import { App } from '../../../../App/App';
import { toastService } from '../../../../../components/Toast/toast.service';
import { ToastVariant } from '../../../../../components/Toast/toast.model';
import { useAuth } from '../../../../Auth/AuthContext';
import { onLogDeleteAccount } from '../../../../../admin/hooks/onLogDeleteAccount.hook';

import { deleteUser } from './hooks/useDeleteUser.hook';

interface Props {
  uid: string;
}

export const ConfirmDeleteAccount: React.FC<Props> = ({ uid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const { currentUser } = useAuth();
  const [deleteUserMessage, setDeleteUserMessage] = useState('');

  useEffect(() => {
    const handleDeleteAccount = async () => {
      setIsLoading(true);

      const [response, respError] = await deleteUser(uid, currentUser);
      setDeleteUserMessage(response);
      setError(respError);
      setIsLoading(false);
      if (!respError) {
        onLogDeleteAccount(uid);
        toastService.show('Konto zostało usunięte', ToastVariant.SUCCESS);
      } else if (respError) {
        toastService.show('Cos poszło nie tak.', 'Błąd', { variant: ToastVariant.ERROR });
      }
    };

    handleDeleteAccount();
  }, [uid, history]);

  return (
    <App>
      <Space direction="vertical" style={{ width: '100%', marginTop: '100px', marginBottom: '60%', padding: '50px' }}>
        {!error ? (
          <Alert
            showIcon
            message={<span>Trwa usuwanie konta {isLoading ? <Spin /> : null}</span>}
            description={'Prosze nie opuszczać strony'}
            type="warning"
          />
        ) : (
          <Alert showIcon message="Błąd" description={deleteUserMessage} type="error" />
        )}
      </Space>
    </App>
  );
};
