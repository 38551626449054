import { EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import * as React from 'react';

import { toastService } from '../../components/Toast/toast.service';

const actions: React.ReactNode[] = [
  <a
    href="https://docs.google.com/forms/d/e/1FAIpQLSc9lRv1hyl6Qtnw-7iB2WYvmnUpmnxBMggQyLtclZ5iRsEAmQ/viewform"
    target="_blank"
    key="signUp"
    rel="noreferrer">
    <EditOutlined />
  </a>, // Ankieta

  <LinkOutlined
    key="share"
    onClick={() => {
      const linkToElement = `${window.location.origin}${window.location.pathname}#Recruitment`;
      navigator.clipboard.writeText(linkToElement);
      toastService.show('Teraz możesz się nim podzielić', 'Skopiowano link');
    }}
  />,
];

const RecruitmentHeader: React.FC = () => {
  const isMobile = window.innerWidth < 768;
  return (
    <div
      id="Recruitment"
      className="relative bg-cover bg-center"
      style={{ backgroundImage: 'url(/images/assets/pexels-olly-789822.jpg)' }}>
      <div className="w-full flex justify-center py-8">
        <div className="flex flex-col w-full mx-4 lg:mx-8 lg:w-2/3">
          <h1 className="text-gray-900 text-6xl lg:text-4xl font-extrabold text-left mt-24 md:mt-0 lg:mt-0">
            Dołącz do{' '}
            <a className="text-blue-700" href="https://najmuje.com">
              najmuje.com
            </a>
          </h1>
          <h2 className="text-gray-900 font-bold text-xl mt-4 text-left">
            Zostań Twarzą Nowoczesnego Startupu nieruchomościowego!
          </h2>
          <div className="flex justify-center md:justify-start lg:justify-start w-full mt-36 lg:mt-12">
            <Card
              title="Specjalista ds. Sprzedaży i Marketingu"
              actions={actions}
              className="shadow-md mx-1 w-full md:w-3/4 lg:w-2/5 lg:mb-24"
              extra={
                !isMobile && (
                  <Button
                    type="link"
                    size="large"
                    className="m-4 mr-0 font-bold"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSc9lRv1hyl6Qtnw-7iB2WYvmnUpmnxBMggQyLtclZ5iRsEAmQ/viewform"
                    target="_blank">
                    Dołącz teraz
                  </Button>
                )
              }>
              <div className="text-lg mb-4">
                <strong>Dlaczego warto z nami pracować?</strong>
              </div>
              <div className="mb-2">
                <strong>Konkurencyjne wynagrodzenie:</strong>
                <br /> Oferujemy atrakcyjną podstawę 5000 zł oraz premie za wyniki w wysokości 5 000 - 10 000 zł
                miesięcznie.
              </div>
              <div className="mb-2">
                <strong>Elastyczność pracy:</strong>
                <br />
                Wybierz pomiędzy pracą zdalną, hybrydową lub stacjonarną w Warszawie. Oferujemy również biura w Atrium
                Reduta lub Centrum Praskim Koneser. Wdrożenie odbywa się stacjonarnie.
              </div>
              <div className="mb-2">
                <strong>Wsparcie i szkolenia:</strong>
                <br /> Rozwijaj się z nami dzięki 4-tygodniowemu szkoleniu wdrażającemu, które przygotuje Cię do pracy
                według najwyższych standardów.
              </div>
              <div className="mb-2">
                <strong>Możliwość dołączenia do zarządu:</strong>
                <br /> Najlepsi mają szansę na dołączenie do zarządu firmy.
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentHeader;
