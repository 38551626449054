import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'rsuite';

interface Props {
  onClick: () => void;
  signUp?: boolean;
}

export const GoogleSignInButton: React.FC<Props> = ({ onClick, signUp }) => {
  const { t } = useTranslation();
  return (
    <Button block className="login-with-google-btn flex items-center justify-center" onClick={onClick}>
      <img src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" className="w-8 h-8" />
      {signUp ? t('FEATURES.AUTH.SIGN_IN.SIGN_UP_WITH_GOOGLE') : t('FEATURES.AUTH.SIGN_IN.SIGN_IN_WITH_GOOGLE')}
    </Button>
  );
};
