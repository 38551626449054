import { FormikHelpers } from 'formik/dist/types';
import { useCallback } from 'react';

import { SignInFormValues } from '../../auth.model';
import { useAuth } from '../../AuthContext';
import { toastService } from '../../../../components/Toast/toast.service';
import { ToastVariant } from '../../../../components/Toast/toast.model';

export function useOnSubmit(
  onSuccess: () => void
): (values: SignInFormValues, formikHelpers: FormikHelpers<SignInFormValues>) => Promise<any> {
  const { signIn } = useAuth();

  return useCallback((values) => {
    return signIn(values)
      .catch((e) => {
        toastService.show(e.message, 'Błąd!', { variant: ToastVariant.ERROR });
      })
      .then(() => onSuccess?.());
  }, []);
}
