import { TFunction } from 'i18next';
import * as yup from 'yup';

import { OfferType } from '../../../models/offers.model';

export const useValidation = (t: TFunction<'translation'>): yup.SchemaOf<any> => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return yup.object().shape({
    uid: yup.string().required(),
    description: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    legalMainUse: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    legalWarehouseUseAccepted: yup.array().min(1, t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    legalWarehouseUseOfficial: yup.array().min(1, t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    areaDetails: yup.object().shape({
      surface: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      totalSurface: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      offices: yup
        .array()
        .of(
          yup.object().shape({
            type: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
            surface: yup
              .number()
              .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
              .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
            windows: yup.boolean(),
          })
        )
        .when('legalMainUse', {
          is: 'Biurowe',
          then: yup
            .array()
            .of(
              yup.object().shape({
                type: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
                surface: yup
                  .number()
                  .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
                  .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
                windows: yup.boolean(),
              })
            )
            .min(1, t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        }),
      warehouses: yup
        .array()
        .of(
          yup.object().shape({
            type: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
            surface: yup
              .number()
              .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
              .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
            windows: yup.boolean(),
          })
        )
        .when('legalMainUse', {
          is: 'Handlowo-Usługowe',
          then: yup
            .array()
            .of(
              yup.object().shape({
                type: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
                surface: yup
                  .number()
                  .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
                  .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
                windows: yup.boolean(),
              })
            )
            .min(1, t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        }),
      technicalRooms: yup
        .array()
        .of(
          yup.object().shape({
            type: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
            surface: yup
              .number()
              .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
              .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
            windows: yup.boolean(),
          })
        )
        .when('legalMainUse', {
          is: 'Magazynowo-Przemysłowe',
          then: yup
            .array()
            .of(
              yup.object().shape({
                type: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
                surface: yup
                  .number()
                  .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
                  .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
                windows: yup.boolean(),
              })
            )
            .min(1, t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        }),
      toiletsLocation: yup.boolean(),
      levels: yup.array().of(
        yup.object().shape({
          levelNumber: yup
            .number()
            .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
            .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
          levelName: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
          surface: yup
            .number()
            .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
            .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
          height: yup
            .number()
            .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
            .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
          numberOfStaircases: yup
            .number()
            .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
            .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
          accessibility: yup.boolean(),
        })
      ),
      surroundings: yup.array().min(1, t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      publicTransportDistance: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      drive: yup.array().min(1, t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      buildingType: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      constructionMaterial: yup.array().min(1, t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      thermalInsulation: yup.array().min(1, t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      constructionCompletionYear: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .max(new Date().getFullYear() + 2, t('COMMON.FORMS.VALIDATION.INVALID_DATE'))
        .min(1500, t('COMMON.FORMS.VALIDATION.INVALID_DATE')),
      landArea: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    }),
    offerType: yup.mixed<OfferType.RENT>(),
    offerStatus: yup.object().shape({
      state: yup.string(),
    }),
    parkingDetails: yup.object().shape({
      parkingSurface: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      totalParkingSurface: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      guardedParking: yup.boolean(),
      parkingLotsAvailable: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    }),
    mediaDetails: yup.object().shape({
      powerAllocation: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      transformerIn: yup.boolean(),
      doublePowerLines: yup.boolean(),
      water: yup.boolean(),
      gas: yup.boolean(),
      sewerage: yup.boolean(),
      heating: yup.boolean(),
      fiber: yup.boolean(),
      LAN: yup.boolean(),
      phoneLine: yup.boolean(),
      energyCategory: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    }),
    geoMarker: yup.object().shape({ lat: yup.number().nullable(), lng: yup.number().nullable() }),
    relatedDetails: yup.object().shape({
      warehouseEquipment: yup.array().of(yup.string()),
      warehouseGroundLevel: yup.boolean(),
      carRamp: yup.boolean(),
      warehouseManagement: yup.boolean(),
      forklift: yup.boolean(),
      gates: yup.array().of(
        yup.object().shape({
          width: yup.number().typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
          height: yup.number().typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        })
      ),
      hallHeight: yup.number().typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      lift: yup.boolean(),
      liftCapacityKg: yup.number().typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      columnsGridStructure: yup.object().shape({
        x: yup.number().nullable(),
        y: yup.number().nullable(),
      }),
    }),
    landlord: yup.string(),
    id: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    address: yup.object().shape({
      street: yup.string(),
      postCode: yup.string(),
      country: yup.string(),
      province: yup.string(),
      district: yup.string(),
      city: yup.string(),
      plotNumber: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      landAndMortgageRegisterNumber: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      ownershipType: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      officialAddress: yup.object().shape({
        houseNumber: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        street: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        estate: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        district: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        county: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        province: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      }),
    }),
    subleaseAgreement: yup.boolean().when('address.ownershipType', {
      is: (ownershipType) => ownershipType === 'Umowa najmu', // Możesz dostosować warunek w zależności od konkretnej wartości, np. `ownershipType === 'someType'`
      then: yup.boolean().oneOf([true], 'Zgoda na podnajem jest wymagana przy tym rodzaju własności'),
      otherwise: yup.boolean(), // Jeśli pole nie jest wymagane w innych przypadkach
    }),
    name: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    price: yup
      .number()
      .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
      .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    priceCurrency: yup.string().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    availability: yup.date().typeError('Pole wymagane'),
    availabilityTo: yup
      .date()
      .nullable()
      .test('availabilityTo', t('COMMON.FORMS.VALIDATION.INVALID_DATE'), function (value) {
        const { availability } = this.parent;
        // Sprawdź, czy availabilityTo jest większe lub równe availability
        if (value === null) {
          // Jeżeli wartość jest null, zwróć true
          return true;
        } else if (value && availability && value >= availability) {
          return true;
        }
        return false;
      })
      .typeError('Błąd'),
    offerValidTo: yup
      .date()
      .typeError('Pole wymagane')
      .min(new Date(), t('COMMON.FORMS.VALIDATION.INVALID_DATE'))
      .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    unitReceiptDeadline: yup
      .number()
      .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
      .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
    leaseAgreement: yup.object().shape({
      infinite: yup.boolean().when(['fixedshort', 'fixedlong'], {
        is: (fixedshort, fixedlong) => !fixedshort && !fixedlong,
        then: yup.boolean().oneOf([true], t('COMMON.FORMS.VALIDATION.AT_LEAST_ONE_FIELD_REQUIRED')),
      }),
      fixedshort: yup.boolean(),
      fixedshortMonths: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .when('fixedshort', {
          is: true,
          then: yup
            .number()
            .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
            .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        }),
      fixedlong: yup.boolean(),
      fixedlongMonths: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .when('fixedlong', {
          is: true,
          then: yup
            .number()
            .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
            .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        }),
      deposit: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      payDay: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      nonRentFees: yup
        .string()
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      lumpSum: yup.number().when('nonRentFees', {
        is: 'Ryczałtowe',
        then: yup.number().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        otherwise: yup.number().nullable(),
      }),
      firstPaymentDay: yup
        .number()
        .typeError(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD'))
        .required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
      averageMonthlyCostsAnnualizedKnown: yup.boolean(),
      averageMonthlyCostsAnnualized: yup.number().when(['nonRentFees', 'averageMonthlyCostsAnnualizedKnown'], {
        is: (nonRentFees, averageMonthlyCostsAnnualizedKnown) =>
          nonRentFees === 'Licznikowe' && !averageMonthlyCostsAnnualizedKnown,
        then: yup.number().required(t('COMMON.FORMS.VALIDATION.REQUIRED_FIELD')),
        otherwise: yup.number().nullable(),
      }),
    }),

    // Do przetestowania czy działa
    summary: yup.object().shape({
      summary1: yup.bool().oneOf([true], t('COMMON.FORMS.VALIDATION.REQUIRED')),
      summary2: yup.bool().oneOf([true], t('COMMON.FORMS.VALIDATION.REQUIRED')),
      summary3: yup.bool().oneOf([true], t('COMMON.FORMS.VALIDATION.REQUIRED')),
      summary4: yup.bool().oneOf([true], t('COMMON.FORMS.VALIDATION.REQUIRED')),
    }),
  });
};
