import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

import { useOnAddOfferPush } from '../../App/hooks/onAddOfferPush.hook';
import { AppRoutes } from '../../Routing/routing.model';

const Wrapper = styled.header`
  background-color: ${({ theme }) => theme.primary};
  background-image: url('/images/banners/banner.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 350px; /* Zwiększono minimalną wysokość */
`;

const Heading = styled(FlexboxGrid)`
  text-align: center;
  height: 100%;
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  height: 350px; /* Zwiększono wysokość */
`;

const CarouselContainer = styled.div<{ translateX: number }>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${(props) => props.translateX}%);
  height: 100%; /* Dostosowano wysokość do kontenera */
  margin: 0;
  padding: 0;
`;

const Slide = styled.div`
  min-width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: white;

  height: 100%; /* Zwiększono wysokość */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HomepageHeading: React.FC = () => {
  return (
    <Wrapper>
      <CustomCarousel />
    </Wrapper>
  );
};

export const CustomCarousel: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { push } = useHistory();

  const handleJoin = () => {
    window.open('/rekrutacja', '_blank');
  };
  const onAddOfferPush = useOnAddOfferPush();

  const slides = [
    <Heading justify="center" key="slide6" style={{ position: 'relative', width: '100%' }}>
      <img
        src="/images/assets/autopromocja1.png"
        alt="Szukasz pracy?"
        className="w-full h-full object-cover rounded-lg object-center"
      />
    </Heading>,
    <Heading justify="center" key="slide2" style={{ position: 'relative', width: '100%' }}>
      <div className="relative z-10 bg-gradient-to-b from-green-500 to-green-800 text-white p-10 rounded-xl shadow-md flex flex-col justify-center items-center h-full ">
        <h2 className="text-5xl font-bold mb-4 text-center">Bezpieczne i Komfortowe Podpisanie Umowy</h2>
        <p className="text-lg mb-6 text-center">
          Zapewniamy pełne wsparcie przy każdym kroku procesu najmu, z gwarancją bezpieczeństwa i przejrzystości. Twój
          spokój ducha to nasz cel.
        </p>
        <button
          className="bg-white text-green-700 font-bold py-3 px-6 rounded-full hover:bg-gray-200 transition duration-300"
          onClick={() => push(AppRoutes.OFFERS)}>
          Znajdź Lokal
        </button>
      </div>
    </Heading>,

    <Heading justify="center" key="slide3" style={{ position: 'relative', width: '100%' }}>
      <div className="relative z-10 bg-gradient-to-r from-blue-500 to-blue-900 text-white p-10 rounded-lg shadow-lg align-middle h-full items-center flex">
        <div>
          <h2 className="text-5xl font-extrabold mb-6">Szybciej i łatwiej wynajmij swój lokal</h2>

          <p className="text-lg mb-6">
            Przeprowadzimy Cię przez każdy etap — od stworzenia oferty oferty po finalny protokół zdawczo-odbiorczy,
            zapewniając pełne wsparcie na każdym kroku.
          </p>
          <button
            className="bg-white text-blue-600  font-bold py-3 px-6 rounded-full hover:bg-gray-100 transition-all"
            onClick={onAddOfferPush}>
            Dodaj Swój Lokal
          </button>
        </div>
      </div>
    </Heading>,

    <Heading justify="center" key="slide4" style={{ position: 'relative', width: '100%' }}>
      <div className="relative z-10 text-white p-8 h-full items-end flex">
        <div>
          <h2 className="text-5xl font-bold mb-4">Wchodzisz, Znajdujesz, Najmujesz</h2>
          <p className="text-xl">
            Z nami szybko i wygodnie znajdziesz idealne miejsce do wynajęcia. Przekonaj się, jak łatwe to może być.
          </p>
        </div>
      </div>

      <img
        src="/images/assets/pexels-khwanchai.jpg"
        alt="Wchodzisz, Znajdujesz, Najmujesz"
        className="w-full h-full object-cover absolute inset-0 z-0 rounded-lg opacity-80"
      />
    </Heading>,
    // <Heading justify="center" key="slide5" style={{ position: 'relative', width: '100%' }}>
    //   <div className="relative z-10 bg-gradient-to-r from-red-500 to-yellow-500 text-white p-10 rounded-xl shadow-md flex flex-col justify-center items-center h-full w-full">
    //     <h2 className="text-5xl font-extrabold mb-4 text-center">Wielki Moment Nadchodzi</h2>
    //     <p className="text-lg mb-6 text-center">Przygotuj się na wyjątkowe wydarzenie! Pozostało niewiele czasu...</p>
    //     <Countingdown />
    //   </div>
    // </Heading>,

    <Heading justify="center" key="slide6" style={{ position: 'relative', width: '100%' }}>
      <div className="text-gray-800 z-10 m-4 h-full rounded-lg ">
        <h1 className="text-5xl font-extrabold text-left w-1/3">
          Dołącz do{' '}
          <a className="text-blue-700" href="https://najmuje.com">
            najmuje.com
          </a>
        </h1>
        <h2 className="font-bold text-xl mt-4 text-left w-1/3">
          Zostań Twarzą Nowoczesnego Startupu nieruchomościowego!
          <Button type="primary" size={'large'} className="my-8" onClick={handleJoin}>
            Dołącz do nas
          </Button>
        </h2>
      </div>
      <img
        src="/images/assets/pexels-olly-cropped.jpg"
        alt="Szukasz pracy?"
        className="w-full h-full object-cover absolute inset-0 z-0 rounded-lg "
      />
    </Heading>,
    <Heading justify="center" key="slide5" style={{ position: 'relative', width: '100%' }}>
      <div className="relative z-10 bg-gradient-to-r from-yellow-500 to-pink-500 text-white p-10 rounded-xl shadow-md flex flex-col justify-center items-center h-full w-full">
        <h2 className="text-5xl font-extrabold mb-4 text-center">
          Zamień czas na pieniądze! Sprawdź nasze 10 RAZY szybciej!
        </h2>
        <p className="text-lg mb-6 text-center">
          Nasze ponad 25 letnie doświadczenie na rynku nieruchomości, wierzymy że można 10 razy szybciej przyspieszyć
          proces najmu. Naszym celem jest zrewolucjonizowanie rynku najmu poprzez szybkie i bezpieczne zawieranie umów
          najmu.
        </p>
        <a
          href="https://web.najmuje.com"
          target="_blank"
          rel="noopener noreferrer" // Zabezpiecza przed zagrożeniami związanymi z nową kartą
          className="bg-white text-purple-600 font-bold py-3 px-6 rounded-full hover:bg-gray-100 transition-all">
          Dowiedz się więcej
        </a>
      </div>
    </Heading>,
  ];

  const totalSlides = slides.length;

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 8000); // Automatyczna zmiana co 8 sekund
    return () => clearInterval(interval);
  }, []);

  return (
    <CarouselWrapper>
      <CarouselContainer translateX={-currentSlide * 100}>
        {slides.map((slide, index) => (
          <Slide key={index}>{slide}</Slide>
        ))}
      </CarouselContainer>
    </CarouselWrapper>
  );
};
