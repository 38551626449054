import { message } from 'antd';
import firebase from 'firebase';

import { firestore } from '../../firebase/firebase';
import { FirestoreCollection } from '../../firebase/firebase.models';

interface Props {
  offerId: string;
  accepted: boolean;
  currentUser: firebase.User;
}

export const handleOnProtocolSign = async ({ offerId, accepted, currentUser }: Props): Promise<void> => {
  if (!offerId || !currentUser) {
    message.error('Brak wymaganych danych do podpisania protokołu.');
    return;
  }

  try {
    if (accepted) {
      await firestore.collection(FirestoreCollection.OFFERS).doc(offerId).update({
        'protocolData.protocolSigningUid': currentUser.uid,
        'protocolData.signedAt': new Date().toLocaleString(),
      });
      console.log('Dane protokołu zapisane pomyślnie');
    }
  } catch (error) {
    message.error('Nie udało się zapisać danych.');
    console.error('Error in handleOnProtocolSign:', error);
  }
};
