import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { PremisesPurpose, SearchFormFilters } from '../../models/offers.model';
import { App } from '../App/App';

import { HomepageHeading } from './components/HomepageHeading';
import { OffersSection } from './components/OffersSection';
import { ProductVideo } from './components/ProductVideo';

export const Home: React.FC = () => {
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [cookies] = useCookies(['userFilters']);
  const [savedFilters, setSavedFilters] = useState<SearchFormFilters | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchLocation = async () => {
      try {
        const response = await axios.get('https://api.ipregistry.co/?key=rtjdme40sy5bbuoz');
        const data = response.data;
        if (isMounted) {
          setRegion(data.location.region.name.toString().toLowerCase());
          setCity(data.location.city);
          console.log(region, data.location.city);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching location data:', error);
        }
      }
    };

    fetchLocation();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (cookies.userFilters) {
      try {
        const decodedFilters = decodeURIComponent(cookies.userFilters);
        const filters: SearchFormFilters = JSON.parse(decodedFilters);
        setSavedFilters(filters);
      } catch (error) {
        console.error('Error parsing filters from cookies:', error);
      }
    }
  }, [cookies]);

  return (
    <App>
      <HomepageHeading />
      <div className="bg-gray-100">
        <OffersSection
          slides={4}
          filters={savedFilters ?? { province: region }}
          sectionTitle={`Oferty którymi możesz się zainteresować`}
        />
        <ProductVideo />
        <OffersSection
          slides={4}
          filters={{
            province: region,
            premisesPurpose: PremisesPurpose.WAREHOUSE_AND_INDUSTRIAL,
          }}
          sectionTitle={`Magazyny w pobliżu miasta ${city}`}
        />
        <OffersSection
          slides={4}
          filters={{
            premisesPurpose: PremisesPurpose.COMMERCIAL_AMD_SERVICE,
            province: region,
          }}
          sectionTitle="Potrzebujesz przestrzeni pod swój biznes?"
        />
        <OffersSection
          slides={4}
          filters={{
            premisesPurpose: PremisesPurpose.OFFICE,
            province: region,
          }}
          sectionTitle="Znajdź biura jakiego potrzebujesz"
        />
      </div>
    </App>
  );
};
