import * as React from 'react';
import styled from 'styled-components';

import { WidthWrapper } from '../../../components/Layout/styles';
import { HowItWorksTile } from '../../../components/HowItWorksTail/HowItWorksTile';

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.backgroundOrange};
  padding: 80px 0;
`;
const Content = styled.article`
  display: flex;
  justify-content: space-evenly;

  > *:not(:last-child):after {
    content: url('/images/assets/arrow.png');
    position: absolute;
    width: auto;
    height: auto;
    background: 0 0;
    left: 100%;
    top: 40px;
  }
`;

export const HowItWorks: React.FC = () => {
  return (
    <Wrapper>
      <WidthWrapper>
        <Content>
          <HowItWorksTile
            title={'Wstaw ogłoszenie'}
            description={'Dzięki dokładnym parametrom opisz Twój Lokal.'}
            color={'green'}
            icon={'building-o'}
          />
          <HowItWorksTile
            title={'Podpisz Ofertę'}
            description={
              'Sprawdź poprawność treści ogłoszenia, określ warunki umowy najmu i wystaw Ofertę podpisując ją cyfrowo.'
            }
            color={'yellow'}
            icon={'check2'}
          />
          <HowItWorksTile
            title={'Odbierz umowę najmu'}
            description={
              'Wystawiona przez Ciebie Oferta trafi do zalogowanych podmiotów, które po akceptacji Twoich warunków zawrą z Tobą umowę najmu w sposób cyfrowy. Sprawdź swój e-mail i umów się na przekazanie Lokalu najemcy.'
            }
            color={'blue'}
            icon={'handshake-o'}
          />
        </Content>
      </WidthWrapper>
    </Wrapper>
  );
};
