import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { NonEmailSignUpFormValues } from '../../auth.model';

export const useValidation = (): yup.SchemaOf<NonEmailSignUpFormValues> => {
  const { t } = useTranslation();
  return yup.object().shape({
    firstName: yup.string().required(t('FEATURES.AUTH.SIGN_UP.INPUT_FIRSTNAME_VALIDATION_REQUIRED')),
    lastName: yup.string().required(t('FEATURES.AUTH.SIGN_UP.INPUT_LASTNAME_VALIDATION_REQUIRED')),
    marketingConsent: yup.boolean().required(t('FEATURES.AUTH.SIGN_UP.INPUT_LASTNAME_VALIDATION_REQUIRED')),
    email: yup.string(),
    uid: yup.string(),
  });
};
