import { Alert, Space } from 'antd';
import * as React from 'react';

interface Props {
  deposit: number;
}

export const InfoAboutDeposit: React.FC<Props> = ({ deposit }) => {
  return (
    <Space direction="vertical" size={12} style={{ marginBottom: 16 }}>
      <Alert type="info" message={`Kwota kaucji dla tego Lokalu wynosi: ${deposit} PLN`} />
    </Space>
  );
};
