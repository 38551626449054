import { Alert, Space } from 'antd';
import * as React from 'react';

export const InfoAboutSignature: React.FC = () => {
  return (
    <Space direction="vertical" size={12} style={{ marginBottom: 16 }}>
      <Alert type="info" message={'Przy wyborze tego rodzaju umowy wymagany jest podpis tradycyjny.'} />
    </Space>
  );
};
